import { Directive, ElementRef, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DestroyableDirective } from '../../components/base/destroyable/destroyable.directive';
import { LocalizationContext, LocalizationKey, LocalizationTransformType } from '../../models/config/localization/localizationModels';
import { LocalizationService } from './localization.service';

@Directive({
  selector: '[localize]'
})
export class LocalizeDirective extends DestroyableDirective {

	@Input({ required: true, alias: 'localize' }) key?: LocalizationKey;
	@Input() context?: LocalizationContext | string;
	@Input() localizedHtml?: boolean;
	@Input() transform?: LocalizationTransformType;

  constructor(
    private elementRef: ElementRef<any>,
		private localizationService : LocalizationService,
		private domSanitizer: DomSanitizer) {
			super();
			this.addOnStopListener(localizationService.listen(() => {
				this.updateView();
			}));
		}


	ngOnInit() {
		this.updateView();
	}

	ngOnChanges() {
		this.updateView();
	}

	updateView() {
		if (this.key) {
			if (this.localizedHtml) {
				(this.elementRef.nativeElement as HTMLElement).innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.localizationService.get(this.key, this.context, this.transform))!;
			} else {
				(this.elementRef.nativeElement as HTMLElement).innerText = this.localizationService.get(this.key, this.context, this.transform);
			}
		}
	}
}
