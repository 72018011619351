import { IdTag } from './../utilities/typeUtilities';
import { Condition } from "./Condition";

export type FormInputUid = IdTag<'formInputUid'>;

export type FormComponent = {
	index: number; // The index of the form component in the text
	type: FormComponentType; // The type of the form component
};

export type FormIfComponent = FormComponent & {
	type: FormComponentType.If | FormComponentType.EndIf;
};

export type FormConditionalComponent = FormComponent & {
	condition: Condition; // The conditions that must be met for the form component to be shown/used
};

export type FormPromptComponent = FormConditionalComponent & {
	type: FormComponentType.Prompt;
	value: string; // The value of the prompt, should be emptied when extracted as a form input component!
};

export type FormInputComponent = FormConditionalComponent & {
	// uid?: FormInputUid; // The uid of the form component
	name?: string; // The name of the form input, used as the key in the inputValues object
	label?: string; // The label of the form input shown to the user
	description?: string; // The description of the form input shown to the user
	required?: boolean; // Whether the form input is required
	default?: string; // The default value of the form input

	variables: { [scope in FormVariableScope]?: FormVariablePolicy }; // Settings for the variables. Default is 'allow' for all scopes
};

export type FormTextInputComponent = FormInputComponent & {
	type: FormComponentType.Text | FormComponentType.Textfield | FormComponentType.Textarea;
	suggestions?: string[]; // if the input is a textfield or textarea the suggestions are defined here
	placeholder?: string; // The placeholder of the form input shown to the user
}

export type FormCheckboxComponent = FormInputComponent & {
	type: FormComponentType.Checkbox | FormComponentType.Toggle;
	true?: string; // this is the value when it is checked
	false?: string; // this is the value when it is unchecked
}

export type FormOptionsComponent = FormInputComponent & {
	type: FormComponentType.Dropdown | FormComponentType.Radio | FormComponentType.Pick;
	options: { value: string; label: string }[]; // if the input is a dropdown or radio, the options are defined here
}

export enum FormVariablePolicy {
	Forbid = 'forbid',
	Allow = 'allow',
	Suggest = 'suggest',
	Require = 'require',
}

export enum FormVariableScope {
	Personal = 'personal',
	Organization = 'organization',
	Project = 'project',
	Global = 'global',
}

export enum FormComponentType {
	If = 'if',
	EndIf = 'endif',
	Text = 'text', // just text, no input
	Button = 'button', // todo
	Prompt = 'prompt', // a prompt piece of text, no input, can be used to encapsulate with conditions

	Textfield = 'textfield',
	Textarea = 'textarea',
	Checkbox = 'checkbox',
	Toggle = 'toggle', // todo
	Url = 'url', // todo
	Image = 'image', // todo
	Radio = 'radio',
	Dropdown = 'dropdown',
	Pick = 'pick',
}

export function canFormComponentTypeHaveValue(type: FormComponentType): boolean {
	return !(type === FormComponentType.If || type === FormComponentType.EndIf || type === FormComponentType.Button || type === FormComponentType.Text);
}

export function isFormInputComponent(component: FormComponent): component is FormInputComponent {
	return canFormComponentTypeHaveValue(component.type);
}
