import { CollectionReference, DocumentReference, DocumentSnapshot, Query, QuerySnapshot, SetOptions, Unsubscribe, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, queryEqual, refEqual, setDoc, updateDoc } from 'firebase/firestore';
import { idPrefixes } from 'src/app/core/shared/models/database/generalModels';
import { FirestoreDocument } from '../../models/database/generalModels';
import { ErrorCallback, makeErrorCallbackWithReturn } from '../errorUtilities';
import { BaseManagedListener } from '../subscriptionUtilities';
import { DotPatchAtom, toKeyValuePatch } from '../typeUtilities';
import { FirebaseWrapper, ResultCallback } from './databaseUtilities';

export const newDocument = (collectionRef: CollectionReference, idPrefixName: string) => {
	return doc(collectionRef, idPrefixes[idPrefixName] + doc(collectionRef).id);
}

export const newDocumentId = (collectionRef: CollectionReference, idPrefixName: string) => {
	return idPrefixes[idPrefixName] + doc(collectionRef).id;
}

///////////////////////////
///////// Wrapper /////////
///////////////////////////

class FirestoreWrapper extends FirebaseWrapper {
  private static instance: FirestoreWrapper;

  private constructor() {
    super();
  }

  public static getInstance(): FirestoreWrapper {
    if (!FirestoreWrapper.instance) {
      FirestoreWrapper.instance = new FirestoreWrapper();
    }

    return FirestoreWrapper.instance;
  }

	listen<T extends FirestoreDocument>(path: string, onResult: ResultCallback<T | undefined>): Unsubscribe {
		return listenToDocumentByRef<T>(doc(getFirestore(), path), (doc) => {
			onResult(doc);
		});
	}

	get<D extends FirestoreDocument>(path: string): Promise<D | undefined> {
		this.checkThreshold();
		return getDoc(doc(getFirestore(), path)).then((doc) => {
			if (!doc.exists()) { return undefined; }
			const data = doc.data() as D;
			data.docRef = doc.ref as DocumentReference;
			data.docId = doc.id;
			return data;
		}).catch(error => {
			console.error(`Error loading document at '${path}':`, error);
			return undefined;
		});
	}

	update(path: string, data: any): Promise<void> {
		this.checkThreshold();
		return updateDoc(doc(getFirestore(), path), data);
	}

	patch<T>(path: string, patch: DotPatchAtom<T>[]): Promise<void> {
		this.checkThreshold();
		const kvpPatch = toKeyValuePatch(patch);
		return updateDoc(doc(getFirestore(), path), kvpPatch);
	}

	remove(path: string): Promise<void> {
		this.checkThreshold();
		return deleteDoc(doc(getFirestore(), path));
	}

	set(path: string, data: any, options: SetOptions = {}): Promise<void> {
		this.checkThreshold();
		return setDoc(doc(getFirestore(), path), data, options);
	}
}

export const fsdb = FirestoreWrapper.getInstance();

///////////////////////////////
////// Get from snapshots /////
///////////////////////////////

export const getDocumentFromSnapshot = <D extends FirestoreDocument>(doc: DocumentSnapshot) => {
	if (!doc.exists()) { return undefined; }
  const data = doc.data() as D;
  data.docRef = doc.ref as DocumentReference;
	data.docId = doc.id;
  return data;
}

export const getDocumentsFromQuerySnapshot = <A extends FirestoreDocument>(querySnapshot: QuerySnapshot<any>): A[] => {
	return querySnapshot.empty ? [] : querySnapshot.docs.map(getDocumentFromSnapshot) as A[];
}

///////////////////////////
////// Load Functions /////
///////////////////////////

export const loadDocumentByRef = async <T extends FirestoreDocument>(ref: DocumentReference, errorCallBack?: ErrorCallback): Promise<T | undefined> => {
	return getDoc(ref).then(passResultOrErrorIfDoesNotExist(getDocumentFromSnapshot<T>, undefined, errorCallBack)).catch(makeErrorCallbackWithReturn(errorCallBack, undefined));
}

export const loadDocumentsFromQuery = async <T extends FirestoreDocument>(query: Query, errorCallBack?: ErrorCallback): Promise<T[] | undefined> => {
	return getDocs(query).then(getDocumentsFromQuerySnapshot<T>).catch(makeErrorCallbackWithReturn(errorCallBack, undefined));
}

export const loadDocumentsFromCollection = async <T extends FirestoreDocument>(collectionRef: CollectionReference, errorCallBack?: ErrorCallback): Promise<T[] | undefined> => {
	return loadDocumentsFromQuery<T>(collectionRef, errorCallBack);
}

///////////////////////////
////// Set Functions /////
///////////////////////////

export const setDocumentByRef = async <T extends FirestoreDocument>(ref: DocumentReference, data: Partial<T>, errorCallBack?: ErrorCallback): Promise<void> => {
	return setDoc(ref, data as any).then().catch(makeErrorCallbackWithReturn(errorCallBack, undefined));
}

export const updateDocumentByRef = async <T extends FirestoreDocument>(ref: DocumentReference, data: Partial<T>, errorCallBack?: ErrorCallback): Promise<void> => {
	return setDoc(ref, data as any, { merge: true }).then().catch(makeErrorCallbackWithReturn(errorCallBack, undefined));
}

/////////////////////////////
////// Listen Functions /////
/////////////////////////////

export const listenToDocumentByRef = <T extends FirestoreDocument>(ref: DocumentReference, resultCallback: ResultCallback<T | undefined>, errorCallBack?: ErrorCallback): Unsubscribe => {
  return onSnapshot(ref, (docSnapshot) => passResultOrErrorIfDoesNotExist(getDocumentFromSnapshot<T>(docSnapshot), resultCallback, errorCallBack), errorCallBack);
}

export const listenToDocumentsFromCollection = <T extends FirestoreDocument>(collectionRef: CollectionReference, resultCallback: ResultCallback<T[] | undefined>, errorCallBack?: ErrorCallback): Unsubscribe => {
	return onSnapshot(collectionRef, (querySnapshot) => resultCallback(getDocumentsFromQuerySnapshot<T>(querySnapshot)), errorCallBack);
}

export const listenToDocumentsFromQuery = <T extends FirestoreDocument>(query: Query, resultCallback: ResultCallback<T[] | undefined>, errorCallBack?: ErrorCallback): Unsubscribe => {
	return onSnapshot(query, (querySnapshot) => resultCallback(getDocumentsFromQuerySnapshot<T>(querySnapshot)), errorCallBack);
}

const passResultOrErrorIfDoesNotExist = <T>(value: T | undefined, resultCallback?: ResultCallback<T | undefined>, errorCallBack?: ErrorCallback): T | undefined => {
	if (value === undefined) {
		if (errorCallBack) {
			errorCallBack("Value was undefined");
		}
		return undefined;
	} else {
		if (resultCallback) {
			resultCallback(value);
		}
		return value;
	}
}

//////////////////////////////
////// Load with retries /////
//////////////////////////////

// a method that will keep retrying to load the data until it is successful, or until the user cancels the operation
export type CancelRetry = () => void;
export const loadDocumentByRefWithRetry = <T extends FirestoreDocument>(ref: DocumentReference, resultCallback: ResultCallback<T>, errorCallBack?: ErrorCallback, maxTries = 100, msBetweenTries = 1000): CancelRetry => {
	let cancel = false;
	let tries = 0;
	let errors: any[] = [];
	const load = () => {
		if (cancel) return;
		if (tries >= maxTries) {
			if (!errorCallBack) {
				console.error(`Failed to load document after ${tries} tries. Last 5 errors:`, errors.slice(-5));
			} else {
				errorCallBack(`Failed to load document after ${tries} tries. Last 5 errors: ${errors.slice(-5).join(", ")}`);
			}
			return;
		}
		tries++;
		loadDocumentByRef<T>(ref, (error) => {
			if (cancel) return;
			errors.push(error);
			setTimeout(load, msBetweenTries);
		}).then((data) => {
			if (data) {
				resultCallback(data);
			} else {
				setTimeout(load, msBetweenTries);
			}
		});
	}
	load();
	return () => { cancel = true; };
}

///////////////////////////////
////// Managed Listeners //////
///////////////////////////////

export class ManagedDocumentListenerByRef<T extends FirestoreDocument> extends BaseManagedListener<T, DocumentReference> {
	protected startListening(ref: DocumentReference): Unsubscribe {
		return listenToDocumentByRef(ref, this.resultCallback!, this.errorCallback);
	}

	protected refEqual(ref1: DocumentReference, ref2: DocumentReference): boolean {
		return refEqual(ref1, ref2);
	}
}

export class ManagedDocumentsListenerByQuery<T extends FirestoreDocument> extends BaseManagedListener<T[], Query> {
	protected startListening(query: Query): Unsubscribe {
		return listenToDocumentsFromQuery(query, this.resultCallback!, this.errorCallback);
	}

	protected refEqual(query1: Query, query2: Query): boolean {
		return queryEqual(query1, query2);
	}
}

export class ManagedDocumentsListenerByCollection<T extends FirestoreDocument> extends ManagedDocumentsListenerByQuery<T> {
	protected override startListening(collectionRef: CollectionReference): Unsubscribe {
		return super.startListening(collectionRef);
	}

	protected override refEqual(collectionRef1: CollectionReference, collectionRef2: CollectionReference): boolean {
		return refEqual(collectionRef1, collectionRef2);
	}
}
