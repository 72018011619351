<ng-container>
  <ng-container *ngIf="!loading; else showLoad">
      <ng-content></ng-content>
  </ng-container>
  <ng-template #showLoad>
    <div class="loader-guard loading m-2" style="text-wrap: nowrap;">
      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      <small class="opacity-50" localize="general.loading"></small>
    </div>
  </ng-template>
</ng-container>
