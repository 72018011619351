import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import { NavigationService } from 'src/app/core/shared/services/page/navigation.service';
import { UserService } from 'src/app/user/services/user.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private navigationService: NavigationService, private userService: UserService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return new Observable<boolean>(subscriber => {
			this.userService.user.subscribe(user => {
				if (user) {
					subscriber.next(true);
				} else if (route.data?.['signup']) {
					this.navigationService.navigate({ toRoute: 'signup', settings: { rememberCurrentUrlForReturn: true } });
					subscriber.next(false);
				} else {
					this.navigationService.navigate({ toRoute: 'login', settings: { rememberCurrentUrlForReturn: true } });
					subscriber.next(false);
				}
				subscriber.complete();
			});
		});
	}
}
