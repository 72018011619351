import { Component, inject } from '@angular/core';
import { PageState, PageStateKeys, PageStateService } from '../../../services/page/page-state.service';
import { deepMerge } from '../../../utilities/objectUtilities';
import { DestroyableComponent } from '../destroyable/destroyable.component';
import { empty } from '../../../utilities/typeUtilities';

export type ComponentState = PageState & ComponentStateLogic;

class ComponentStateLogic {
	private _requiredPageState: RequiredPageState;

	public wasValid: boolean = false;
	public isValid: boolean = false;
	get becameValid(): boolean {
		return this.isValid && !this.wasValid;
	}

	get becameInvalid(): boolean {
		return !this.isValid && this.wasValid;
	}

	/**
	 * Contains the key of the property that the component should update, as it has changed or the page or the component initialized.
	 */
	public shouldUpdate: ShouldUpdate = empty();
	public invalids: InvalidStates = empty();

	private constructor(requiredPageState: RequiredPageState) {
		this._requiredPageState = requiredPageState;
	}

	public static create(requiredPageState: RequiredPageState): [ComponentState, (pageState: PageState) => void] {
		const state = new ComponentStateLogic(deepMerge(defaultRequiredPageState, requiredPageState)) as ComponentState;
		return [state, state._onUpdate.bind(state)];
	}

	private _onUpdate(pageState: PageState) {
		this.wasValid = this.isValid;
		this._determineValidity(pageState);
		this._storePageState(pageState);
		this._determineShouldUpdate(pageState);
	}

	private _determineValidity(pageState: PageState) {
		this.isValid = true;
		this.invalids = empty();
		for (const key in this._requiredPageState) {
			if (key.endsWith('Required')) {
				const requiredKey = key as keyof RequiredPageState;
				const stateKey = key.slice(0, -8) as keyof PageStateKeys;
				if (this._requiredPageState[requiredKey] && !pageState[stateKey]) {
					this.invalids[stateKey] = true;
					this.isValid = false;
				}
			}
		}
	}

	private _storePageState(pageState: PageState) {
		if (!this.isValid) {
			for (let key in pageState) {
				this._asPageState[key as keyof PageStateKeys] = undefined;
			}
		} else {
			Object.assign(this, pageState);
		}
	}

	private _determineShouldUpdate(pageState: PageState) {
		this.shouldUpdate = empty();
		for ( const key in pageState) {
			this.shouldUpdate[key as keyof PageStateKeys] = this.becameValid || this.becameInvalid || (pageState.changed[key as keyof PageStateKeys] ?? false);
		}
	}

	private get _asPageState(): PageState {
		return this as Partial<PageState> as PageState;
	}
}

type ShouldUpdate = {
	[key in keyof PageStateKeys]: boolean;
}

type InvalidStates = {
	[key in keyof PageStateKeys]: boolean;
}

export type RequiredPageState = {
	[key in `${keyof PageStateKeys}Required`]?: boolean;
};

const defaultRequiredPageState: RequiredPageState = {
	isNonAnonymousUserRequired: true,
};

@Component({
  template: ``,
  styles: []
})
/**
 * This component is used to check if the component is valid. It is used to check if the user is logged in, if the user is a member of an organization, etc.
 *
 * Pages that extend this component should implement the following methods:
 * - requireAuthUser(): boolean
 * - requireUser(): boolean
 * - requireMembership(): boolean
 * - requireParamKeys(): string[]
 * - onPageStateUpdate(componentState: ComponentState): void
 *
 * The first four methods are used to check if the page is valid. The last method is used to update the page accordingly.
 *
 * This allows separation of concerns. Page components don't have to check if the page is valid and obtain the data themselves. They can just
 * implement the onPageStateUpdate method and use the data passed to them.
 *
 * Note that when a page is not valid, it may be because data is loading. Show a loader in that case.
 *
 * TODO: determine if this is the best way to do this. Maybe it is better to use an auth guard instead?
 */
export abstract class PageStateBasedComponent extends DestroyableComponent {

	public isComponentValid: boolean = false;

	protected pageStateService: PageStateService;
	public componentState: ComponentState;
	protected componentStateUpdater: (pageState: PageState) => void;

  protected abstract getRequiredPageState(): RequiredPageState | undefined;

  constructor() {
		super();
		this.pageStateService = inject(PageStateService);
		[this.componentState, this.componentStateUpdater] = ComponentStateLogic.create(this.getRequiredPageState() ?? empty());
  }

  ngOnInit(): void {
    const pageStateSubscription = this.pageStateService.pageState.subscribe(async (pageState) => {
      try {
				this.componentStateUpdater(pageState);
				this.isComponentValid = this.componentState.isValid;
        await this.onComponentStateUpdate(this.componentState);

      } catch (e) {
        console.error(e);
      }
    });

		this.addOnStopListener(() => pageStateSubscription.unsubscribe());
  }

  abstract onComponentStateUpdate(state: ComponentState): Promise<void>;
}
