import { BaseHtmlBit, HtmlBit } from "./base-html-bit";

export class SpanHtmlBit extends BaseHtmlBit {
	private _content: HtmlBit = '';

	constructor(content: HtmlBit) {
		super();
		this._content = content;
		this.listenTo(this._content);
		this.updateValue();
	}

	set content(content: HtmlBit) {
		this.stopListeningTo(this._content);
		this._content = content;
		this.listenTo(this._content);
		this.updateValue();
	}

	get html(): string {
		return `<span class="${this.classes}">${this._content}</span>`;
	}
};
