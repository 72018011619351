import { Context, ContextType, isContextType } from "../../../services/page/context.service";

export const languages = {
	nl: {
		name: 'Nederlands',
		flag: '🇳🇱',
	},
	en: {
		name: 'English',
		flag: '🇬🇧',
	},
};

type LocalizationModel = {
	// ###############################################
	"general.app": LocalizationText;
	"general.user": LocalizationText;
	"general.users": LocalizationText;
	"general.credits": LocalizationText;
	"general.creditsSign": LocalizationText;
	"general.creditsFormatter": LocalizationText;
	"general.organization": LocalizationText;
	"general.organizations": LocalizationText;
	"general.member": LocalizationText;
	"general.members": LocalizationText;
	"general.inviteLink": LocalizationText;
	"general.inviteLinks": LocalizationText;
	"general.giftcard": LocalizationText;
	"general.role": LocalizationText;
	"general.email": LocalizationText;
	"general.public": LocalizationText;
	"general.private": LocalizationText;
	// -----------------------------------------------
	"general.publicUrl": LocalizationText,
	// -----------------------------------------------
	"general.form": LocalizationText;
	"general.forms": LocalizationText;
	"general.template": LocalizationText;
	"general.templates": LocalizationText;
	"general.variable": LocalizationText;
	"general.variables": LocalizationText;
	"general.chat": LocalizationText;
	"general.chats": LocalizationText;
	"general.copy" : LocalizationText;
	"general.bookmark": LocalizationText;
	"general.home": LocalizationText;
	// -----------------------------------------------
	"general.remove": LocalizationText;
	"general.add": LocalizationText;
	"general.edit": LocalizationText;
	"general.update": LocalizationText;
	"general.deactivate": LocalizationText;
	"general.activate": LocalizationText;
	"general.delete": LocalizationText;
	"general.cancel": LocalizationText;
	"general.save": LocalizationText;
	"general.create": LocalizationText;
	"general.search": LocalizationText;
	// -----------------------------------------------
	"general.loading": LocalizationText;
	"general.underConstruction": LocalizationText;
	// ###############################################
	"role.owner": LocalizationText;
	"role.manager": LocalizationText;
	"role.creator": LocalizationText;
	"role.user": LocalizationText;
	"role.guest": LocalizationText;
	// ###############################################
	"permissions.public": LocalizationText;
	"permissions.organization": LocalizationText;
	"permissions.private": LocalizationText;
	// ###############################################
	"toasts.success.addedToOrganization": LocalizationText;
	// -----------------------------------------------
	"toasts.error.internal": LocalizationText;
	"toasts.error.unknown": LocalizationText;
	// -----------------------------------------------
	"toasts.error.notAuthenticated": LocalizationText;
	"toasts.error.notAdmin": LocalizationText;
	// -----------------------------------------------
	"toasts.error.noActiveOrganization": LocalizationText;
	"toasts.error.alreadyMemberOfOrganization": LocalizationText;
	"toasts.error.invalidEmail": LocalizationText;
	"toasts.error.inviteExpired": LocalizationText;
	"toasts.error.inviteOverused": LocalizationText;
	// -----------------------------------------------
	"toasts.error.promptNotFound": LocalizationText;
	"toasts.error.formNotFound": LocalizationText;
	// -----------------------------------------------
	"toasts.error.insufficientFunds": LocalizationText;
	"toasts.error.invalidCreditAmount": LocalizationText;
	// ###############################################
	//"guard.error.notLoggedIn.title": LocalizationText;
	"guard.error.notLoggedIn.description": LocalizationText;
	"guard.error.notLoggedIn.fixButtonText": LocalizationText;
	// -----------------------------------------------
	//"guard.error.noValidUserDoc.title": LocalizationText;
	"guard.error.noValidUserDoc.description": LocalizationText;
	"guard.error.noValidUserDoc.fixButtonText": LocalizationText;
	// -----------------------------------------------
	//"guard.error.noMembership.title": LocalizationText;
	"guard.error.noMembership.description": LocalizationText;
	"guard.error.noMembership.fixButtonText": LocalizationText;
	// -----------------------------------------------
	//"guard.error.missingParams.title": LocalizationText;
	"guard.error.missingParams.description": LocalizationText;
	"guard.error.missingParams.fixButtonText": LocalizationText;
	// -----------------------------------------------
	//"guard.error.creditsTooLow.title": LocalizationText;
	"guard.error.creditsTooLow.description": LocalizationText;
	"guard.error.creditsTooLow.fixButtonText": LocalizationText;
	// ###############################################
	"navbar.organization.switchTitle": LocalizationText;
	"navbar.user.noUser": LocalizationText;
	"navbar.user.noOrg": LocalizationText;
	"navbar.user.logout": LocalizationText;
	"navbar.noChatsFound": LocalizationText;
	"navbar.backToProd": LocalizationText;
	// ###############################################
	"component.template.generateButton": LocalizationText;
	"component.template.clearButton": LocalizationText;
	"component.template.privateCheckboxLabel": LocalizationText;
	"component.template.fileInputLabel": LocalizationText;
	"component.template.addBookmarkButton": LocalizationText;
	"component.template.removeBookmarkButton": LocalizationText;
	"component.template.setHomeButton": LocalizationText;
	"component.template.unsetHomeButton": LocalizationText;
	// ###############################################
	"component.chat.selectChat": LocalizationText;
	"component.chat.assistantName": LocalizationText;
	"component.chat.userName": LocalizationText;
	"component.chat.chatTitle": LocalizationText;
	// ###############################################
	"component.publicToken.createButton": LocalizationText;
	"component.publicToken.publicUrlLabel": LocalizationText;
	"component.publicToken.copyButtonTitle": LocalizationText;
	"component.publicToken.openButtonTitle": LocalizationText;
	"component.publicToken.deleteButtonTitle": LocalizationText;
	"component.publicToken.deleteConfirmText": LocalizationText;
	// ###############################################
	"component.navbar.orgInvite": LocalizationText;
	"component.navbar.lowBalance": LocalizationText;
	"component.navbar.lowBalanceButtonText": LocalizationText;
	// ###############################################
	"page.login.pageName": LocalizationText;
	"page.login.emailLabel": LocalizationText;
	"page.login.passwordLabel": LocalizationText;
	"page.login.passwordReset.back": LocalizationText;
	"page.login.passwordReset.link": LocalizationText;
	"page.login.passwordReset.button": LocalizationText;
	"page.login.passwordReset.title": LocalizationText;
	"page.login.loginButtonText": LocalizationText;
	"page.login.orLoginWithLabel": LocalizationText;
	// ###############################################
	"page.signUp.pageName": LocalizationText;
	"page.signUp.nameLabel": LocalizationText;
	"page.signUp.emailLabel": LocalizationText;
	"page.signUp.passwordLabel": LocalizationText;
	"page.signUp.confirmPasswordLabel": LocalizationText;
	"page.signUp.signUpButtonText": LocalizationText;
	"page.signUp.orSignUpWithLabel": LocalizationText;
	// ###############################################
	"page.generate.pageName": LocalizationText;
	"page.generate.sidebar.chats": LocalizationText;
	"page.generate.sidebar.templates": LocalizationText;
	"page.generate.sidebar.variables": LocalizationText;
	"page.generate.template.copyEmbedCodeText": LocalizationText;
	"page.generate.template.noDescriptionText": LocalizationText;
	"page.generate.template.templateButtonText": LocalizationText;
	"page.generate.template.editButtonText": LocalizationText;
	"page.generate.template.pickOtherChatButtonText": LocalizationText;
	"page.generate.chat.newChatLabel": LocalizationText;
	"page.generate.chat.newChatForTemplate.clean": LocalizationText;
	"page.generate.chat.newChatForTemplate.withSameInputs": LocalizationText;
	"page.generate.chat.currentLabel": LocalizationText;
	"page.generate.chat.changeName": LocalizationText;
	"page.generate.noTemplatesFound.title": LocalizationText;
	"page.generate.noTemplatesFound.description": LocalizationText;
	"page.generate.noTemplatesFound.createButtonText": LocalizationText;
	// ###############################################
	"page.templates.pageName": LocalizationText;
	"page.templates.createButtonText": LocalizationText;
	"page.generate.chat.searchFieldLabel": LocalizationText;
	"page.templates.searchTemplateLabel": LocalizationText;
	"page.templates.noTemplatesAvailable": LocalizationText;
	// ###############################################
	"page.template.edit|create.defaultFormName": LocalizationText;
	"page.template.edit|create.createTitle": LocalizationText;
	"page.template.edit|create.documentationLinkText": LocalizationText;
	"page.template.edit|create.nameLabel": LocalizationText;
	"page.template.edit|create.descriptionLabel": LocalizationText;
	"page.template.edit|create.systemPromptLabel": LocalizationText;
	"page.template.edit|create.userPromptLabel": LocalizationText;
	"page.template.edit|create.chatTypeLabel": LocalizationText;
	"page.template.edit|create.chatTypeText": LocalizationText;
	"page.template.edit|create.permissions.title": LocalizationText;
	"page.template.edit|create.permissions.description": LocalizationText;
	"page.template.edit|create.permissions.modifyLabel": LocalizationText;
	"page.template.edit|create.permissions.useLabel": LocalizationText;
	"page.template.edit|create.permissions.newChatLabel": LocalizationText;
	"page.template.edit|create.replyOptions.title": LocalizationText;
	"page.template.edit|create.replyOptions.textLabel": LocalizationText;
	"page.template.edit|create.replyOptions.promptLabel": LocalizationText;
	"page.template.edit|create.replyOptions.addButtonText": LocalizationText;
	"page.template.edit|create.preview.previewTitle": LocalizationText;
	"page.template.edit|create.preview.createTemplate": LocalizationText;
	"page.template.edit|create.preview.debuggingTitle": LocalizationText;
	"page.template.edit|create.preview.debuggingText": LocalizationText;
	"page.template.edit|create.preview.outputTitle": LocalizationText;
	// -----------------------------------------------
	"page.template.edit.pageName": LocalizationText;
	"page.template.edit.createdByLabel": LocalizationText;
	"page.template.edit.goToFormButtonText": LocalizationText;
	// -----------------------------------------------
	"page.template.create.pageName": LocalizationText;
	"page.template.create.createButtonText": LocalizationText;
	// ###############################################
	"page.variables.pageName": LocalizationText;
	"page.variables.createButtonText": LocalizationText;
	"page.variables.searchFieldLabel": LocalizationText;
	"page.variables.noVariablesAvailable": LocalizationText;
	// ###############################################
	"page.organizations.pageName": LocalizationText;
	"page.organizations.noneFoundLabel": LocalizationText;
	"page.organizations.createButtonText": LocalizationText;
	"page.organizations.invitations.title": LocalizationText;
	"page.organizations.invitations.noneFoundLabel": LocalizationText;
	"page.organizations.invitations.inviteToJoinLabel": LocalizationText;
	"page.organizations.invitations.acceptButton": LocalizationText;
	"page.organizations.invitations.declinceButton": LocalizationText;
	// ###############################################
	"page.organizations.create.title": LocalizationText;
	"page.organizations.create.nameLabel": LocalizationText;
	"page.organizations.create.createButtonText": LocalizationText;
	// ###############################################
	"page.organization.settings.pageName": LocalizationText;
	// ###############################################
	"page.organization.members.pageName": LocalizationText;
	// -----------------------------------------------
	"page.organization.members.current.column.member": LocalizationText;
	"page.organization.members.current.column.role": LocalizationText;
	"page.organization.members.current.column.membersince": LocalizationText;
	"page.organization.members.current.column.invitedBy": LocalizationText;
	"page.organization.members.current.column.invitedBy.usedLink": LocalizationText;
	"page.organization.members.current.column.status": LocalizationText;
	"page.organization.members.current.column.actions": LocalizationText;
	"page.organization.members.current.noPermission": LocalizationText;
	// -----------------------------------------------
	"page.organization.members.invites.title": LocalizationText;
	"page.organization.members.invites.invite": LocalizationText;
	"page.organization.members.invites.column.sent": LocalizationText;
	"page.organization.members.invites.column.email": LocalizationText;
	"page.organization.members.invites.column.role": LocalizationText;
	"page.organization.members.invites.column.status": LocalizationText;
	"page.organization.members.invites.column.invitedBy": LocalizationText;
	"page.organization.members.invites.column.expires": LocalizationText;
	"page.organization.members.invites.column.actions": LocalizationText;
	"page.organization.members.invites.column.action.removeButtonText": LocalizationText;
	// -----------------------------------------------
	"page.organization.members.inviteLinks.title": LocalizationText;
	"page.organization.members.inviteLinks.create": LocalizationText;
	"page.organization.members.inviteLinks.column.created": LocalizationText;
	"page.organization.members.inviteLinks.column.link": LocalizationText;
	"page.organization.members.inviteLinks.column.role": LocalizationText;
	"page.organization.members.inviteLinks.column.usesLeft": LocalizationText;
	"page.organization.members.inviteLinks.column.expires": LocalizationText;
	"page.organization.members.inviteLinks.column.createdBy": LocalizationText;
	"page.organization.members.inviteLinks.column.actions": LocalizationText;
	"page.organization.members.inviteLinks.column.action.removeButtonText": LocalizationText;
	// -----------------------------------------------
	"page.branding.pageName": LocalizationText;
	// ###############################################
	"page.user.account.details": LocalizationText;
	"page.user.account.pageName": LocalizationText;
	"page.user.account.details.name": LocalizationText;
	"page.user.account.details.photo": LocalizationText;
	"page.user.account.login.title": LocalizationText;
	"page.user.account.login.changeEmail": LocalizationText;
	"page.user.account.login.email": LocalizationText;
	"page.user.account.login.password": LocalizationText;
	"page.user.account.login.newPassword": LocalizationText;
	"page.user.account.login.repeatPassword": LocalizationText;
	"page.user.account.login.providers.title": LocalizationText;
	"page.user.account.login.providers.description": LocalizationText;
	"page.user.account.login.provider.add": LocalizationText;
	"page.user.account.login.provider.email": LocalizationText;
	"page.user.account.login.provider.google": LocalizationText;
	"page.user.account.delete.title": LocalizationText;
	"page.user.account.delete.description": LocalizationText;
	"page.user.account.delete.confirm": LocalizationText;
	// ###############################################
	"page.billing.pageName": LocalizationText;
	"page.billing.balance.title": LocalizationText;
	"page.billing.balance.description": LocalizationText;
	// -----------------------------------------------
	"page.billing.purchaseMore.title": LocalizationText;
	"page.billing.purchaseMore.description": LocalizationText;
	"page.billing.purchaseMore.tab.forMe": LocalizationText;
	"page.billing.purchaseMore.tab.forOther": LocalizationText;
	"page.billing.purchaseMore.purchaseButtonText.forMe": LocalizationText;
	"page.billing.purchaseMore.purchaseButtonText.forOther": LocalizationText;
	"page.billing.purchaseMore.purchaseIsGiftCheckboxLabel": LocalizationText;
	// -----------------------------------------------
	"page.billing.giftcards.title": LocalizationText;
	"page.billing.giftcards.description": LocalizationText;
	"page.billing.giftcards.column.code": LocalizationText;
	"page.billing.giftcards.column.amount": LocalizationText;
	"page.billing.giftcards.column.boughtOn": LocalizationText;
	"page.billing.giftcards.column.redeemedBy": LocalizationText;
	"page.billing.giftcards.column.redeemedOn": LocalizationText;
	"page.billing.giftcards.column.link": LocalizationText;
	// -----------------------------------------------
	"page.billing.creditHistory.title": LocalizationText;
	"page.billing.creditHistory.description": LocalizationText;
	"page.billing.creditHistory.column.amount": LocalizationText;
	"page.billing.creditHistory.column.savings": LocalizationText;
	"page.billing.creditHistory.column.savings.notice": LocalizationText;
	"page.billing.creditHistory.column.user": LocalizationText;
	"page.billing.creditHistory.column.reason": LocalizationText;
	"page.billing.creditHistory.column.date": LocalizationText;
	"page.billing.creditHistory.column.actions": LocalizationText;
	"page.billing.creditHistory.column.action.viewChat": LocalizationText;
	"page.billing.creditHistory.column.action.invoiceButtonText": LocalizationText;
	"page.billing.creditHistory.column.action.invoiceDownloadTitle": LocalizationText;
	// ###############################################
	"page.giftcard.buy.title": LocalizationText;
	"page.giftcard.buy.buyButtonText": LocalizationText;
	// -----------------------------------------------
	"page.giftcard.redeem.title": LocalizationText;
	"page.giftcard.redeem.notFound": LocalizationText;
	"page.giftcard.redeem.notActivated": LocalizationText;
	"page.giftcard.redeem.yourCard": LocalizationText;
	"page.giftcard.redeem.yourCardWarning": LocalizationText;
	"page.giftcard.redeem.logInWarning": LocalizationText;
	"page.giftcard.redeem.alreadyRedeemed": LocalizationText;
	"page.giftcard.redeem.alreadyRedeemedYourself": LocalizationText;
	"page.giftcard.redeem.redeemButtonText": LocalizationText;
	"page.giftcard.redeem.redeemYourself": LocalizationText;
	"page.giftcard.redeem.giftCardInfo": LocalizationText;
	"page.giftcard.redeem.giftCardInfo1": LocalizationText;
	"page.giftcard.redeem.giftCardInfo2": LocalizationText;
	"page.giftcard.redeem.giftCardInfo3": LocalizationText;
	"page.giftcard.redeem.messagePlaceholder": LocalizationText;
	"page.giftcard.redeem.redeemInfo": LocalizationText;
	"page.giftcard.redeem.alreadyRedeemedInfo": LocalizationText;
	"page.giftcard.redeem.someoneAlreadyRedeemedInfo": LocalizationText;
	// ###############################################
	"page.payment.success.title": LocalizationText;
	"page.payment.success.description": LocalizationText;
	// ###############################################
	"hostComponent.aisha.vmbo": LocalizationText;
	"hostComponent.aisha.havo": LocalizationText;
	"hostComponent.aisha.vwo": LocalizationText;
};

export type LanguageCode = keyof typeof languages;
export type AppLocalization = Record<LanguageCode, Record<LocalizationStaticKey, LocalizationText>>;
export type AppLocalizationModifications = { [key in LanguageCode]?: { [key in LocalizationStaticKey]?: LocalizationText } };

export type LocalizationStaticKey = keyof LocalizationModel;
export type LocalizationContextType = ContextType | 'object' | 'count';
export type LocalizationContextKey = `@${LocalizationContextType}`;
export type LocalizationContext = Context & { object?: string, count?: number | string };
export type LocalizationKey = LocalizationStaticKey | LocalizationContextKey;

export function isLocalizationContextType(value: string): value is LocalizationContextType {
	return value === 'object' || value === 'count' || isContextType(value);
}
export function isLocalizationContextKey(value: string) {
	return value.startsWith('@') && isLocalizationContextType(value.slice(1));
}

export type LocalizationTransformType = 'capitalize' | 'uppercase' | 'lowercase' | 'titlecase' | 'sentencecase';
export type LocalizationWithTransform = { key: LocalizationKey, transform?: LocalizationTransformType };
export type LocalizationArg = LocalizationKey | LocalizationWithTransform;

export type LocalizationWithArgs = {
	value: string;
	args: LocalizationArg[];
};

export type LocalizationText = string | LocalizationWithTransform | LocalizationWithArgs;
