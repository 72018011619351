import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import * as firebaseUI from 'firebaseui';
import { environment } from 'src/environments/environment';

export const fbApp = initializeApp(environment.firebaseConfig);
export const fbFirestore = getFirestore(fbApp);
export const fbFunctions = getFunctions(fbApp, 'europe-west1');
export const fbAuth = getAuth(fbApp);
export const fbDatabase = getDatabase(fbApp);
export const fbStorage = getStorage(fbApp);
export const fbUI = new firebaseUI.auth.AuthUI(fbAuth);

