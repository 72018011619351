import { Injectable } from '@angular/core';
import { getDocs, orderBy, query } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { ManagedDocumentListenerByRef, fsdb, listenToDocumentsFromQuery } from "../../core/shared/utilities/firebase/firestoreUtilities";
import { createStripeCheckoutSession as createStripeCreditsCheckoutSession, productCollection } from 'src/app/core/shared/utilities/database/paymentUtilities';
import { CreateCreditsCheckoutSessionBody5, CreateCheckoutSessionResult5, WalletOwnerType5 } from 'src/app/core/shared/models/database/paymentModels';
import { WalletCreditChange5, WalletCreditData5, WalletOwner5, WalletPaymentData5 } from 'src/app/core/shared/models/database/walletModels';
import { walletCreditsDataDoc, walletCreditsHistoryCollection } from 'src/app/core/shared/utilities/database/walletUtilities';
import { organizationPaymentDoc } from 'src/app/core/shared/utilities/database/organizationUtilities';
import { OrganizationPaymentData5 } from 'src/app/core/shared/models/database/organizationModels';
import { userPaymentDoc } from 'src/app/core/shared/utilities/database/userUtilities';
import { UserService } from 'src/app/user/services/user.service';
import { DestroyableComponent } from 'src/app/core/shared/components/base/destroyable/destroyable.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export type FormattedWalletCreditData5 = WalletCreditData5 & {
  formattedCredits: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends DestroyableComponent {
	public userWalletBalance: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
	private userWalletBalanceListener: ManagedDocumentListenerByRef<WalletCreditData5> = new ManagedDocumentListenerByRef(
		(balance: WalletCreditData5 | undefined) => { this.userWalletBalance.next(balance?.credits); },
		(error: Error) => { console.error('Error listening to wallet balance', error); })
		.hookStop(this)
		.enableRetry();

  constructor(
		private userService: UserService,
		private gaService: GoogleAnalyticsService)
	{
		super();

		this.userService.user.subscribe((user) => {
			if (!user) {
				this.userWalletBalanceListener.pause();
				return;
			}

			this.listenForUserWalletBalance();
		});
	}

	private async listenForUserWalletBalance() {
    const userId = this.userService.user.value?.docRef?.id;
    if (!userId) return;

		// TODO user helper functions for this
		// (copy the backed onces when we merge the repos)
		const paymentDocRef = userPaymentDoc(userId);
		const paymentData = await fsdb.get(paymentDocRef.path) as OrganizationPaymentData5;
		const walletId = paymentData.walletPath.id;
		const walletCreditsDataRef = walletCreditsDataDoc(walletId);
		this.userWalletBalanceListener.listenTo(walletCreditsDataRef);
	}

	public get userWalletBalanceLow(): boolean {
		const balance = this.userWalletBalance.value;
		if (balance === undefined) return false;
		return balance < 0.5;
	}

	async getWalletId(owner: WalletOwner5) {
		switch (owner.ownerType) {
			case WalletOwnerType5.ORG: {
				const organizationPaymentDataRef = organizationPaymentDoc(owner.ownerPath.id);
				const organizationPaymentData = await fsdb.get<OrganizationPaymentData5>(organizationPaymentDataRef.path);
				if (!organizationPaymentData) throw new Error('No payment data found for org');
				const walletId = organizationPaymentData.walletPath.id;
				return walletId;
			}

			case WalletOwnerType5.USER: {
				const userPaymentDataRef = userPaymentDoc(owner.ownerPath.id);
				const userPaymentData = await fsdb.get<OrganizationPaymentData5>(userPaymentDataRef.path);
				if (!userPaymentData) throw new Error('No payment data found for user');
				const walletId = userPaymentData.walletPath.id;
				return walletId;
			}

			default: {
				throw new Error("Invalid owner type");
			}
		}
	}

  createAndSendToCreditsCheckout(quantity: number, walletId: string, giftcard: boolean): Promise<void> {
		return new Promise<void>((_, reject) => {
			try {
				const body: CreateCreditsCheckoutSessionBody5 = {
					quantity: quantity,
					walletId: walletId,
					giftcard: giftcard,
				};
				createStripeCreditsCheckoutSession(body).then(async (data) => {
					const result = data.data as CreateCheckoutSessionResult5;
					if (!result.success) throw new Error('Failed to create checkout session');
					this.gaService.event('wallet_credit_purchase', 'wallet', walletId, undefined, undefined, { quantity: quantity, current_balance: this.userWalletBalance.value });
					window.location.href = result.checkoutUrl;
				});
			} catch (error) {
				reject(error);
			}
		});
  }

  toCurrencyString(amount: number): string {
    return amount.toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    }).replace(/\s/g, '');;
  }
}
