import { FormComponent as FormComp, FormInputComponent as FormInputComp, FormComponentType, isFormInputComponent, FormOptionsComponent, FormCheckboxComponent, canFormComponentTypeHaveValue } from 'src/app/core/shared/models/FormInput';
import {
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnInit,
	Output, QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormInputComponent } from '../../inputs/dynamic-input/form-input.component';

type FormInputSetup = FormInputComp & { controlName: string };

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges {

	@Input() components: FormComp[] | undefined;
	@Input() values: Array<string> | undefined;
	@Input() active: boolean = true;
	@Output('onSubmit') onSubmitEvent: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

	@ViewChildren('formInput') formInputs!: QueryList<FormInputComponent>;

  protected form!: FormGroup;
	protected inputs: (FormInputSetup | undefined)[] = [];
	protected inputValues: Record<string, any> = {};

  constructor(
    private fb: FormBuilder,
		private zone: NgZone,
  ) {
    this.form = this.fb.group({});
  }

	ngOnInit(): void {
		this.convertComponentsToInputsAndSetupForm();
		this.setValuesToInputs();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['components']) {
			this.convertComponentsToInputsAndSetupForm();
		}
		if (changes['values']) {
			this.setValuesToInputs();
		}
	}

	convertComponentsToInputsAndSetupForm() {
		if (!this.components) {
			this.inputs = [];
			return;
		}
		this.inputs = this.components.map((part, index) => {
			if (isFormInputComponent(part)) {
				return {
					...part,
					controlName: part.name ? part.name : `control_${index}`
				};
			}
			return undefined;
		}) as (FormInputSetup | undefined)[];
		this.setupForm();
	}

	clearInputValues() {
		this.formInputs.forEach(input => input.clearInputValues());
		// this.values = [];
		// this.inputValues = {};
		// this.setValuesToInputs();
	}

  setupForm() {
    if (!this.components) return;
    // this.components.forEach((part, index) => {
    //   switch (part.type) {
    //     case FormComponentType.Checkbox:
    //       this.form.addControl(`control_${index}`, this.fb.control(false));
    //       break;
    //     case FormComponentType.Radio:
    //     case FormComponentType.Dropdown:
    //       this.form.addControl(`control_${index}`, this.fb.control(''));
    //       break;
    //     default:
    //       this.form.addControl(`control_${index}`, this.fb.control(''));
    //       break;
    //   }
    // });
		this.setValuesToInputs();
  }

	setValuesToInputs() {
		setTimeout(() => {
			if (!this.components) return;
			if (!this.values) return;
			//console.log('setting values to inputs', this.values);
			this.values.forEach((value, index) => {
				const controlName = this.inputs[index]?.controlName ?? `control_${index}`;
				this.form.get(controlName)?.setValue(value);
			});
		}, 50);
	}

	handleEnter(event: Event, index: number): void {
		const keyboardEvent = event as KeyboardEvent;
		const inputsArray = this.formInputs.toArray();

		if (event.target instanceof HTMLTextAreaElement) {
			const textAreaElement = event.target as HTMLTextAreaElement;
			const caretPosition = textAreaElement.selectionStart;
			const textLength = textAreaElement.value.length;

			if (caretPosition !== textLength) {
				return; // Do not submit/next if the caret is not at the end of the text
			}
		}

		if (keyboardEvent.shiftKey) {
			return;
		}

		if (index === inputsArray.length - 1) {
			this.onSubmit();
		} else {
			const nextInput = inputsArray[index + 1];
			if (nextInput) {
				nextInput.focus();
			}
		}

		keyboardEvent.preventDefault();
	}

	onSubmit() {
		console.log("Submitting dynamic form")

    let values: any[] = [];
    if (!this.components) {
			console.log("Warning: empty form submitted");
		} else {
    	this.components.forEach((part, index) => {
				if (!canFormComponentTypeHaveValue(part.type)) {
					values.push('');
					return;
				}

				const controlName = this.inputs[index]?.controlName ?? `control_${index}`;
    	  const controlValue = this.form.get(controlName)?.value;
				console.log('controlName', controlName, 'controlValue', controlValue);

    	  if (part.type === FormComponentType.Checkbox) {
					values.push(controlValue);
    	  } else if (part.type === FormComponentType.Radio || part.type === FormComponentType.Dropdown || part.type === FormComponentType.Pick) {
					console.log('options part:', part);
					const typedPart = part as FormOptionsComponent;
    	    if (typedPart.options !== undefined) {
    	      if (Array.isArray(typedPart.options)) {
							values.push(controlValue);
    	      } else {
    	        const selectedOption = typedPart.options[controlValue];
    	        values.push(selectedOption);
    	      }
    	    }
    	  } else {
    	    values.push(controlValue);
    	  }
    	});
		}

		this.onSubmitEvent.emit(values);
	}
}
