import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { VerticalLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { FlexColumnLayoutElementComponent } from '../base/flex-column-layout-element.component';

@Component({
  selector: 'app-vertical-layout-group',
  template: `<ng-template #container></ng-template>`,
  styles: []
})
export class VerticalLayoutGroupComponent extends FlexColumnLayoutElementComponent {
	@ViewChild('container', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;

	@Input() elements: VerticalLayoutGroup['elements'] = [];

	protected override setupAllValues(): void {
		this.setupValueForKey('elements');
	}

	override setupValueForKey(key: keyof this): void {
		if (key !== 'elements') return;
		this.draw(this.container, this.elements);
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// Do nothing
	}
}
