import { AppLocalization } from "../app/core/shared/models/config/localization/localizationModels";

export const defaultLocalization: AppLocalization = {
	en: {
		// ###############################################
		"general.app": "Gen8",
		"general.user": "user",
		"general.users": { value: "{{arg}}s", args: ["general.user"] },
		"general.credits": "credits",
		"general.creditsSign": `<i class="fa-solid fa-bolt"></i>`,
		"general.creditsFormatter": { value: `{{arg}} {{arg}}`, args: ['general.creditsSign', '@count']},
		"general.organization": "organization",
		"general.organizations": { value: "{{arg}}s", args: ["general.organization"] },
		"general.member": "member",
		"general.members": { value: "{{arg}}s", args: ["general.member"] },
		"general.inviteLink": "invite link",
		"general.inviteLinks": "invite links",
		"general.giftcard": "gift card",
		"general.role": "role",
		"general.email": "email",
		"general.public": "public",
		"general.private": "private",
		"general.template": "template",
		"general.templates": { value: "{{arg}}s", args: ["general.template"] },
		"general.form": "form",
		"general.forms": { value: "{{arg}}s", args: ["general.form"] },
		"general.chat": "chat",
		"general.chats": { value: "{{arg}}s", args: ["general.chat"] },
		"general.variable": "definition",
		"general.variables": { value: "{{arg}}s", args: ["general.variable"] },
		"general.copy": "Copy",
		"general.home": "anchor",
		"general.bookmark": "bookmark",
		"general.publicUrl": "public URL",
		"general.loading": "Loading...",
		"general.underConstruction": "Under construction",
		"general.remove": "remove",
		"general.add": "add",
		"general.edit": "edit",
		"general.update": "update",
		"general.deactivate": "deactivate",
		"general.activate": "activate",
		"general.delete": "delete",
		"general.cancel": "cancel",
		"general.save": "save",
		"general.create": "create",
		"general.search": "search",
		// ###############################################
		"role.owner": "owner",
		"role.manager": "manager",
		"role.creator": "creator",
		"role.user": "user",
		"role.guest": "guest",
		// ###############################################
		"permissions.public": "Everyone",
		"permissions.organization": { key: "general.organization", transform: "capitalize" },
		"permissions.private": "Only you",
		// ###############################################
		"toasts.success.addedToOrganization": { value: "You have been added to the {{arg}}", args: ["general.organization"] },
		// -----------------------------------------------
		"toasts.error.internal": "An error occurred. Please try again",
		"toasts.error.unknown": { key: "toasts.error.internal" },
		// -----------------------------------------------
		"toasts.error.notAuthenticated": "You are not authenticated. Please log in to perform this action.",
		"toasts.error.notAdmin": "This action is only available to Gen8 admins.",
		// -----------------------------------------------
		"toasts.error.noActiveOrganization": { value: "There is no active {{arg}}. Please select or join an {{arg}} to perform this action.", args: ["general.organization", "general.organization"] },
		"toasts.error.alreadyMemberOfOrganization": { value: "You are already a member of this {{arg}}.", args: ["general.organization"] },
		"toasts.error.invalidEmail": { value: "Please enter a valid {{arg}}.", args: ["general.email"] },
		"toasts.error.inviteExpired": { value: "This {{arg}} has expired.", args: ["general.inviteLink"] },
		"toasts.error.inviteOverused": { value: "This {{arg}} has been used too many times.", args: ["general.inviteLink"] },
		// -----------------------------------------------
		"toasts.error.promptNotFound": "Could not obtain prompt for this chat.",
		"toasts.error.formNotFound": { value: "Could not obtain {{arg}}.", args: ["general.form"] },
		// -----------------------------------------------
		"toasts.error.insufficientFunds": { value: "You or your {{arg}} do not have enough {{arg}} to perform this action.", args: ["general.organization", "general.credits"] },
		"toasts.error.invalidCreditAmount": "Please choose an integer amount between the specified range.",
		// ###############################################
		"guard.error.notLoggedIn.description": "Please log in to view this page",
		"guard.error.notLoggedIn.fixButtonText": "Log in",
		// -----------------------------------------------
		"guard.error.noValidUserDoc.description": "You're logged in but information on your account is (still) missing. Try loggin out and in again. If this issue remains, please contact info&#64;gen8.app about this",
		"guard.error.noValidUserDoc.fixButtonText": "Logout",
		// -----------------------------------------------
		"guard.error.noMembership.description": "You are not currently part of any organization",
		"guard.error.noMembership.fixButtonText": "Create an organization",
		// -----------------------------------------------
		"guard.error.missingParams.description": "The adress of the page you are visiting is incomplete",
		"guard.error.missingParams.fixButtonText": "Go back",
		// -----------------------------------------------
		"guard.error.creditsTooLow.description": "You do not have enough credits",
		"guard.error.creditsTooLow.fixButtonText": "Buy credits",
		// ###############################################
		"navbar.organization.switchTitle": { value: "Switch {{arg}}", args: ["general.organization"] },
		"navbar.user.noUser": "...",
		"navbar.user.noOrg": "...",
		"navbar.user.logout": "Logout",
		"navbar.noChatsFound": "No chats found...",
		"navbar.backToProd": "back to production",
		// ###############################################
		"component.template.generateButton": "Generate",
		"component.template.clearButton": "Clear",
		"component.template.privateCheckboxLabel": { value: "Private {{arg}}?", args: ["general.chat"] },
		"component.template.fileInputLabel": "Add, drop or paste images...",
		"component.template.addBookmarkButton": { value: "{{arg}} this {{arg}} for yourself", args: ["general.bookmark", "general.template"] },
		"component.template.removeBookmarkButton": { value: "Remove {{arg}}", args: ["general.template"] },
		"component.template.setHomeButton": { value: "Set {{arg}} as your {{arg}} for this {{arg}}", args: ["general.template", "general.home", "general.organization"] },
		"component.template.unsetHomeButton": { value: "Remove {{arg}} as your {{arg}} for this {{arg}}", args: ["general.template", "general.home", "general.organization"] },
		// ###############################################
		"component.chat.selectChat": { value: "Select {{arg}}?", args: ["general.chat"] },
		"component.chat.assistantName": "Gen8",
		"component.chat.userName": { key: "@user" },
		"component.chat.chatTitle": { key: "general.chat", transform: "titlecase"},
		// ###############################################
		"component.publicToken.createButton": { value: "Create {{arg}}", args: ["general.publicUrl"] },
		"component.publicToken.publicUrlLabel": { value: "🌎 {{arg}}", args: ["general.publicUrl"] },
		"component.publicToken.copyButtonTitle": { value: "Copy {{arg}} to clipboard", args: ["general.publicUrl"] },
		"component.publicToken.openButtonTitle": { value: "Open {{arg}} in new window", args: ["general.publicUrl"] },
		"component.publicToken.deleteButtonTitle": { value: "Delete {{arg}}", args: ["general.publicUrl"] },
		"component.publicToken.deleteConfirmText": { value: "Are you sure you want to remove this {{arg}}?", args: ["general.publicUrl"] },
		// ###############################################
		"component.navbar.orgInvite": "You have been invited to join:",
		"component.navbar.lowBalance": { value: "Helped by {{arg}}? Buy more {{arg}} to continue using our platform", args: ["general.app", "general.credits"] },
		"component.navbar.lowBalanceButtonText": { value: "Buy {{arg}}", args: ["general.credits"] },
		// ###############################################
		"page.login.pageName": "Login",
		"page.login.emailLabel": { key: "general.email", transform: "capitalize"},
		"page.login.passwordLabel": "Password",
		"page.login.passwordReset.back": "Cancel",
		"page.login.passwordReset.link": "Forgot your password?",
		"page.login.passwordReset.button": "Send reset email",
		"page.login.passwordReset.title": "Reset password",
		"page.login.loginButtonText": "Login",
		"page.login.orLoginWithLabel": "Or login with",
		// ###############################################
		"page.signUp.pageName": "Sign up",
		"page.signUp.nameLabel": "Name",
		"page.signUp.emailLabel": { key: "general.email", transform: "capitalize"},
		"page.signUp.passwordLabel": "Password",
		"page.signUp.confirmPasswordLabel": "Confirm password",
		"page.signUp.signUpButtonText": "Sign up",
		"page.signUp.orSignUpWithLabel": "Or sign up with",
		// ###############################################
		"page.generate.pageName": "Generate",
		"page.generate.sidebar.chats": { key: "general.chats", transform: "titlecase" },
		"page.generate.sidebar.templates": { key: "general.templates", transform: "titlecase" },
		"page.generate.sidebar.variables": { key: "general.variables", transform: "titlecase" },
		"page.generate.template.copyEmbedCodeText": "Copy embed code",
		"page.generate.template.noDescriptionText": "No description available...",
		"page.generate.template.templateButtonText": { value: "{{arg}} page", args: ["general.template"] },
		"page.generate.template.editButtonText": { value: "Edit {{arg}}", args: ["general.template"] },
		"page.generate.template.pickOtherChatButtonText": { value: "Pick other {{arg}}", args: ["general.chat"] },
		"page.generate.chat.searchFieldLabel": { value: "Search {{arg}}...", args: ["general.chat"] },
		"page.generate.chat.newChatLabel": { value: "Start a new {{arg}}...", args: ["general.chat"] },
		"page.generate.chat.currentLabel": { value: "Current {{arg}}", args: ["general.chat"] },
		"page.generate.chat.newChatForTemplate.clean": { value: "Start a new {{arg}} for this {{arg}}", args: ["general.chat", "general.template"] },
		"page.generate.chat.newChatForTemplate.withSameInputs": { value: "Start a new {{arg}} for this {{arg}} with the same inputs", args: ["general.chat", "general.template"] },
		"page.generate.chat.changeName": "Change name",
		"page.generate.noTemplatesFound.title": { value: "No {{arg}} found", args: ["general.templates"] },
		"page.generate.noTemplatesFound.description": { value: "You can create a new {{arg}} or ask someone in your organization to create one for you.", args: ["general.template"] },
		"page.generate.noTemplatesFound.createButtonText": { value: "Create {{arg}}", args: ["general.template"] },
		// ###############################################
		"page.templates.pageName": { transform: "titlecase", key: "general.templates" },
		"page.templates.createButtonText": { value: "Create {{arg}}" , args: ["general.template"] },
		"page.templates.searchTemplateLabel": { value: "Search {{arg}}...", args: ["general.template"] },
		"page.templates.noTemplatesAvailable": { value: "There are no {{arg}}.", args: ["general.templates"] },
		// ###############################################
		"page.template.edit|create.defaultFormName": { value: "Nameless {{arg}}", args: ["general.form"] },
		"page.template.edit|create.createTitle": { value: "Create {{arg}}", args: ["general.form"] },
		"page.template.edit|create.documentationLinkText": "Documentation",
		"page.template.edit|create.nameLabel": "Name",
		"page.template.edit|create.descriptionLabel": "Description",
		"page.template.edit|create.systemPromptLabel": "System prompt",
		"page.template.edit|create.userPromptLabel": "Prompt",
		"page.template.edit|create.chatTypeLabel": "Transcribe?",
		"page.template.edit|create.chatTypeText": "When set to transcribe, each image is processed in a seperate request. Usually this is not what you want!",
		"page.template.edit|create.permissions.title": "Permissions",
		"page.template.edit|create.permissions.description": { value: "Who can use this {{arg}} and for what?", args: ["general.template"] },
		"page.template.edit|create.permissions.modifyLabel": { value: "Modify this {{arg}}", args: ["general.template"] },
		"page.template.edit|create.permissions.useLabel": { value: "Use this {{arg}}", args: ["general.template"] },
		"page.template.edit|create.permissions.newChatLabel": { value: "Start {{arg}} with this {{arg}}", args: ["general.chat", "general.template"] },
		"page.template.edit|create.replyOptions.title": "Reply options",
		"page.template.edit|create.replyOptions.textLabel": "Text",
		"page.template.edit|create.replyOptions.promptLabel": "Subprompt",
		"page.template.edit|create.replyOptions.addButtonText": "Add",
		"page.template.edit|create.preview.previewTitle": { value: "Preview {{arg}}", args: ["general.form"] },
		"page.template.edit|create.preview.createTemplate": { value: "You will be able to test your {{arg}} here once it is finished...", args: ["general.form"] },
		"page.template.edit|create.preview.debuggingTitle": "Debug prompt",
		"page.template.edit|create.preview.debuggingText": "Start a chat to see the raw prompt...",
		"page.template.edit|create.preview.outputTitle": "Preview output",
		// -----------------------------------------------
		"page.template.edit.pageName": { value: "Edit {{arg}}", args: ["general.template"] },
		"page.template.edit.createdByLabel": "Created by",
		"page.template.edit.goToFormButtonText": { value: "Go to this {{arg}}", args: ["general.form"] },
		// -----------------------------------------------
		"page.template.create.pageName": { value: "Create {{arg}}", args: ["general.template"] },
		"page.template.create.createButtonText": "Create",
		// ###############################################
		"page.variables.pageName": { key: "general.variables", transform: "titlecase" },
		"page.variables.createButtonText": { value: "Create {{arg}}", args: ["general.variable"] },
		"page.variables.searchFieldLabel": { value: "Search {{arg}}...", args: ["general.variable"] },
		"page.variables.noVariablesAvailable": { value: "No {{arg}} found", args: ["general.variables"] },
		// ###############################################
		"page.organizations.pageName": { key: "general.organizations", transform: "titlecase"},
		"page.organizations.noneFoundLabel": { value: "You are not a {{arg}} of any {{arg}}. Create a new {{arg}} or ask someone to invite you.", args: ["general.member", "general.organization", "general.organization"] },
		"page.organizations.createButtonText": "Create",
		"page.organizations.invitations.title": "Invitations",
		"page.organizations.invitations.noneFoundLabel": "No invitations found",
		"page.organizations.invitations.inviteToJoinLabel": "Invite to join",
		"page.organizations.invitations.acceptButton": "Accept",
		"page.organizations.invitations.declinceButton": "Decline",
		// ###############################################
		"page.organizations.create.title": { value: "Create a new {{arg}}", args: ["general.organization"] },
		"page.organizations.create.nameLabel": { value: "Name of your new {{arg}}", args: ["general.organization"] },
		"page.organizations.create.createButtonText": "Create",
		// ###############################################
		"page.organization.settings.pageName": "Settings",
		// ###############################################
		"page.organization.members.pageName": { key: "general.members", transform: "titlecase" },
		"page.organization.members.current.column.member": { key: "general.member", transform: "titlecase" },
		"page.organization.members.current.column.role": { key: "general.role", transform: "titlecase" },
		"page.organization.members.current.column.membersince": { value: "{{arg}} since", args: [{ key: "general.member", transform: "titlecase" }] },
		"page.organization.members.current.column.invitedBy": "Invited by",
		"page.organization.members.current.column.status": "Status",
		"page.organization.members.current.column.actions": "Actions",
		"page.organization.members.current.noPermission": "You do not have permission to view the members of this organization.",
		// -----------------------------------------------
		"page.organization.members.invites.title": "Invites",
		"page.organization.members.invites.invite": "Invite",
		"page.organization.members.invites.column.sent": "Sent",
		"page.organization.members.invites.column.email": { key: "general.email", transform: "titlecase" },
		"page.organization.members.invites.column.role": { key: "general.role", transform: "titlecase" },
		"page.organization.members.invites.column.status": "Status",
		"page.organization.members.invites.column.invitedBy": "Invited by",
		"page.organization.members.current.column.invitedBy.usedLink": { key: "general.inviteLink", transform: "titlecase" },
		"page.organization.members.invites.column.expires": "Expires",
		"page.organization.members.invites.column.actions": "Actions",
		"page.organization.members.invites.column.action.removeButtonText": "Remove",
		// -----------------------------------------------
		"page.organization.members.inviteLinks.title": { key: "general.inviteLinks", transform: "titlecase" },
		"page.organization.members.inviteLinks.create": { value: "Create new {{arg}}", args: ["general.inviteLink"] },
		"page.organization.members.inviteLinks.column.created": "Created",
		"page.organization.members.inviteLinks.column.link": "Link",
		"page.organization.members.inviteLinks.column.role": { key: "general.role", transform: "titlecase" },
		"page.organization.members.inviteLinks.column.usesLeft": "Uses left",
		"page.organization.members.inviteLinks.column.expires": "Expires",
		"page.organization.members.inviteLinks.column.createdBy": "Created by",
		"page.organization.members.inviteLinks.column.actions": "Actions",
		"page.organization.members.inviteLinks.column.action.removeButtonText": "Remove",
		// -----------------------------------------------
		"page.branding.pageName": "Branding",
		// ###############################################
		"page.user.account.pageName": "Account",
		"page.user.account.details": "Details",
		"page.user.account.details.name": "Name",
		"page.user.account.details.photo": "Photo",
		"page.user.account.login.title": "Login details",
		"page.user.account.login.email": "E-mail",
		"page.user.account.login.changeEmail": "Change e-mail",
		"page.user.account.login.password": "Password",
		"page.user.account.login.newPassword": "New password",
		"page.user.account.login.repeatPassword": "Repeat password",
		"page.user.account.login.providers.title": "Login methods",
		"page.user.account.login.providers.description": "You can log in with the following methods. If available, add more methods to make logging in easier.",
		"page.user.account.login.provider.add": "Add login method",
		"page.user.account.login.provider.email": "E-mail and password",
		"page.user.account.login.provider.google": "Google",
		"page.user.account.delete.title": "Delete account",
		"page.user.account.delete.description": "You can delete your account here. This action is irreversible.",
		"page.user.account.delete.confirm": "Delete account",
		// ###############################################
		"page.billing.pageName": "Billing",
		"page.billing.balance.title": "Balance",
		"page.billing.balance.description": "Your current balance",
		// -----------------------------------------------
		"page.billing.purchaseMore.title": { value: "Purchase more {{arg}}", args: ["general.credits"] },
		"page.billing.purchaseMore.description": "Purchase more credits",
		"page.billing.purchaseMore.tab.forMe": "For me",
		"page.billing.purchaseMore.tab.forOther": "For someone else",
		"page.billing.purchaseMore.purchaseButtonText.forMe": "Purchase",
		"page.billing.purchaseMore.purchaseButtonText.forOther": { value: "Purchase {{arg}}", args: ["general.giftcard"] },
		"page.billing.purchaseMore.purchaseIsGiftCheckboxLabel": "This purchase is a gift",
		// -----------------------------------------------
		"page.billing.giftcards.title": "Giftcards",
		"page.billing.giftcards.description": "Your giftcards",
		"page.billing.giftcards.column.code": "Code",
		"page.billing.giftcards.column.amount": "Amount",
		"page.billing.giftcards.column.boughtOn": "Bought on",
		"page.billing.giftcards.column.redeemedBy": "Redeemed by",
		"page.billing.giftcards.column.redeemedOn": "Redeemed on",
		"page.billing.giftcards.column.link": "Link",
		// -----------------------------------------------
		"page.billing.creditHistory.title": "Credit history",
		"page.billing.creditHistory.description": "Your credit history",
		"page.billing.creditHistory.column.amount": "Amount",
		"page.billing.creditHistory.column.savings": "Savings*",
		"page.billing.creditHistory.column.savings.notice": "*Savings are very rough estimates based on copywriter costs of €0.25 ~ €1 per word",
		"page.billing.creditHistory.column.user": "User",
		"page.billing.creditHistory.column.reason": "Reason",
		"page.billing.creditHistory.column.date": "Date",
		"page.billing.creditHistory.column.actions": "Actions",
		"page.billing.creditHistory.column.action.viewChat": "View",
		"page.billing.creditHistory.column.action.invoiceButtonText": "Invoice",
		"page.billing.creditHistory.column.action.invoiceDownloadTitle": "Download invoice",
		// ###############################################
		"page.giftcard.buy.title": { value: "Buy a {{arg}} {{arg}}", args: ["general.app", "general.giftcard"] },
		"page.giftcard.buy.buyButtonText": "Buy",
		// -----------------------------------------------
		"page.giftcard.redeem.title": "Gift Card",
		"page.giftcard.redeem.notFound": { value: "We can't find this {{arg}}, check whether you have the correct link", args: ["general.giftcard"] },
		"page.giftcard.redeem.notActivated": { value: "This {{arg}} is not activated yet. This may take a few minutes. The page will automatically refresh when your {{arg}} is ready!", args: ["general.giftcard", "general.giftcard"] },
		"page.giftcard.redeem.yourCard": { value: "Copy the link to share it with the receiver of your gift", args: ["general.giftcard"] },
		"page.giftcard.redeem.yourCardWarning": { value: "This is your own {{arg}}, are you sure you want to redeem it?", args: ["general.giftcard"] },
		"page.giftcard.redeem.logInWarning": { value: "You must be logged in to redeem a {{arg}}", args: ["general.giftcard"] },
		"page.giftcard.redeem.alreadyRedeemed": { value: "This {{arg}} has already been claimed", args: ["general.giftcard"] },
		"page.giftcard.redeem.alreadyRedeemedYourself": "Credits received",
		"page.giftcard.redeem.redeemButtonText": { value: "Use {{arg}}", args: ["general.giftcard"] },
		"page.giftcard.redeem.redeemYourself": "Use yourself",
		"page.giftcard.redeem.giftCardInfo": "With this card you can:",
		"page.giftcard.redeem.giftCardInfo1": "answer questions, or",
		"page.giftcard.redeem.giftCardInfo2": "generate texts, or",
		"page.giftcard.redeem.giftCardInfo3": "brainstorm",
		"page.giftcard.redeem.messagePlaceholder": "Write a message...",
		"page.giftcard.redeem.redeemInfo": "When you redeem the card, you directly receive all credits and your card will be deactivated",
		"page.giftcard.redeem.alreadyRedeemedInfo": "You have already redeemed this card",
		"page.giftcard.redeem.someoneAlreadyRedeemedInfo": "This card is unavailable because someone already redeemed it",


		// ###############################################
		"page.payment.success.title": "Payment succeeded!",
		"page.payment.success.description": { value: "It may take a minute for your purchase to be processed. If this has not happened after an hour, please contact a {{arg}} administrator. Your purchases can be found in the {{arg}} tab of the navigation bar.", args: ["general.app", "page.billing.pageName"] },
		// ###############################################
		"hostComponent.aisha.vmbo": "VMBO",
		"hostComponent.aisha.havo": "HAVO",
		"hostComponent.aisha.vwo": "VWO",
	},
	nl: {
		// ###############################################
		"general.app": "Gen8",
		"general.user": "gebruiker",
		"general.users": { value: "{{arg}}s", args: ["general.user"] },
		"general.credits": "credits",
		"general.creditsSign": `<i class="fa-solid fa-bolt"></i>`,
		"general.creditsFormatter": { value: `{{arg}} {{arg}}`, args: ['general.creditsSign', '@count']},
		"general.organization": "organisatie",
		"general.organizations": { value: "{{arg}}s", args: ["general.organization"] },
		"general.member": "lid",
		"general.members": "leden",
		"general.inviteLink": "uitnodigingslink",
		"general.inviteLinks": "uitnodigingslinks",
		"general.giftcard": "cadeaubon",
		"general.role": "rol",
		"general.email": "email",
		"general.public": "publiek",
		"general.private": "privé",
		"general.template": "sjabloon",
		"general.templates": "sjablonen",
		"general.form": "formulier",
		"general.forms": "formulieren",
		"general.chat": "gesprek",
		"general.copy": "Kopieer",
		"general.chats": "gesprekken",
		"general.variable": "definitie",
		"general.variables": "definities",
		"general.home": "start pagina",
		"general.bookmark": "bladwijzer",
		"general.publicUrl": "publieke URL",
		"general.loading": "Laden...",
		"general.underConstruction": "Onder constructie",
		"general.remove": "verwijderen",
		"general.add": "toevoegen",
		"general.edit": "bewerken",
		"general.update": "updaten",
		"general.deactivate": "deactiveren",
		"general.activate": "activeren",
		"general.delete": "verwijderen",
		"general.cancel": "annuleren",
		"general.save": "opslaan",
		"general.create": "maken",
		"general.search": "zoeken",
		// ###############################################
		"role.owner": "eigenaar",
		"role.manager": "beheerder",
		"role.creator": "maker",
		"role.user": "gebruiker",
		"role.guest": "gast",
		// ###############################################
		"permissions.public": "Iedereen",
		"permissions.organization": { key: "general.organization", transform: "capitalize" },
		"permissions.private": "Alleen jij",
		// ###############################################
		"toasts.success.addedToOrganization": { value: "Je bent toegevoegd aan de {{arg}}", args: ["general.organization"] },
		// -----------------------------------------------
		"toasts.error.internal": "Er is een fout opgetreden. Probeer het opnieuw.",
		"toasts.error.unknown": { key: "toasts.error.internal" },
		// -----------------------------------------------
		"toasts.error.notAuthenticated": "Je bent niet ingelogd. Log in om deze actie uit te voeren.",
		"toasts.error.notAdmin": "Deze actie is alleen beschikbaar voor Gen8-beheerders.",
		// -----------------------------------------------
		"toasts.error.noActiveOrganization": { value: "Er is geen actieve {{arg}}. Selecteer of sluit je aan bij een {{arg}} om deze actie uit te voeren.", args: ["general.organization", "general.organization"] },
		"toasts.error.alreadyMemberOfOrganization": { value: "Je bent al lid van deze {{arg}}.", args: ["general.organization"] },
		"toasts.error.invalidEmail": { value: "Voer een valide {{arg}} in.", args: ["general.email"] },
		"toasts.error.inviteExpired": { value: "Deze {{arg}} is verlopen.", args: ["general.inviteLink"] },
		"toasts.error.inviteOverused": { value: "Deze {{arg}} is te vaak gebruikt.", args: ["general.inviteLink"] },
		// -----------------------------------------------
		"toasts.error.promptNotFound": "Prompt voor dit gesprek kon niet worden opgehaald.",
		"toasts.error.formNotFound": { value: "{{arg}} kon niet worden opgehaald.", args: ["general.form"] },
		// -----------------------------------------------
		"toasts.error.insufficientFunds": { value: "Jij of jouw {{arg}} hebben niet genoeg {{arg}} om dit te doen.", args: ["general.organization", "general.credits"] },
		"toasts.error.invalidCreditAmount": "Kies een heel bedrag tussen de aangegeven grenzen.",
		// ###############################################
		"guard.error.notLoggedIn.description": "Log in om deze pagina te bekijken",
		"guard.error.notLoggedIn.fixButtonText": "Log in",
		// -----------------------------------------------
		"guard.error.noValidUserDoc.description": "Je bent ingelogd, maar er ontbreekt nog steeds informatie van je account. Probeer in en uit te loggen, en neem als het probleem aan blijft houden contact op met info&#64;gen8.app",
		"guard.error.noValidUserDoc.fixButtonText": "Log uit",
		// -----------------------------------------------
		"guard.error.noMembership.description": { value: "Je bent niet lid van een {{arg}}", args: ["general.organization"] },
		"guard.error.noMembership.fixButtonText": { value: "Maak een {{arg}}", args: ["general.organization"]},
		// -----------------------------------------------
		"guard.error.missingParams.description": "Het adres van de pagina die je probeerd te bezoeken is incompleet",
		"guard.error.missingParams.fixButtonText": "Ga terug",
		// -----------------------------------------------
		"guard.error.creditsTooLow.description": { value: "Je hebt niet genoeg {{arg}}", args: ["general.credits"] },
		"guard.error.creditsTooLow.fixButtonText": { value: "Koop {{arg}}", args: ["general.credits"]},
		// ###############################################
		"navbar.organization.switchTitle": { value: "Wissel van {{arg}}", args: ["general.organization"] },
		"navbar.user.noUser": "...",
		"navbar.user.noOrg": "...",
		"navbar.user.logout": "Uitloggen",
		"navbar.noChatsFound": "Geen chats gevonden...",
		"navbar.backToProd": "terug naar productie",
		// ###############################################
		"component.template.generateButton": "Sturen",
		"component.template.clearButton": "Wissen",
		"component.template.privateCheckboxLabel": { value: "Privé {{arg}}?", args: ["general.chat"] },
		"component.template.fileInputLabel": "Sleep, plak of kies plaatje(s)...",
		"component.template.addBookmarkButton": { value: "Maak {{arg}} voor jezelf", args: ["general.bookmark"] },
		"component.template.removeBookmarkButton": { value: "Verwijder {{arg}}", args: ["general.bookmark"] },
		"component.template.setHomeButton": { value: "Zet {{arg}} als jouw {{arg}} voor deze {{arg}}", args: ["general.template", "general.home", "general.organization"] },
		"component.template.unsetHomeButton": { value: "Verwijder {{arg}} als jouw {{arg}} voor deze {{arg}}", args: ["general.template", "general.home", "general.organization"] },
		// ###############################################
		"component.chat.selectChat": { value: "Selecteer {{arg}}", args: ["general.chat"] },
		"component.chat.assistantName": "Gen8",
		"component.chat.userName": { key: "@user" },
		"component.chat.chatTitle": { key: "general.chat", transform: "titlecase"},
		// ###############################################
		"component.publicToken.createButton": { value: "Maak {{arg}}", args: ["general.publicUrl"] },
		"component.publicToken.publicUrlLabel": { value: "🌎 {{arg}}", args: ["general.publicUrl"] },
		"component.publicToken.copyButtonTitle": { value: "Kopieer {{arg}} naar klembord", args: ["general.publicUrl"] },
		"component.publicToken.openButtonTitle": { value: "Open {{arg}} in een nieuw tabblad", args: ["general.publicUrl"] },
		"component.publicToken.deleteButtonTitle": { value: "Verwijder {{arg}}", args: ["general.publicUrl"] },
		"component.publicToken.deleteConfirmText": { value: "Weet je zeker dat je deze {{arg}} wilt verwijderen?", args: ["general.publicUrl"] },
		// ###############################################
		"component.navbar.orgInvite": "Je bent uitgenodigd voor:",
		"component.navbar.lowBalance": { value: "Goed geholpen door {{arg}}? Koop meer {{arg}} om door te blijven gaan", args: ["general.app", "general.credits"] },
		"component.navbar.lowBalanceButtonText": { value: "Koop {{arg}}", args: ["general.credits"] },
		// ###############################################
		"page.login.pageName": "Log in",
		"page.login.emailLabel": { key: "general.email", transform: "capitalize" },
		"page.login.passwordLabel": "Wachtwoord",
		"page.login.passwordReset.back": "Annuleren",
		"page.login.passwordReset.link": "Wachtwoord vergeten?",
		"page.login.passwordReset.button": "Stuur herstel link",
		"page.login.passwordReset.title": "Wachtwoord vergeten?",
		"page.login.loginButtonText": "Inloggen",
		"page.login.orLoginWithLabel": "Of log in met",
		// ###############################################
		"page.signUp.pageName": "Registreer",
		"page.signUp.nameLabel": "Naam",
		"page.signUp.emailLabel": { key: "general.email", transform: "capitalize" },
		"page.signUp.passwordLabel": "Wachtwoord",
		"page.signUp.confirmPasswordLabel": "Bevestig wachtwoord",
		"page.signUp.signUpButtonText": "Registreer",
		"page.signUp.orSignUpWithLabel": "Of registreer met",
		// ###############################################
		"page.generate.pageName": { key: "general.chats", transform: "titlecase"},
		"page.generate.sidebar.chats": { key: "general.chats", transform: "titlecase"},
		"page.generate.sidebar.templates": { key: "general.templates", transform: "titlecase"},
		"page.generate.sidebar.variables": { key: "general.variables", transform: "titlecase"},
		"page.generate.template.copyEmbedCodeText": "Kopieer embed code",
		"page.generate.template.noDescriptionText": "Geen beschrijving beschikbaar...",
		"page.generate.template.templateButtonText": { value: "{{arg}}pagina", args: ["general.template"] },
		"page.generate.template.editButtonText": { value: "Bewerk {{arg}}", args: ["general.template"] },
		"page.generate.template.pickOtherChatButtonText": { value: "Kies {{arg}}", args: ["general.chat"] },
		"page.generate.chat.searchFieldLabel": { value: "Zoek {{arg}}...", args: ["general.chat"] },
		"page.generate.chat.newChatLabel": { value: "Begin een nieuw {{arg}}...", args: ["general.chat"] },
		"page.generate.chat.currentLabel": { value: "Huidig {{arg}}", args: ["general.chat"] },
		"page.generate.chat.newChatForTemplate.clean": { value: "Begin een nieuw {{arg}} voor dit {{arg}}", args: ["general.chat", "general.template"] },
		"page.generate.chat.newChatForTemplate.withSameInputs": { value: "Begin een nieuw {{arg}} voor dit {{arg}} met dezelfde invoer", args: ["general.chat", "general.template"] },
		"page.generate.chat.changeName": "Verander naam",
		"page.generate.noTemplatesFound.title": { value: "Geen {{arg}} gevonden", args: ["general.templates"] },
		"page.generate.noTemplatesFound.description": { value: "Je kan een nieuwe {{arg}} maken of iemand in jouw organizatie vragen om dit voor je te doen.", args: ["general.template"] },
		"page.generate.noTemplatesFound.createButtonText": { value: "Maak een {{arg}}", args: ["general.template"] },
		// ###############################################
		"page.templates.pageName": { transform: "titlecase", key: "general.templates" },
		"page.templates.createButtonText": { value: "Maak {{arg}}", args: ["general.template"] },
		"page.templates.searchTemplateLabel": { value: "Zoek {{arg}}...", args: ["general.template"] },
		"page.templates.noTemplatesAvailable": { value: "Er zijn geen {{arg}}", args: ["general.templates"] },
		// ###############################################
		"page.template.edit|create.defaultFormName": { value: "Naamloos {{arg}}", args: ["general.form"] },
		"page.template.edit|create.createTitle": { value: "Maak {{arg}}", args: ["general.form"] },
		"page.template.edit|create.documentationLinkText": "Documentatie",
		"page.template.edit|create.nameLabel": "Naam",
		"page.template.edit|create.descriptionLabel": "Beschrijving",
		"page.template.edit|create.systemPromptLabel": "Systeemprompt",
		"page.template.edit|create.userPromptLabel": "Prompt",
		"page.template.edit|create.chatTypeLabel": "Transcriberen?",
		"page.template.edit|create.chatTypeText": "Bij een transcriberend gesprek wordt elk plaatje apart verwerkt. Meestal wil je dit niet!",
		"page.template.edit|create.permissions.title": "Permissies",
		"page.template.edit|create.permissions.description": { value: "Wie kan het {{arg}} gebruiken en waarvoor?", args: ["general.template"] },
		"page.template.edit|create.permissions.modifyLabel": { value: "Het {{arg}} aanpassen", args: ["general.template"] },
		"page.template.edit|create.permissions.useLabel": { value: "Het {{arg}} gebruiken", args: ["general.template"] },
		"page.template.edit|create.permissions.newChatLabel": { value: "Een {{arg}} starten met dit {{arg}}", args: ["general.chat", "general.template"] },
		"page.template.edit|create.replyOptions.title": "Chat opties",
		"page.template.edit|create.replyOptions.textLabel": "Tekst",
		"page.template.edit|create.replyOptions.promptLabel": "Subprompt",
		"page.template.edit|create.replyOptions.addButtonText": "Voeg toe",
		"page.template.edit|create.preview.previewTitle": { value: "Test {{arg}}", args: ["general.form"] },
		"page.template.edit|create.preview.createTemplate": { value: "Zodra je {{arg}} af is kan je hem hier testen...", args: ["general.form"] },
		"page.template.edit|create.preview.debuggingTitle": "Debugging prompt",
		"page.template.edit|create.preview.debuggingText": "Start een chat om de kale prompt te zien...",
		"page.template.edit|create.preview.outputTitle": "Output",
		// -----------------------------------------------
		"page.template.edit.pageName": { value: "Bewerk {{arg}}", args: ["general.template"] },
		"page.template.edit.createdByLabel": "Gemaakt door",
		"page.template.edit.goToFormButtonText": { value: "Ga naar dit {{arg}}", args: ["general.form"] },
		// -----------------------------------------------
		"page.template.create.pageName": { value: "Maak {{arg}}", args: ["general.template"] },
		"page.template.create.createButtonText": "Creëer",
		// ###############################################
		"page.variables.pageName": { key: "general.variables", transform: "titlecase" },
		"page.variables.createButtonText": { value: "Maak {{arg}}", args: ["general.variable"] },
		"page.variables.searchFieldLabel": { value: "Zoek {{arg}}...", args: ["general.variable"] },
		"page.variables.noVariablesAvailable": { value: "Er zijn geen {{arg}}", args: ["general.variables"] },
		// ###############################################
		"page.organizations.pageName": { key: "general.organizations", transform: "titlecase"},
		"page.organizations.noneFoundLabel": { value: "Je bent geen {{arg}} van een {{arg}}. Maak een nieuwe {{arg}} of vraag of iemand je wilt uitnodigen.", args: ["general.member", "general.organization", "general.organization"] },
		"page.organizations.createButtonText": "Creëer",
		"page.organizations.invitations.title": "Uitnodigingen",
		"page.organizations.invitations.noneFoundLabel": "Geen uitnodigingen gevonden",
		"page.organizations.invitations.inviteToJoinLabel": "Nodig uit om deel te nemen",
		"page.organizations.invitations.acceptButton": "Accepteer",
		"page.organizations.invitations.declinceButton": "Weiger",
		// ###############################################
		"page.organizations.create.title": { value: "Maak een nieuwe {{arg}}", args: ["general.organization"] },
		"page.organizations.create.nameLabel": { value: "Naam van de {{arg}}", args: ["general.organization"] },
		"page.organizations.create.createButtonText": "Maak",
		// ###############################################
		"page.organization.settings.pageName": "Instellingen",
		// ###############################################
		"page.organization.members.pageName": { key: "general.members", transform: "titlecase" },
		"page.organization.members.current.column.member": { key: "general.member", transform: "titlecase" },
		"page.organization.members.current.column.role": { key: "general.role", transform: "titlecase" },
		"page.organization.members.current.column.membersince": { value: "{{arg}} sinds", args: [{ key: "general.member", transform: "titlecase" }] },
		"page.organization.members.current.column.invitedBy": "Uitgenodigd door",
		"page.organization.members.current.column.invitedBy.usedLink": { key: "general.inviteLink", transform: "titlecase" },
		"page.organization.members.current.column.status": "Status",
		"page.organization.members.current.column.actions": "Acties",
		"page.organization.members.current.noPermission": "Je hebt geen toestemming om de leden van deze organizatie te zien.",
		// -----------------------------------------------
		"page.organization.members.invites.title": "Uitnodigingen",
		"page.organization.members.invites.invite": "Nodig uit",
		"page.organization.members.invites.column.sent": "Verzonden",
		"page.organization.members.invites.column.email": { key: "general.email", transform: "titlecase" },
		"page.organization.members.invites.column.role": { key: "general.role", transform: "titlecase" },
		"page.organization.members.invites.column.status": "Status",
		"page.organization.members.invites.column.invitedBy": "Uitgenodigd door",
		"page.organization.members.invites.column.expires": "Verloopt op",
		"page.organization.members.invites.column.actions": "Acties",
		"page.organization.members.invites.column.action.removeButtonText": "Verwijder",
		// -----------------------------------------------
		"page.organization.members.inviteLinks.title": { key: "general.inviteLinks", transform: "titlecase" },
		"page.organization.members.inviteLinks.create": { value: "Maak nieuwe {{arg}}", args: ["general.inviteLink"] },
		"page.organization.members.inviteLinks.column.created": "Gemaakt op",
		"page.organization.members.inviteLinks.column.link": "Link",
		"page.organization.members.inviteLinks.column.role": { key: "general.role", transform: "titlecase" },
		"page.organization.members.inviteLinks.column.usesLeft": "Aantal keren te gebruiken",
		"page.organization.members.inviteLinks.column.expires": "Verloopt op",
		"page.organization.members.inviteLinks.column.createdBy": "Gemaakt door",
		"page.organization.members.inviteLinks.column.actions": "Acties",
		"page.organization.members.inviteLinks.column.action.removeButtonText": "Verwijder",
		// -----------------------------------------------
		"page.branding.pageName": "Branding",
		// ###############################################
		"page.user.account.pageName": "Account",
		"page.user.account.details": "Details",
		"page.user.account.details.name": "Naam",
		"page.user.account.details.photo": "Profielfoto",
		"page.user.account.login.title": "Login gegevens",
		"page.user.account.login.email": "E-mail",
		"page.user.account.login.changeEmail": "Verander e-mail",
		"page.user.account.login.password": "Wachtwoord",
		"page.user.account.login.newPassword": "Nieuw wachtwoord",
		"page.user.account.login.repeatPassword": "Herhaal wachtwoord",
		"page.user.account.login.providers.title": "Login methodes",
		"page.user.account.login.providers.description": "Je kan inloggen met de volgende methodes. Als mogelijk, voeg meer methodes toe om het inloggen makkelijker te maken.",
		"page.user.account.login.provider.add": "Voeg login methode toe",
		"page.user.account.login.provider.email": "E-mail en wachtwoord",
		"page.user.account.login.provider.google": "Google",
		"page.user.account.delete.title": "Account verwijderen",
		"page.user.account.delete.description": "Je kan je account hier verwijderen. Deze actie is onomkeerbaar.",
		"page.user.account.delete.confirm": "Verwijder account",
		// ###############################################
		"page.billing.pageName": "Tegoed",
		"page.billing.balance.title": "Balans",
		"page.billing.balance.description": "Jouw huidige balans",
		// -----------------------------------------------
		"page.billing.purchaseMore.title": { value: "Koop meer {{arg}}", args: ["general.credits"] },
		"page.billing.purchaseMore.description": "Koop meer tegoed",
		"page.billing.purchaseMore.tab.forMe": "Voor mij",
		"page.billing.purchaseMore.tab.forOther": "Voor iemand anders",
		"page.billing.purchaseMore.purchaseButtonText.forMe": "Koop",
		"page.billing.purchaseMore.purchaseButtonText.forOther": { value: "Koop {{arg}}", args: ["general.giftcard"] },
		"page.billing.purchaseMore.purchaseIsGiftCheckboxLabel": "Dit is een cadeau",
		// -----------------------------------------------
		"page.billing.giftcards.title": "Cadeaubonnen",
		"page.billing.giftcards.description": "Jouw cadeaubonnen",
		"page.billing.giftcards.column.code": "Code",
		"page.billing.giftcards.column.amount": "Bedrag",
		"page.billing.giftcards.column.boughtOn": "Gekocht op",
		"page.billing.giftcards.column.redeemedBy": "Gebruikt door",
		"page.billing.giftcards.column.redeemedOn": "Gebruikt op",
		"page.billing.giftcards.column.link": "Link",
		// -----------------------------------------------
		"page.billing.creditHistory.title": "Gebuikshistorie",
		"page.billing.creditHistory.description": "Jouw gebruikshistorie",
		"page.billing.creditHistory.column.amount": "Tegoed",
		"page.billing.creditHistory.column.savings": "Besparing*",
		"page.billing.creditHistory.column.savings.notice": "*Besparingen zijn gebaseerd op een grove schatting van kosten voor een tesktschrijver á €0.25 ~ €1 per woord",
		"page.billing.creditHistory.column.user": "Gebruiker",
		"page.billing.creditHistory.column.reason": "Reden",
		"page.billing.creditHistory.column.date": "Datum",
		"page.billing.creditHistory.column.actions": "Acties",
		"page.billing.creditHistory.column.action.viewChat": "Bekijk",
		"page.billing.creditHistory.column.action.invoiceButtonText": "Factuur",
		"page.billing.creditHistory.column.action.invoiceDownloadTitle": "Download factuur",
		// ###############################################
		"page.giftcard.buy.title": { value: "Koop een {{arg}} {{arg}}", args: ["general.app", "general.giftcard"] },
		"page.giftcard.buy.buyButtonText": "Koop",
		// -----------------------------------------------
		"page.giftcard.redeem.title": "Cadeaubon",
		"page.giftcard.redeem.notFound": { value: "We kunnen deze {{arg}} niet vinden, check nog een keer of je de goede link hebt", args: ["general.giftcard"] },
		"page.giftcard.redeem.notActivated": { value: "Deze {{arg}} is nog niet geactiveerd. Dit kan even duren na een aankoop. De pagina ververst vanzelf als je {{arg}} klaar is!", args: ["general.giftcard", "general.giftcard"] },
		"page.giftcard.redeem.yourCard": { value: "Kopieer de link om die te delen met de ontvanger van je cadeau", args: ["general.giftcard"] },
		"page.giftcard.redeem.yourCardWarning": { value: "Dit is je eigen {{arg}}, weet je zeker dat je hem wilt gebruiken?", args: ["general.giftcard"] },
		"page.giftcard.redeem.logInWarning": { value: "Je moet ingelogd zijn om een {{arg}} te gebruiken", args: ["general.giftcard"] },
		"page.giftcard.redeem.alreadyRedeemed": "Deze bon is al ingeleverd",
		"page.giftcard.redeem.alreadyRedeemedYourself": "Credits ontvangen",
		"page.giftcard.redeem.redeemButtonText": "Cadeaubon inleveren",
		"page.giftcard.redeem.redeemYourself": "Zelf gebruiken",
		"page.giftcard.redeem.giftCardInfo": "Met deze bon kun je:",
		"page.giftcard.redeem.giftCardInfo1": "vragen laten beantwoorden, of",
		"page.giftcard.redeem.giftCardInfo2": "teksten laten schrijven, of",
		"page.giftcard.redeem.giftCardInfo3": "brainstormen",
		"page.giftcard.redeem.messagePlaceholder": "Schrijf hier een bericht...",
		"page.giftcard.redeem.alreadyRedeemedInfo": "Je hebt deze cadeaubon al ingeleverd",
		"page.giftcard.redeem.someoneAlreadyRedeemedInfo": "Deze bon is niet meer beschikbaar omdat iemand anders die al heeft ingeleverd",
		"page.giftcard.redeem.redeemInfo": "Bij het inleveren ontvang je direct alle credits en is de cadeaubon niet meer te gebruiken",




		// ###############################################
		"page.payment.success.title": "Betaling succesvol!",
		"page.payment.success.description": { value: "Het kan een minuut duren voordat uw aankoop is verwerkt. Als dit na een uur nog niet is gebeurd, neem dan contact op met een {{arg}}-beheerder. Uw aankopen zijn terug te vinden op het {{arg}}-tabblad van de navigatiebalk.", args: ["general.app", "page.billing.pageName"] },
		// ###############################################
		"hostComponent.aisha.vmbo": "VMBO",
		"hostComponent.aisha.havo": "HAVO",
		"hostComponent.aisha.vwo": "VWO",
	}
}
