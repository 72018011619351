import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { PageStateService } from './page-state.service';

export type ContextType = keyof Context;
export function isContextType(value: string): boolean {
	return Object.keys(initialContext).includes(value);
}

export type Context = {
	user?: string;
	userId?: string;
	organization?: string;
	organizationId?: string;
	template?: string;
	templateId?: string;
	chat?: string;
	chatId?: string;
};

const initialContext: Context = {
	user: undefined,
	userId: undefined,
	organization: undefined,
	organizationId: undefined,
	template: undefined,
	templateId: undefined,
	chat: undefined,
	chatId: undefined,
};

@Injectable({
  providedIn: 'root'
})
export class ContextService {

	context: BehaviorSubject<Context> = new BehaviorSubject<Context>(initialContext);

	setOrganization(name: string, id: string) {
		this.context.next({
			...this.context.value,
			organization: name,
			organizationId: id,
		});
	}

	unsetOrganization() {
		this.context.next({
			...this.context.value,
			organization: undefined,
			organizationId: undefined,
		});
	}

	setUser(name: string, id: string) {
		// console.log('set user context', name, id);
		this.context.next({
			...this.context.value,
			user: name,
			userId: id,
		});
	}

	unsetUser() {
		this.context.next({
			...this.context.value,
			user: undefined,
			userId: undefined,
		});
	}

	setTemplate(name: string, id: string) {
		// console.log('set template context', name, id);
		this.context.next({
			...this.context.value,
			template: name,
			templateId: id,
		});
	}

	unsetTemplate() {
		this.context.next({
			...this.context.value,
			template: undefined,
			templateId: undefined,
		});
	}

	setChat(name: string, id: string) {
		// console.log('set chat context', name, id);
		this.context.next({
			...this.context.value,
			chat: name,
			chatId: id,
		});
	}

	unsetChat() {
		this.context.next({
			...this.context.value,
			chat: undefined,
			chatId: undefined,
		});
	}

}
