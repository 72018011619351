import { Directive, ElementRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LocalizationKey } from '../../models/config/localization/localizationModels';
import { LocalizedHtmlBit } from '../../models/layout/html/localized-html-bit';
import { SpanHtmlBit } from '../../models/layout/html/span-html-bit';
import { ValueWrapper } from '../../utilities/data/dynamic/valueWrapper';
import { TooltipElement } from './tooltip-element';
import { TooltipService } from './tooltip.service';

@Directive({
  selector: '[tooltip],[tooltipRaw]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('tooltip') localizationKey?: LocalizationKey;
	@Input('tooltipRaw') tooltipRaw?: string;
	private _tooltipElement?: TooltipElement;
	private _valueWrapper?: ValueWrapper<string>;

  constructor(private el: ElementRef, private tooltipService: TooltipService) {}

  ngOnInit() {
    if (!this.localizationKey && !this.tooltipRaw) {
      return;
    }
    this.createTooltipElement();
  }

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

		if (changes['localizationKey'] || changes['tooltip']) {
			if (this.localizationKey) {
				// if we have a localization bit, update the key
				if (this._valueWrapper instanceof LocalizedHtmlBit) {
					this._valueWrapper.key = this.localizationKey;
				} else {
					this.createTooltipElement();
				}
			} else {
				this.removeTooltipElement();
			}
		}

		if (changes['tooltipRaw']) {
			if (this.tooltipRaw) {
				// if we have a value wrapper, update the value
				if (this._valueWrapper instanceof SpanHtmlBit) {
					this._valueWrapper.content = this.tooltipRaw;
				} else {
					this.createTooltipElement();
				}
			} else {
				this.removeTooltipElement();
			}
		}
	}

  ngOnDestroy() {
		this.removeTooltipElement();
  }

  private createTooltipElement() {

		this.removeTooltipElement();

		if (this.localizationKey) {
			this._valueWrapper = new LocalizedHtmlBit(this.localizationKey);
		} else if (this.tooltipRaw) {
			this._valueWrapper = new SpanHtmlBit(this.tooltipRaw);
		} else {
			return;
		}

		this._tooltipElement = this.tooltipService.createTooltipOnElement(this.el.nativeElement, this._valueWrapper);
  }

  private removeTooltipElement() {
    if (this._tooltipElement) {
			this._tooltipElement.destroy();
			if (this._valueWrapper instanceof LocalizedHtmlBit) {
				this._valueWrapper.destroy();
			}
			this._tooltipElement = undefined;
			this._valueWrapper = undefined;
    }
  }
}
