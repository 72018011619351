import { autoPlacement, computePosition, offset, shift } from "@floating-ui/dom";
import { HtmlBit } from "../../models/layout/html/base-html-bit";
import { Destroyable } from "../../utilities/data/dynamic/destroyable";
import { ValueWrapper } from "../../utilities/data/dynamic/valueWrapper";

export class TooltipElement implements Destroyable {

	private _hookedTo: HTMLElement;
	private _tooltipElement: HTMLElement;
	private _content: HtmlBit;
	private _unsubscribeFns: (() => void)[] = [];

	constructor(hookedTo: HTMLElement, tooltipElement: HTMLElement, content: HtmlBit, onDestroy: () => void) {
		this._hookedTo = hookedTo;
		this._tooltipElement = tooltipElement;
		this._content = content;

		hookedTo.addEventListener('mouseenter', this._onMouseEnter.bind(this));
		hookedTo.addEventListener('mouseleave', this._onMouseLeave.bind(this));
		hookedTo.addEventListener('focus', this._onFocus.bind(this));
		hookedTo.addEventListener('blur', this._onBlur.bind(this));

		this._updateContent();

		if (content instanceof ValueWrapper) {
			this._unsubscribeFns.push((content as ValueWrapper<any>).listen(this._updateContent.bind(this)));
		}
	}

	destroy(): void {
		this._hookedTo.removeEventListener('mouseenter', this._onMouseEnter.bind(this));
		this._hookedTo.removeEventListener('mouseleave', this._onMouseLeave.bind(this));
		this._hookedTo.removeEventListener('focus', this._onFocus.bind(this));
		this._hookedTo.removeEventListener('blur', this._onBlur.bind(this));

		this._unsubscribeFns.forEach(fn => fn());
		if (this._tooltipElement && this._tooltipElement.parentElement) {
			this._tooltipElement.remove();
		}
	}

	private _updateContent() {
		this._tooltipElement.innerHTML = this._content.toString();
	}

	private _onMouseEnter() {
		this._tooltipElement.style.visibility = 'visible';
		this._updateTooltipPosition();
	}

	private _onMouseLeave() {
		this._tooltipElement.style.visibility = 'hidden';
	}

	private _onFocus() {
		this._tooltipElement.style.visibility = 'visible';
		this._updateTooltipPosition();
	}

	private _onBlur() {
		this._tooltipElement.style.visibility = 'hidden';
	}

	private async _updateTooltipPosition() {
    if (this._tooltipElement) {
      const { x, y } = await computePosition(this._hookedTo, this._tooltipElement, {
        placement: 'top',
        middleware: [offset(8), shift(), autoPlacement()],
      });

			this._tooltipElement.style.left = `${x}px`;
			this._tooltipElement.style.top = `${y}px`;
    }
  }

}
