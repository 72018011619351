<ng-container *ngIf="name" [formGroup]="group!">

	@if (anySetup.type === 'prompt') {
		<input type="hidden" [formControlName]="name" value="false" />
	}

	<div
		class="form-element d-flex flex-column gap-1"
		*ngIf="show"
	>
		<!-- Textfield -->
		<ng-container *ngIf="anySetup.type === 'textfield'">
			<label class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">{{ anySetup.description }}</p>
			<input #inputElement type="text" [formControlName]="name" (change)="onChanged($event)" (input)="onInput($event)">

			<ng-container *ngIf="!disabled">
				<app-input-suggestion
					[showSuggestionsTitle]="first"
					*ngFor="let suggestion of anySetup.suggestions; let first = first"
					[suggestion]="suggestion"
					(suggestionSelected)="inputSuggestion(suggestion)"
				></app-input-suggestion>
			</ng-container>
		</ng-container>

		<!-- Textarea -->
		<ng-container *ngIf="anySetup.type === 'textarea'">
			<label class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">{{ anySetup.description }}</p>
			<textarea #inputElement appAutoSize [formControlName]="name" (change)="onChanged($event)" (input)="onInput($event)"></textarea>

			<ng-container *ngIf="!disabled">
				<app-input-suggestion
					[showSuggestionsTitle]="first"
					*ngFor="let suggestion of anySetup.suggestions; let first = first"
					[suggestion]="suggestion"
					(suggestionSelected)="inputSuggestion(suggestion)"
				></app-input-suggestion>
			</ng-container>
		</ng-container>

		<!-- Checkbox -->
		<ng-container *ngIf="anySetup.type === 'checkbox'">
			<p *ngIf="anySetup.description" class="formDescription">{{ anySetup.description }}</p>
			<label class="checkbox-label">
				<input #inputElement type="checkbox" class="form-check-input" [formControlName]="name" (change)="onChanged($event)">
				<span>{{ anySetup.label }}</span>
			</label>
		</ng-container>

		<!-- Pick -->
		<ng-container *ngIf="anySetup.type === 'pick'">
			<label class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">{{ anySetup.description }}</p>
			<input type="hidden" [formControlName]="name" (change)="onChanged($event)">
			<div *ngIf="!hasValue" class="d-flex flex-row flex-wrap gap-2">
					<button
						*ngFor="let option of anySetup.options; let i = index"
						[disabled]="disabled"
						class="btn btn-sm btn-secondary"
						(click)="pick(option.label)"
					>
						{{option.label}}
					</button>
			</div>
			<ng-container *ngIf="hasValue">
				<div class="btn-group" style="width: fit-content">
					<button (submit)="false" disabled="true" class="btn btn-sm btn-secondary w-100">{{controlValue}}</button>
					<button (submit)="false" [disabled]="disabled" class="btn btn-sm btn-secondary" (click)="pick(null)">
						X
					</button>
				</div>
			</ng-container>
		</ng-container>

		<!-- Radio -->
		<ng-container *ngIf="anySetup.type === 'radio'">
			<label class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">{{ anySetup.description }}</p>
			<ng-container *ngFor="let option of anySetup.options">
				<label class="radio-label">
					<input #inputElement type="radio" class="form-check-input" [value]="option.label" [formControlName]="name" (change)="onChanged($event)">
					<span>{{ option.label }}</span>
				</label>
			</ng-container>
		</ng-container>

		<!-- Dropdown -->
		<ng-container *ngIf="anySetup.type === 'dropdown'">
			<label class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">{{ anySetup.description }}</p>
			<select [formControlName]="name" class="form-select" (change)="onChanged($event)" [disabled]="disabled">
				<option *ngFor="let option of anySetup.options"
								[value]="option.label">{{ option.label }}</option>
			</select>
		</ng-container>
	</div>
</ng-container>
