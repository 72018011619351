import { Injectable, TemplateRef } from '@angular/core';

export enum LayoutLocation {
	TOP = 'top',
	SIDE = 'side',
	MAIN = 'main'
}

export type PageContent = {
	template: TemplateRef<any>;
	id: string;
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
	private contentId = 0;

	public readonly content: { [key in LayoutLocation]: PageContent[] } = {
		[LayoutLocation.TOP]: [],
		[LayoutLocation.SIDE]: [],
		[LayoutLocation.MAIN]: []
	}

  constructor() { }

	public addContent(content: TemplateRef<any>, layoutLocation: LayoutLocation): () => void {
		const id = (this.contentId++).toString();
		this.content[layoutLocation].push({ template: content, id });
		//console.log(`Added content to ${location} with ID ${id}`, this.content)
		return () => this._removeContent(id, layoutLocation);
	}

	private _removeContent(id: string, location: LayoutLocation): void {
		const index = this.content[location].findIndex(c => c.id === id);
		if (index !== -1) {
			this.content[location].splice(index, 1);
			//console.log(`Removed content from ${location} with ID ${id}`, this.content)
			return;
		}

		console.warn(`Failed to remove page content: id ${id} not found.`);
	}
}
