import { Injectable } from '@angular/core';
import { defaultLocalization } from 'src/config/default-localization';
import { AppLocalization, isLocalizationContextKey, LanguageCode, LocalizationArg, LocalizationContext, LocalizationContextKey, LocalizationKey, LocalizationStaticKey, LocalizationTransformType, LocalizationWithArgs, LocalizationWithTransform } from '../../models/config/localization/localizationModels';
import { ContextService } from '../../services/page/context.service';
import { Listenable } from '../../utilities/data/dynamic/listenables';
import { deepClone, deepMerge } from '../../utilities/objectUtilities';
import { HostConfigService } from '../host-config/host-config.service';
import { UserService } from 'src/app/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService extends Listenable<void> {

	static _localizationService: LocalizationService;
	static get instance(): LocalizationService {
		return this._localizationService;
	}

	private hostLocalization: AppLocalization = deepMerge(defaultLocalization, this.configService.getHostLocalizationModifications());
	private localization: AppLocalization = this.hostLocalization;
	private activeLanguage: LanguageCode = "nl";

	constructor(
		private configService: HostConfigService,
		private contextService: ContextService,
		private userService: UserService) {
		super();

		LocalizationService._localizationService = this;

		// get session storage language
		const sessionLanguage = sessionStorage.getItem('language');
		if (sessionLanguage) {
			this.activeLanguage = sessionLanguage as LanguageCode;
		}

		configService.onHostConfigChange.subscribe(() => {
			this.reloadLocalization();
			this.notifyUpdate();
		});

		contextService.context.subscribe(() => {
			this.notifyUpdate();
		});

		userService.user.subscribe((user) => {
			if (user) {
				const oldLanguage = this.activeLanguage;
				this.language = user.data?.language ?? oldLanguage;
				if (oldLanguage !== this.activeLanguage) {
					this.notifyUpdate();
				}
			}
		});

		this.reloadLocalization();
	}

	private reloadLocalization() {
		this.localization = deepMerge(deepClone(this.hostLocalization), this.configService.getActiveOrganizationLocalizationModifications());
	}

	set language(language: LanguageCode) {
		this.activeLanguage = language;
		this.notifyUpdate();

		// store in session storage
		sessionStorage.setItem('language', language);

		// save language to user data
		this.userService.patchUser([{
			path: 'data.language',
			value: language,
		}]);
	}

	get language() {
		return this.activeLanguage;
	}

	keyExists(key: LocalizationKey): boolean {
		return isLocalizationContextKey(key) || this.localization[this.activeLanguage].hasOwnProperty(key);
	}

	/**
	 * Get a localized string
	 * @param key
	 * @param context the context to use for resolving context keys. When a string is provided, it is used as the '@object' context key
	 * @returns
	 */
	get(key: LocalizationKey, context?: LocalizationContext | string, transform?: LocalizationTransformType): string {
		let result: string;
		if (context) {
			if (typeof context === 'string') {
				result = this._get(key, { ...this.contextService.context.value, object: context, count: context });
			} else {
				result = this._get(key, { ...this.contextService.context.value, ...context });
			}
		} else {
			result = this._get(key, this.contextService.context.value);
		}

		return this.transform(result, transform);
	}

	private _get(key: LocalizationKey, context: LocalizationContext): string {
		if (!key) {
			return 'LOCALIZATION_ERROR';
		}

		// is key a context key?
		if (key.startsWith('@')) {
			return this.resolveContextKey(key as LocalizationContextKey, context);
		}

		// otherwise the key is a static key
		const value = this.localization[this.activeLanguage][key as LocalizationStaticKey];

		// if value is string, return it
		if (typeof value === 'string') {
			return value as string;
		}

		// if value is object, then it needs resolving
		if (typeof value === 'object') {

			if (value.hasOwnProperty('value')) {
				return this.resolveLocalizationWithArgs(value as LocalizationWithArgs, context);

			} else if (value.hasOwnProperty('key')) {
				return this.resolveLocalizationWithTransform(value as LocalizationWithTransform, context);

			} else {
				console.error(`Localization key value is object but missing 'value' or 'key' property: ${key}:`, value);
				return 'LOCALIZATION_ERROR';
			}
		}

		console.error(`Localization key value is of invalid type: ${key}:`, value);
		return 'LOCALIZATION_ERROR';
	}

	private resolveContextKey(key: LocalizationContextKey, context: LocalizationContext): string {
		const contextKey = key.slice(1) as keyof LocalizationContext;
		return (context[contextKey] ?? 'LOCALIZATION_ERROR') as string;
	}

	private resolveLocalizationWithArgs(obj: LocalizationWithArgs, context: LocalizationContext): string {
		let argIndex = 0;
		return obj.value.replaceAll("{{arg}}", () => {
			if (obj.args.length <= argIndex) {
				console.error(`Localization key value has more arguments than provided`, obj);
				return 'LOCALIZATION_ERROR';
			}
			return this.resolveLocalizationArg(obj.args[argIndex++], context);
		});
	}

	private resolveLocalizationArg(arg: LocalizationArg, context: LocalizationContext): string {
		// if arg is a string, it is a key
		if (typeof arg === 'string') {
			return this.get(arg, context);
		}
		// if arg is an object, it is a localization with transform
		return this.resolveLocalizationWithTransform(arg, context);
	}

	private resolveLocalizationWithTransform(obj: LocalizationWithTransform, context: LocalizationContext): string {
		const value = this._get(obj.key, context);
		return this.transform(value, obj.transform);
	}

	transform(value: string, transform?: LocalizationTransformType) {
		if (!transform) {
			return value;
		}
		switch (transform) {
			case 'capitalize':
				return value.charAt(0).toUpperCase() + value.slice(1);
			case 'uppercase':
				return value.toUpperCase();
			case 'lowercase':
				return value.toLowerCase();
			case 'titlecase':
				return value.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
			case 'sentencecase':
				return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
			default:
				console.error(`Invalid transform type: ${transform}`);
				return value;
		}
	}
}
