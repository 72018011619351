import { MonoTypeOperatorFunction, throttleTime } from "rxjs";
import { Destroyable } from "../../../utilities/data/dynamic/destroyable";
import { Listenable } from "../../../utilities/data/dynamic/listenables";
import { Listener } from "../../../utilities/data/dynamic/listener";
import { ValueWrapper } from "../../../utilities/data/dynamic/valueWrapper";
import { Dynamic } from "../../../utilities/data/dynamic/valueWrappers";
import BootstrapClass from "../bootstrap/bootstrapTypes";
import { FontAwesomeClass } from "../fontawesome/fontawesomeTypes";

export type HtmlBit = Dynamic<string> | Dynamic<BaseHtmlBit>;

type HtmlBitClass = FontAwesomeClass | BootstrapClass;

export abstract class BaseHtmlBit extends ValueWrapper<string> implements Destroyable {

	// protected override get updatePiper(): MonoTypeOperatorFunction<any> {
	// 	return throttleTime(1, undefined, { trailing: true, leading: false});
	// }

	private _classes?: Set<HtmlBitClass>;
	private _uid = Math.random().toString(36).substring(14);
	private _listener: Listener<string> = new Listener();

	constructor() {
		super('');
		this._classes = new Set();
		this._classes.add(this._uid as any);
	}

	get uid(): string {
		return this._uid;
	}

	abstract get html(): string;

	override toString(): string {
		return this.html;
	}

	addClass(...bsClass: HtmlBitClass[]): this {
		if (!this._classes) {
			this._classes = new Set();
		}
		bsClass.forEach(c => this._classes!.add(c));
		this.updateValue();
		return this;
	}

	removeClass(...bsClass: HtmlBitClass[]): this {
		if (this._classes) {
			bsClass.forEach(c => this._classes!.delete(c));
		}
		this.updateValue();
		return this;
	}

	listenTo(other: Dynamic<any> | Dynamic<any>[]): this {
		if (Array.isArray(other)) {
			other.forEach(o => this.listenTo(o));
		} else if (other instanceof Listenable) {
			this._listener.listenTo(other, this.updateValue.bind(this));
		}
		return this;
	}

	stopListeningTo(other: Dynamic<any> | Dynamic<any>[]): this {
		if (Array.isArray(other)) {
			other.forEach(o => this.stopListeningTo(o));
		} else if (other instanceof Listenable) {
			this._listener.stopListeningTo(other);
		}
		return this;
	}

	protected updateValue(): void {
		this.value = this.html;
	}

	destroy(): void {
		this._listener.destroy();
	}

	get classes(): string {
		return this._classes ? Array.from(this._classes).join(' ') : '';
	}
}

