import { Component } from '@angular/core';
import { VerticalLayoutGroupComponent } from './vertical-layout-group.component';

@Component({
  selector: 'app-blockquote-layout-group',
  template: `<ng-template #container></ng-template>`,
	styles: [`
		:host {
			display: contents;
		}
	`]
})
export class BlockquoteLayoutGroupComponent extends VerticalLayoutGroupComponent {

  override setupRuleBasedStyles(calculatedRules: any): void {
		super.setupRuleBasedStyles(calculatedRules);
		this.setClass('border-start', true);
		this.setClass('ps-4', true);
	}
}
