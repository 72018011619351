import { Component, Input } from '@angular/core';
import { IndentLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { LayoutElementRule } from '../../../models/layout/layoutBase';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { VerticalLayoutGroupComponent } from './vertical-layout-group.component';

@Component({
  selector: 'app-indent-layout-group',
  template: `<ng-template #container></ng-template>`,
  styles: []
})
export class IndentLayoutGroupComponent extends VerticalLayoutGroupComponent {

	@Input() amount: IndentLayoutGroup['amount'] = 1;

  override setupRuleBasedStyles(calculatedRules: Set<LayoutElementRule>): void {
		super.setupRuleBasedStyles(calculatedRules);
		this.setStyle('padding-left', this.amount * 4 + 'rem');
	}

	override setupValueForKey(key: keyof this): void {
		super.setupValueForKey(key);
		if (key === 'amount') {
			const unwrappedAmount = unwrap(this.amount);
			this.setStyle('padding-left', unwrappedAmount * 4 + 'rem');
		}
	}
}
