import { Listenable } from "./listenables";

export class ValueWrapper<T> extends Listenable<T> {

	constructor(protected _value: T, passSetter?: (setter: (value: T) => void) => void) {
		super();
		if (passSetter) {
			passSetter(this._set.bind(this));
		}
	}

	saveWrapper(saver: (wrapper: ValueWrapper<T>) => void): this {
		saver(this);
		return this;
	}

	get value(): T {
		return this._value;
	}

	protected set value(value: T) {
		this._set(value);
	}

	private _set(value: T): void {
		this._value = value;
		this.notifyUpdate(value);
	}

	override toString(): string {
		return this._value + '';
	}
}

export class ValueLink<T> extends ValueWrapper<T> {

	constructor(value: T, onUpdate?: (value: T) => void) {
		super(value);
		this.listen(onUpdate as (value: T) => void);
	}

	override get value(): T {
		return super.value;
	}

	override set value(value: T) {
		super.value = value;
	}
}

export class ThrottledValueWrapper<T> extends ValueWrapper<T> {
	private timerSet = false;

	override notifyUpdate(value: T): void {
		if (this.timerSet) return;
		this.timerSet = true;
		setTimeout(() => {
			this.timerSet = false;
			super.notifyUpdate(value);
		}, 0);
	}
}
