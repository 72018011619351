import { ValueWrapper } from "./valueWrapper";

export type Dynamic<T> = T | ValueWrapper<T>;
export type DynamicString = Dynamic<string>;
export type DynamicNumber = Dynamic<number>;
export type DynamicBoolean = Dynamic<boolean>;
export type DynamicBigInt = Dynamic<bigint>;
export type DynamicSymbol = Dynamic<symbol>;

export type DynamicDate = Dynamic<Date>;
export type DynamicArray<T> = Dynamic<Dynamic<T>[]>;

export type Unwrapped<T> = T extends ValueWrapper<infer U>
	? U
	: T extends Dynamic<infer U>
		? U
		: T extends DynamicArray<infer U>
			? U[]
			: T;

export type UnwrappedElement<T,I extends number> = T extends unknown[] ? Unwrapped<T[I]> : never

export const unwrap = <T>(value: T): Unwrapped<T> => {
	if (value === null || value === undefined) {
		return value as Unwrapped<T>;
	} else if (value instanceof ValueWrapper) {
		return value.value;
	} else if (Array.isArray(value)) {
		return value.map(unwrap) as Unwrapped<T>;
	} else {
		return value as Unwrapped<T>;
	}
};
