import { MembershipsService } from '../../../../organization/services/memberships.service';
import { OrganizationActionPermissionPath5 } from '../../models/database/orgPermissionModels';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { OrganizationMembership5 } from '../../models/database/organizationModels';
import { memberHasPermissionFor } from '../../utilities/database/permissionUtilities';

@Directive({
  selector: '[ifHasPermission]'
})
export class IfHasPermissionDirective {
	@Input('ifHasPermission') set ifHasPermission(value: {
		for: OrganizationActionPermissionPath5 | OrganizationActionPermissionPath5[],
		forOperator?: 'and' | 'or' ,
		forOrganizationId?: string,
		or?: boolean,
		andIf?: boolean,
		else?: TemplateRef<any>
	}) {
		this.forPermissionPath = value.for;
		this.forOperator = value.forOperator ?? 'or';
		this.forOrganizationId = value.forOrganizationId;
		this.or = value.or ?? false;
		this.andIf = value.andIf ?? true;
		this.elseTemplate = value.else;
		this.updateView();
	}

	forPermissionPath: OrganizationActionPermissionPath5 | OrganizationActionPermissionPath5[] | undefined;
	forOperator: 'and' | 'or' | undefined;
	forOrganizationId: string | undefined;
	or: boolean | undefined;
	andIf: boolean | undefined;
	elseTemplate: TemplateRef<any> | undefined;

	selectedMembership: OrganizationMembership5 | undefined;
	selectedMembershipSubscription: Subscription | undefined;

	permissionAllowed: boolean = false;
  private hasIfView = false;
  private hasElseView = false;

	constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
		private activeOrgService: ActiveOrganizationService,
		private membershipsService: MembershipsService) {}

	ngOnInit() {
		this.listenToSelectedMembership();
		this.updateView();
	}

	ngOnChanges() {
		this.updateView();
	}

	ngOnDestroy() {
		if (this.selectedMembershipSubscription) {
			this.selectedMembershipSubscription.unsubscribe();
			this.selectedMembershipSubscription = undefined;
		}
	}

	listenToSelectedMembership() {
		if (this.selectedMembershipSubscription) {
			this.selectedMembershipSubscription.unsubscribe();
			this.selectedMembershipSubscription = undefined;
		}
		this.selectedMembershipSubscription = this.activeOrgService.activeMembership.subscribe((membership) => {
			this.selectedMembership = membership;
			this.updateView();
		});
	}

	updateView() {
		this.updatePermission();

		if (this.permissionAllowed) {
			if (this.hasElseView) {
				this.viewContainer.clear();
				this.hasElseView = false;
			}

			if (!this.hasIfView) {
				this.viewContainer.createEmbeddedView(this.templateRef);
				this.hasIfView = true;
			}
		} else {
			if (this.hasIfView) {
				this.viewContainer.clear();
				this.hasIfView = false;
			}

			if (this.elseTemplate && !this.hasElseView) {
				this.viewContainer.createEmbeddedView(this.elseTemplate);
				this.hasElseView = true;
			}
		}
	}

	updatePermission() {
		let membership = this.selectedMembership;
		if (this.forOrganizationId) {
			membership = this.membershipsService.memberships.value.find(m => m.organizationRef.id === this.forOrganizationId);
		}
		this.permissionAllowed = (memberHasPermissionFor(membership, this.forPermissionPath!) || (this.or ?? false)) && (this.andIf ?? true);
	}
}
