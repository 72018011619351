import { Directive, ElementRef, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { HostConfigOption, HostConfigTarget } from '../../models/config/host-config/hostConfigModels';
import { HostConfigService } from './host-config.service';
import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { OrganizationMembership5 } from '../../models/database/organizationModels';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[hostConfig]'
})
export class HostConfigDirective {
	@Input() hostConfig: HostConfigOption | undefined;
	@Input() configTarget: HostConfigTarget | undefined;
	@Input() organizationIdConfig: string | undefined;

	selectedMembership: OrganizationMembership5 | undefined;
	selectedMembershipSubscription: Subscription | undefined;

	optionValue: any = '';

	constructor(
    private elementRef: ElementRef<any>,
		private renderer: Renderer2,
		private hostConfigService: HostConfigService,
		private orgService: ActiveOrganizationService) {
		}

	ngOnInit() {
		this.listenToSelectedMembership();
		this.updateView();
	}

	ngOnChanges() {
		this.updateView();
	}

	ngOnDestroy() {
		if (this.selectedMembershipSubscription) {
			this.selectedMembershipSubscription.unsubscribe();
			this.selectedMembershipSubscription = undefined;
		}
	}

	listenToSelectedMembership() {
		if (this.selectedMembershipSubscription) {
			this.selectedMembershipSubscription.unsubscribe();
			this.selectedMembershipSubscription = undefined;
		}
		this. selectedMembershipSubscription = this.orgService.activeMembership.subscribe((membership) => {
			this.selectedMembership = membership;
			this.updateView();
		});
	}

	restorePreviousModification?: () => void = () => {};

	updateOptionValueAndTarget() {
		if (this.hostConfig) {
			this.optionValue = this.hostConfigService.get(this.hostConfig, this.organizationIdConfig);
			if (!this.configTarget) {
				this.configTarget = this.hostConfigService.getTarget(this.hostConfig!);
			}
		} else {
			console.error("Error getting option value for host config string value directive, no option provided");
		}
	}

	updateView() {
		this.updateOptionValueAndTarget();

		if (this.restorePreviousModification) {
			this.restorePreviousModification();
			this.restorePreviousModification = undefined;
		}

		// set the value of the string to the view
		if (this.configTarget === 'innerText') {
			this.updateInnerText();
		} else if (this.configTarget === 'class') {
			this.updateClass();
		} else if (this.configTarget === 'visibility') {
			this.updateRender();
		} else if (this.configTarget === 'src') {
			this.updateSrc();
		} else {
			console.error("Error updating view for host config directive, target not supported for this directive. Config target:", this.configTarget, "for option", this.hostConfig, "with value", this.optionValue);
		}
	}

	updateInnerText() {
		const previousValue = this.elementRef.nativeElement.innerText;
		this.elementRef.nativeElement.innerText = this.optionValue;
		this.restorePreviousModification = () => {
			this.elementRef.nativeElement.innerText = previousValue;
		};
	}

	updateClass() {
		const addedClasses: string[] = [];
		if (typeof this.optionValue === 'string') {
			const classes = this.optionValue.split(' ');
			classes.forEach((c) => {
				if (!this.elementRef.nativeElement.classList.contains(c)) {
					this.renderer.addClass(this.elementRef.nativeElement, c);
					addedClasses.push(c);
				}
			});
			// if array
		} else if (Array.isArray(this.optionValue)) {
			this.optionValue.forEach((c) => {
				if (!this.elementRef.nativeElement.classList.contains(c)) {
					this.renderer.addClass(this.elementRef.nativeElement, c);
					addedClasses.push(c);
				}
			});
		}
		this.restorePreviousModification = () => {
			addedClasses.forEach((c) => {
				this.renderer.removeClass(this.elementRef.nativeElement, c);
			});
		};
	}

	updateRender() {
		if (!this.optionValue) {
			const previousDisplay = this.elementRef.nativeElement.style.display;
			this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
			this.restorePreviousModification = () => {
				this.renderer.setStyle(this.elementRef.nativeElement, 'display', previousDisplay);
			};
		}
	}

	updateSrc() {
		const previousSrc = (this.elementRef.nativeElement as HTMLImageElement).src;
		(this.elementRef.nativeElement as HTMLImageElement).src = this.optionValue;
		this.restorePreviousModification = () => {
			(this.elementRef.nativeElement as HTMLImageElement).src = previousSrc;
		};
	}
}
