import { tokenCollectionsToIdPrefixMapping } from './publicTokenModels';
import { DocumentReference, GeoPoint, Timestamp } from "firebase/firestore";
import { OrganizationDocType5, organzationCollectionsToIdPrefixMapping } from "./organizationModels";
import { userCollectionsToIdPrefixMapping } from "./userModels";
import { inviteCollectionsToIdPrefixMapping } from './inviteModels';
import { Tag } from '../../utilities/typeUtilities';

/////////////////////////
// General Models
/////////////////////////

export type DatabaseObject = Tag<{}, "DatabaseObject">;

// cloud functions BaseModel
// export type FirestoreDocument = {};

// (un)conmment for Angular client
export type FirestoreDocument<T extends string = string> = DatabaseObject & { docRef?: DocumentReference, docId?: T };

export let idPrefixes: { [key: string]: string } = {
    ...userCollectionsToIdPrefixMapping,
    ...organzationCollectionsToIdPrefixMapping,
    ...tokenCollectionsToIdPrefixMapping,
		...inviteCollectionsToIdPrefixMapping,
};

export type GeneralInfo5 = {
    name: string | null;
    description: string | null;
    image: string | null;
}

export type MetaData5 = {
    timestamp: Timestamp | null;
    userRef: DocumentReference | null;
    organizationRef: DocumentReference | null;
    memberRef: DocumentReference | null;
    publicTokenRef: DocumentReference | null;
}

export type Contact5 = {
    name: string;
    email: string;
    phone: string;
    address: Address5;
}

export type Address5 = {
    addressLine: string[];
    postalCode: string;
    city: string;
    region: string;
    country: string;
    location: GeoPoint | null;
}

export type Reference5 = {
	ref?: DocumentReference;
	id: string;
}

export type DataDoc5 = FirestoreDocument & {
    doc: Doc5;
}

export type Doc5 = {
    name: string;
    type: DocType5;
    ownerRef: DocumentReference | null;
}

export type DocType5 = OrganizationDocType5;

export type Bank5 = {
    name: string;
    iban: string;
    bic: string;
}

export type Notes5 = {
  tags: string[];
  rating: number | null;
  notes: Note5[];
}

export type Note5 = {
  id: string;
  created: MetaData5;
  rating: number | null;
  text: string;
}
