import { deepClone } from './../../utilities/objectUtilities';
import { Injectable } from '@angular/core';
import { ActivatedRoute, GuardsCheckStart, NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ContextService } from './context.service';
import { Listenable } from '../../utilities/data/dynamic/listenables';

export type RouteOption =
	| 'login'
	| 'signup'
	| 'mvp1'
	| 'mvp1/template'
	| 'mvp1/template/chat'
	| 'templates'
	| 'templates/create'
	| 'template/edit'
	| 'organizations'
	| 'organizations/create'
	| 'organization'
	| 'organization/details'
	| 'organization/billing'
	| 'organization/members'
	| 'user'
	| 'user/details'
	| 'user/billing';

export type RouteParams = {
	organizationId?: string;
	templateId?: string;
	chatId?: string;
};

export type RouteQueries = {
	returnUrl?: string;
};

export type RouteFragment = string;

export type RouteSettings = {
	preserveFragment?: boolean; // default is false
	preserveQueries?: boolean | { [key in keyof RouteQueries]: boolean }; // default is true
	rememberCurrentUrlForReturn?: boolean; // default is false
	extras?: NavigationExtras;
}

export type NavigateSetup = {
	toRoute: RouteOption;
	fragment?: RouteFragment;
	params?: RouteParams;
	queries?: RouteQueries;
	settings?: RouteSettings;
};

export type Gen8Param = keyof Gen8Params;
export class Gen8Params {
	organizationId: string | undefined = undefined;
	userId: string | undefined = undefined;
	variableId: string | undefined = undefined;
	templateId: string | undefined = undefined;
	tokenId: string | undefined = undefined;
	chatId: string | undefined = undefined;
}
export const EmptyGen8Params = new Gen8Params();

export type ResolvedRoute = {
	commands: any[],
	extras: NavigationExtras
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends Listenable<void> {

	returnUrl: string | undefined;
	public currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentParams: BehaviorSubject<Gen8Params> = new BehaviorSubject<Gen8Params>(new Gen8Params());

	constructor(
		public readonly router: Router,
		private activatedRoute: ActivatedRoute,
		private contextService: ContextService) {
			super();

			this.activatedRoute.queryParams.subscribe((params: RouteQueries) => {
				this.returnUrl = params?.returnUrl ?? undefined;
			});

			router.events.subscribe((val) => {

				// if guard event, remember url
				if (val instanceof GuardsCheckStart) {
					this.currentUrl.next((val as GuardsCheckStart).url);
				}

				// if end event, remember params
				if (val.toString().includes('NavigationEnd')) {
					const aggregatedParams: Gen8Params = new Gen8Params();
					let route = activatedRoute.snapshot;
					while (route) {
						Object.assign(aggregatedParams, route.params);
						route = route.children[0];
					}
					this.currentParams.next(aggregatedParams);
					this.notifyUpdate();
				}
			});

			// if context changes, notify listeners so that they may update their routes
			contextService.context.subscribe(context => {
				this.notifyUpdate();
			});
		}

	// private _parseReturnStack(): string[] {
	// 	return this.returnStack
	// 		? JSON.parse(Buffer.from(this.returnStack, 'base64').toString("utf8")) as string[]
	// 		: [];
	// }

	// private _makeReturnStack(returnStack: string[]) {
	// 	return Buffer.from(JSON.stringify(returnStack)).toString('base64');
	// }

	// private _popReturnStack(): { returnTo: string, returnStack: string } {
	// 	const parsed = this._parseReturnStack();
	// 	const returnTo = parsed.pop();
	// 	const returnStack = this._makeReturnStack(parsed);
	// 	return { returnTo: returnTo ?? '/produce', returnStack };
	// }

	// private _pushReturnStack(): string {
	// 	const parsed = this._parseReturnStack();
	// 	const thisRoute = this.router.url.split('?')[0];
	// 	parsed.push(thisRoute);
	// 	return this._makeReturnStack(parsed);
	// }

	/**
	 * Navigates to the return URL. If no return URL is set, navigates to the default page
	 * @returns Promise that resolves to true if the navigation was successful
	 */
	return(): Promise<boolean> {
		// const { returnTo, returnStack } = this._popReturnStack();
		return this.router.navigateByUrl(this.returnUrl ?? '/produce', { replaceUrl: true });
	}

	/**
	 * Routes to a route option including the necessary parameters
	 * If the parameters are not provided, the current page state and route params will be used
	 */
	navigate(setup: NavigateSetup): Promise<boolean>
	navigate(route: RouteOption, params?: RouteParams, settings?: RouteSettings): Promise<boolean>
	navigate(route: RouteOption | NavigateSetup, params?: RouteParams, settings?: RouteSettings): Promise<boolean> {
		const { commands, extras } = typeof route === 'string'
			? this.toRoute(route, params, settings)
			: this.toRoute(route);
			console.log('navigating to', commands, extras);
		return this.router.navigate(commands, extras);
	}

	/**
	 * Converts a route option to a route including the necessary parameters
	 * If the parameters are not provided, the current page state and route params will be used
	 */
	toRoute(setup: NavigateSetup): ResolvedRoute
	toRoute(route: RouteOption, params?: RouteParams, settings?: RouteSettings): ResolvedRoute
	toRoute(route: RouteOption | NavigateSetup, params?: RouteParams, settings?: RouteSettings): ResolvedRoute {
		let setup: NavigateSetup = typeof route === 'string' ? { toRoute: route } : deepClone(route);
		setup.settings = { ...(settings ?? {}), ...(setup.settings ?? {}) };
		setup.params = { ...(params ?? {}), ...(setup.params ?? {}) };

		const context = this.contextService.context.value;

		if (!setup.params.organizationId) {
			setup.params.organizationId = context.organizationId;
		}

		if (!setup.params.templateId) {
			setup.params.templateId = context.templateId;
		}

		if (!setup.params.chatId) {
			setup.params.chatId = context.chatId;
		}

		if (setup.settings?.rememberCurrentUrlForReturn) {
			console.log('remembering return url', this.currentUrl.value);
		}

		const queries = setup.settings?.rememberCurrentUrlForReturn
			? { returnUrl: this.currentUrl.value }
			: {};

		const extras: NavigationExtras = {
			preserveFragment: settings?.preserveFragment ?? false,
			queryParamsHandling: (settings?.preserveQueries ?? true) ? 'merge' : undefined,
			queryParams: {...setup?.queries, ...queries},
			fragment: setup.fragment,
			...settings?.extras
		};

		switch (setup.toRoute) {
			case 'login':
				return {commands: ['/auth', 'login'], extras: extras };
			case 'signup':
				return {commands: ['/auth', 'signup'], extras: extras };
			case 'mvp1':
				return {commands: ['/produce'], extras: extras };
			case 'mvp1/template':
				return {commands: ['/produce', setup.params.templateId], extras: extras };
			case 'mvp1/template/chat':
				return {commands: ['/produce', setup.params.templateId, setup.params.chatId], extras: extras };
			case 'templates':
				return {commands: ['/templates'], extras: extras };
			case 'templates/create':
				return {commands: ['/templates', 'create'], extras: extras };
			case 'template/edit':
				return {commands: ['/template', setup.params.templateId, 'edit'], extras: extras };
			case 'organizations':
				return {commands: ['/organizations'], extras: extras };
			case 'organizations/create':
				return {commands: ['/organizations', 'create'], extras: extras };
			case 'organization':
				return {commands: ['/organization'], extras: extras };
			case 'organization/details':
				return {commands: ['/organization', setup.params.organizationId, 'details'], extras: extras };
			case 'organization/billing':
				return {commands: ['/organization', setup.params.organizationId, 'billing'], extras: extras };
			case 'organization/members':
				return {commands: ['/organization', setup.params.organizationId, 'members'], extras: extras };
			case 'user':
				return {commands: ['/user'], extras: extras };
			case 'user/details':
				return {commands: ['/user', 'details'], extras: extras };
			case 'user/billing':
				return {commands: ['/user', 'billing'], extras: extras };
			default:
				console.error('Invalid route option', setup.toRoute);
				return {commands: ['/'], extras: extras };
		}
	}
}
