import { Component, Input } from '@angular/core';
import { HorizontalLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { VerticalLayoutGroupComponent } from './vertical-layout-group.component';

@Component({
  selector: 'app-horizontal-layout-group',
  template: `<ng-template #container></ng-template>`,
	styles: []
})
export class HorizontalLayoutGroupComponent extends VerticalLayoutGroupComponent {

	@Input() align: HorizontalLayoutGroup['align'] = 'baseline';

	override ngOnInit(): void {
		super.ngOnInit();
		this.setClass('flex-row', true, `flex-column`);
		this.setClass('gap-2', true, `gap-0`);
		this.setClass('align-items-' + this.align, true);
	}

}
