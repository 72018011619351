
<div class="d-flex flex-column mb-3 gap-2">
	<div class="d-flex flex-row align-items-baseline gap-2 justify-content-between border-bottom border-dark">
		<h1 class="mb-0 d-flex flex-row flex-grow-1 align-items-baseline gap-3 pb-2">
			<img *ngIf="image" [src]="image" class="rounded-circle" style="width: 40px; height: 40px; transform: translateY(5px)">
			<i *ngIf="faIcon" class="fa-solid" [ngClass]="faIcon"></i>
			<span>{{ title }}</span>
			<span *ngIf="subtitle" class="fs-5 text-muted">{{ subtitle }}</span>
		</h1>
		<ng-content></ng-content>
	</div>
	<div *ngIf="description" class="fs-5">{{ description }}</div>
</div>
