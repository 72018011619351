

export type ErrorCallback = (error: any) => void;
export type ErrorAndReturnCallback<T> = (error: any) => T;
export const makeErrorCallbackWithReturn: <T>(errorCallBack: ErrorCallback | undefined, value: T) => ErrorAndReturnCallback<T> = (errorCallBack, value) => {
	return (error: any) => {
		if (errorCallBack) errorCallBack(error);
		return value;
	}
}
