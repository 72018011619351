import { LocalizationContext, LocalizationKey, LocalizationTransformType } from "../../models/config/localization/localizationModels";
import { Destroyable } from "../../utilities/data/dynamic/destroyable";
import { Listener } from '../../utilities/data/dynamic/listener';
import { ValueWrapper } from "../../utilities/data/dynamic/valueWrapper";
import { Dynamic } from "../../utilities/data/dynamic/valueWrappers";
import { unwrap } from '../../utilities/data/dynamic/valueWrappers';
import { LocalizationService } from "./localization.service";

export class LocalizationWrapper extends ValueWrapper<string> implements Destroyable {

	private _key: Dynamic<LocalizationKey>;
	private _context?: Dynamic<LocalizationContext | string>;
	private _transform?: Dynamic<LocalizationTransformType>;
	private _listener: Listener<void> = new Listener();

	constructor(
			key: Dynamic<LocalizationKey>,
			context?: Dynamic<LocalizationContext | string>,
			transform?: Dynamic<LocalizationTransformType>) {
		super(LocalizationWrapper._resolve(key, context, transform));

		this._key = key;
		this._context = context;
		this._transform = transform;

		this._listener.listenTo(key, this._onArgUpdate.bind(this));
		this._listener.listenTo(context, this._onArgUpdate.bind(this));
		this._listener.listenTo(transform, this._onArgUpdate.bind(this));
		this._listener.listenTo(LocalizationService.instance, this._onArgUpdate.bind(this));
	}

	set key(key: Dynamic<LocalizationKey>) {
		this._listener.stopListeningTo(this._key);
		this._key = key;
		this._listener.listenTo(key, this._onArgUpdate.bind(this));
		this._onArgUpdate();
	}

	set context(context: Dynamic<LocalizationContext | string> | undefined) {
		this._listener.stopListeningTo(this._context);
		this._context = context;
		this._listener.listenTo(context, this._onArgUpdate.bind(this));
		this._onArgUpdate();
	}

	set transform(transform: Dynamic<LocalizationTransformType> | undefined) {
		this._listener.stopListeningTo(this._transform);
		this._transform = transform;
		this._listener.listenTo(transform, this._onArgUpdate.bind(this));
		this._onArgUpdate();
	}

	private _onArgUpdate() {
		this.value = LocalizationWrapper._resolve(this._key, this._context, this._transform);
	}

	destroy() {
		this._listener.destroy();
	}

	private static _resolve(
		key: Dynamic<LocalizationKey>,
		context?: Dynamic<LocalizationContext | string>,
		transform?: Dynamic<LocalizationTransformType>): string {
			return LocalizationService.instance?.get(unwrap(key), unwrap(context), unwrap(transform)) ?? '';
	}

}
