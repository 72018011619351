
export enum OrganizationRole5 {
	OWNER = "owner",
	MANAGER = "manager",
	CREATOR = "creator",
	USER = "user",
	GUEST = "guest",
	PUBLIC = "public",

	CUSTOM = "custom",
}

export type R_Rights5 = {
	read: boolean;
}

export type RU_Rights5 = R_Rights5 & {
	update: boolean;
}

export type RUD_Rights5 = RU_Rights5 & {
	delete: boolean;
}

export type CRUD_Rights5 = RUD_Rights5 & {
	create: boolean;
}

export type OrganizationPermissions5 = {
	organization: {
		info: RUD_Rights5;
		variables: RUD_Rights5;
	}
	memberships: {
		members: RUD_Rights5;
		permissions: RU_Rights5;
		invites: CRUD_Rights5;
		inviteLinks: CRUD_Rights5;
		publicTokens: CRUD_Rights5;
	}
	administration : {
		creditHistory: R_Rights5;
		credits: R_Rights5;
	}
	mvp1: {
		accessTokens: CRUD_Rights5;
		templates: CRUD_Rights5;
		chats: CRUD_Rights5; // note: deleting your own chats is always allowed
	};
};

export type OrganizationActionPermissionPath5 =
	"organization.info.read" |
	"organization.info.update" |
	"organization.info.delete" |
	"organization.variables.read" |
	"organization.variables.update" |
	"organization.variables.delete" |
	"memberships.members.read" |
	"memberships.members.update" |
	"memberships.members.delete" |
	"memberships.permissions.read" |
	"memberships.permissions.update" |
	"memberships.invites.create" |
	"memberships.invites.read" |
	"memberships.invites.update" |
	"memberships.invites.delete" |
	"memberships.inviteLinks.create" |
	"memberships.inviteLinks.read" |
	"memberships.inviteLinks.update" |
	"memberships.inviteLinks.delete" |
	"memberships.publicTokens.create" |
	"memberships.publicTokens.read" |
	"memberships.publicTokens.update" |
	"memberships.publicTokens.delete" |
	"administration.creditHistory.read" |
	"administration.credits.read" |
	"mvp1.accessTokens.create" |
	"mvp1.accessTokens.read" |
	"mvp1.accessTokens.update" |
	"mvp1.accessTokens.delete" |
	"mvp1.templates.create" |
	"mvp1.templates.read" |
	"mvp1.templates.update" |
	"mvp1.templates.delete" |
	"mvp1.chats.create" |
	"mvp1.chats.read" |
	"mvp1.chats.update" |
	"mvp1.chats.delete";

export const ORGANIZATION_OWNER_PERMISSIONS: OrganizationPermissions5 = {
	organization: {
		info: { read: true, update: true, delete: true },
		variables: { read: true, update: true, delete: true },
	},
	memberships: {
		members: { read: true, update: true, delete: true },
		permissions: { read: true, update: true },
		invites: { create: true, read: true, update: true, delete: true },
		inviteLinks: { create: true, read: true, update: true, delete: true },
		publicTokens: { create: true, read: true, update: true, delete: true },
	},
	administration: {
		creditHistory: { read: true },
		credits: { read: true },
	},
	mvp1: {
		accessTokens: { create: true, read: true, update: true, delete: true },
		templates: { create: true, read: true, update: true, delete: true },
		chats: { create: true, read: true, update: true, delete: true },
	},
};

export const ORGANIZATION_MANAGER_PERMISSIONS: OrganizationPermissions5 = {
	...ORGANIZATION_OWNER_PERMISSIONS,
	organization: {
		...ORGANIZATION_OWNER_PERMISSIONS.organization,
		info: { read: true, update: true, delete: false },
	},
};

export const ORGANIZATION_CREATOR_PERMISSIONS: OrganizationPermissions5 = {
	organization: {
		...ORGANIZATION_OWNER_PERMISSIONS.organization,
		info: { read: true, update: false, delete: false },
	},
	memberships: {
		members: { read: true, update: false, delete: false },
		permissions: { read: false, update: false },
		invites: { create: false, read: false, update: false, delete: false },
		inviteLinks: { create: false, read: false, update: false, delete: false },
		publicTokens: { create: true, read: true, update: true, delete: true },
	},
	administration: {
		creditHistory: { read: false },
		credits: { read: true },
	},
	mvp1: {
		accessTokens: { create: true, read: true, update: true, delete: true },
		templates: { create: true, read: true, update: true, delete: true },
		chats: { create: true, read: true, update: true, delete: false },
	},
};

export const ORGANIZATION_USER_PERMISSIONS: OrganizationPermissions5 = {
	organization: {
		info: { read: true, update: false, delete: false },
		variables: { read: true, update: true, delete: false },
	},
	memberships: {
		members: { read: true, update: false, delete: false },
		permissions: { read: false, update: false },
		invites: { create: false, read: false, update: false, delete: false },
		inviteLinks: { create: false, read: false, update: false, delete: false },
		publicTokens: { create: false, read: true, update: false, delete: false },
	},
	administration: {
		creditHistory: { read: false },
		credits: { read: true },
	},
	mvp1: {
		accessTokens: { create: false, read: true, update: false, delete: false },
		templates: { create: false, read: false, update: false, delete: false },
		chats: { create: true, read: true, update: true, delete: false },
	},
};

export const ORGANIZATION_GUEST_PERMISSIONS: OrganizationPermissions5 = {
	organization: {
		info: { read: false, update: false, delete: false },
		variables: { read: false, update: false, delete: false },
	},
	memberships: {
		members: { read: false, update: false, delete: false },
		permissions: { read: false, update: false },
		invites: { create: false, read: false, update: false, delete: false },
		inviteLinks: { create: false, read: false, update: false, delete: false },
		publicTokens: { create: false, read: false, update: false, delete: false },
	},
	administration: {
		creditHistory: { read: false },
		credits: { read: false },
	},
	mvp1: {
		accessTokens: { create: false, read: false, update: false, delete: false },
		templates: { create: false, read: false, update: false, delete: false },
		chats: { create: true, read: false, update: false, delete: false }, // a guest can create chats, but they will be private and only they can see, edit and delete them
	},
};

export const ORGANIZATION_PUBLIC_PERMISSIONS: OrganizationPermissions5 = {
	...ORGANIZATION_GUEST_PERMISSIONS,
	mvp1: {
		...ORGANIZATION_GUEST_PERMISSIONS.mvp1,
		chats: { create: false, read: false, update: false, delete: false },
	},
};

// Below is a utility function that is not used in the codebase. We may use it in the future.

// export const hasMorePermissionsForAFeature = (a: OrganizationRights5, b: OrganizationRights5): boolean => {
// 	// traverse all boolean values and check if a has more permissions than b
// 	const categoryKeys = Object.keys(a) as (keyof OrganizationRights5)[];
// 	for (const catKey of categoryKeys) {
// 		const aCat = a[catKey] ?? {};
// 		const bCat = b[catKey] ?? {};
// 		const aFeatures = Object.keys(aCat);
// 		for (const feature of aFeatures) {
// 			const aFeat = (aCat as any)[feature] ?? {};
// 			const bFeat = (bCat as any)[feature] ?? {};
// 			const permissions = Object.keys(aFeat);
// 			for (const perm of permissions) {
// 				const aPerm = aFeat[perm] ?? false;
// 				const bPerm = bFeat[perm] ?? false;
// 				if (aPerm && !bPerm) {
// 					return true;
// 				}
// 			}
// 		}
// 	}

// 	return false;
// }
