import { Component, Input, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-nav-list-divider',
  templateUrl: './nav-list-divider.component.html',
  styleUrls: ['./nav-list-divider.component.css']
})
export class NavListDividerComponent {

	@Input() label: string = '';
	@Input() isTop: boolean = false;
	@Input() order?: number;

  constructor() { }

  // Bind the 'order' style property to the host element
  @HostBinding('style.order') get styleOrder() {
    return this.order;
  }

}
