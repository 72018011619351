import { Component } from '@angular/core';
import { BrandingService } from './core/shared/services/page/branding.service';
import { UserService } from './user/services/user.service';
import { DomObserverService } from './core/shared/services/html/dom-observer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(private bService: BrandingService, protected userService: UserService, protected observer: DomObserverService) {
	}
}
