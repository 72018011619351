const DATA_KEY = Symbol("metadata");

export type Data<T> = {
	[key in keyof T]: T[key];
};

export function CustomData<T>(data: Data<T>) {
	return function (constructor: Function) {
		// Attach the extra data to the class constructor
		Reflect.defineProperty(constructor.prototype, DATA_KEY, {
				value: data,
				writable: false,
		});
	};
}

export function getCustomData<T>(constructor: Function): Data<T> {
	return Reflect.get(constructor.prototype, DATA_KEY);
}
