import { Component, Input } from '@angular/core';
import { TextInputElement } from '../../../models/layout/inputElements';
import { BaseInputElementComponent } from './base-input-element.component';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';

@Component({
  selector: 'app-text-input-element',
  template: `
	<app-normal-input-element-container [setup]="setup" [for]="uniqueId">
		<div *ngIf="prefix | dynamic" [innerHTML]="prefix | dynamic"></div>
		<input
			[id]="uniqueId"
			class="form-control"
			type="text"
			[disabled]="disabled | dynamic"
			[autocomplete]="autocomplete | dynamic"
			[placeholder]="placeholder | dynamic"
			[value]="value | dynamic"
			(change)="onValueChange($event)"
			(input)="onInput($event)"
		/>
		<div *ngIf="suffix | dynamic" [innerHTML]="suffix | dynamic"></div>
	</app-normal-input-element-container>
	`,
  styleUrls: ['./input-elements.styles.scss']
})
export class TextInputElementComponent extends BaseInputElementComponent<string> {

	@Input() prefix: TextInputElement['prefix'];
	@Input() suffix: TextInputElement['suffix'];
	@Input() autocomplete: TextInputElement['autocomplete'] = 'off';
	@Input() placeholder: TextInputElement['placeholder'];

	protected override onValueChangeEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
	protected override onInputEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
}
