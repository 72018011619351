import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Listener } from '../../../utilities/data/dynamic/listener';
import { ValueWrapper } from '../../../utilities/data/dynamic/valueWrapper';
import { DestroyableComponent } from '../destroyable/destroyable.component';

@Component({
	template: ''
})
export abstract class DynamicValuesComponent extends DestroyableComponent implements OnInit, OnChanges {

	private dynamicValuesListener: Listener<any> = new Listener();
	protected updatedKeyTimes: Map<string, number> = new Map();

	ngOnInit(): void {
		this._listenToAllDynamicValues();
		this._setupAllValues();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const keys = Object.keys(changes);
		keys.forEach(key => {
			const typedKey = key as keyof this;
			const oldValue = changes[key].previousValue;
			const newValue = changes[key].currentValue;
			this._stopListeningToDynamicValue(oldValue);
			this.setupValueForKey(typedKey);
			this._rememberJustUpdated(key);
			this._listenToDynamicValueOfKey(key, newValue);
		});
	}

	private _setupAllValues() {
		this.setupAllValues();
	}

	private _listenToAllDynamicValues() {
		this._stopListeningToAllDynamicValues();
		Object.entries(this).forEach(([key, value]) => {
			this._listenToDynamicValueOfKey(key, value);
		});
	}

	private _stopListeningToAllDynamicValues() {
		this.dynamicValuesListener.stopListeningToAll();
	}

	private _listenToDynamicValueOfKey(key:string, value: any) {
		const typed = key as keyof this;
		if (value instanceof ValueWrapper) {
			this.dynamicValuesListener.listenTo(value, this._onDynamicValueUpdated.bind(this, typed));
		} else if (Array.isArray(value)) {
			value.forEach((v, i) => {
				if (v instanceof ValueWrapper) {
					this.dynamicValuesListener.listenTo(v, this._onDynamicValueUpdated.bind(this, typed));
				}
			});
		}
	}

	private _onDynamicValueUpdated(key: keyof this, value: any) {
		this.setupValueForKey(key);
		this._rememberJustUpdated(key as string);
	}

	private _rememberJustUpdated(key: string) {
		this.updatedKeyTimes.set(key, Date.now());
	}

	private _stopListeningToDynamicValue(value: any) {
		if (value instanceof ValueWrapper) {
			this.dynamicValuesListener.stopListeningTo(value);
		} else if (Array.isArray(value)) {
			value.forEach((v, i) => {
				if (v instanceof ValueWrapper) {
					this.dynamicValuesListener.stopListeningTo(v);
				}
			});
		}
	}

	protected abstract setupAllValues(): void;
	protected abstract setupValueForKey(key: keyof this): void;
	protected abstract setupValueInArrayForKey(key: keyof this, index: number): void;

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this._stopListeningToAllDynamicValues();
	}
}
