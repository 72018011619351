import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ImageElement } from 'src/app/core/shared/models/layout/layoutElements';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';

@Component({
  selector: 'app-image-element',
	template: `<img #imgEl [src]="url | dynamic" [alt]="alt | dynamic" />`,
	styles: [`
    :host {
      display: contents;
    }
  `]
})
export class ImageElementComponent extends BlockLayoutElementComponent {

	@ViewChild('imgEl', { static: true }) imgEl!: ElementRef<HTMLImageElement>;

	protected override get contextElement(): ElementRef<HTMLImageElement> {
		return this.imgEl;
	}

	@Input() url: ImageElement['url'] = '';
	@Input() alt: ImageElement['alt'] = '';
	@Input() size: ImageElement['size'] = 'auto';

	override ngOnInit(): void {
		super.ngOnInit();
		this.setStyle('height', 'auto');
  }

	override setupAllValues(): void {
		this.setupValueForKey('size');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'size') {
			const unwrappedSize = unwrap(this.size);
			this.setClass('img-fluid', unwrappedSize === 'fluid');
			let width = `auto`;
			switch (unwrappedSize) {
				case 'text':
					width = `1em`;
					break;
				case 'small':
					width = `3em`;
					break;
				case 'medium':
					width = `6em`;
					break;
				case 'large':
					width = `12em`;
					break;
				case 'full':
					width = `100%`;
					break;
			}

			this.renderer2.setStyle(this.contextElement.nativeElement, 'width', width);
		}
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays
	}
}
