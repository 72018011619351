import { Component, OnDestroy } from '@angular/core';
import { CanStop } from '../../../utilities/subscriptionUtilities';

@Component({
  template: ``,
  styles: []
})
export abstract class DestroyableComponent extends CanStop implements OnDestroy {
	ngOnDestroy(): void {
		this.onStopped();
	}
}
