import type { Primitive, Tagged, UnwrapTagged } from 'type-fest';
import { TagContainer } from 'type-fest/source/opaque';
import { User5 } from "../models/database/userModels";
import { OrganizationId } from './../models/database/organizationModels';
import { SimpleChange } from '@angular/core';

export type ObjectKey = string | number | symbol;
export type Function = (...args: any[]) => any;
export type NotObject = Primitive | Primitive[] | Function;
// @deprecated use Type<T> from angular instead
export type ClassConstructor<T> = new (...args: any[]) => T;

export type TypedChanges<T> = { [propName in keyof T]: SimpleChange}

type ObjectWithFunctionsAsNever<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any ? never : T[K];
};
// Utility to check if the object still matches itself after removing functions
type IsObjectWithoutFunctions<T> = T extends ObjectWithFunctionsAsNever<T> ? keyof T extends keyof ObjectWithFunctionsAsNever<T> ? T : never : never


// export type NotArray = (object | Primitive) & { length?: never; };
// export type NonArrayObject = object & { length?: never; };
// export type NonFunction<T> = T extends Function ? never : T;

// export type NotFunc = Exclude<Primitive | Primitive[], Function> // any
// type ObjectWithoutFunctions = {
//   [K in ObjectKey]: Exclude<Primitive | Primitive[] | Record<string | number, any>, Function>;
// };

// const t6: NotObject = { a: 1, b: 'test' };
// const t7: Function = { a: 1, b: 'test' };
// const t8: Function = { a: 1, b: 'test', c: () => {} };
// const t9: Function = () => {};

// type t6 = { [key: string]: Function } extends ObjectWithoutFunctions ? false : true;


// type t1 = (() => void) extends object 																? true : false;
// type t2 = (() => void) extends Record<ObjectKey, any> 								? true : false;
// type t3 = Record<ObjectKey, any> extends Function 										? true : false;
// type t4 = Record<ObjectKey, any>[] extends Record<string, any> 				? true : false;
// type t5 = Record<ObjectKey, any> extends Record<string, any> 					? true : false;

// // type t6 = {[key:ObjectKey]: Function} extends ObjectWithoutFunctions 	? true : false;


// // type ObjectWithFunctions<T> = {
// //   [K in keyof T]: T[K] extends (...args: any[]) => any ? T[K] : never;
// // };



// // Step 1: Utility to check if a type is a function
// type IsFunction<T> = T extends (...args: any[]) => any ? true : false;

// // Step 2: Iterate over all properties and check for functions
// type HasFunction<T> = {
//   [K in keyof T]: IsFunction<T[K]>;
// }[keyof T];

// // Step 3: Return the original type if it has at least one function, otherwise return never
// type ObjectWithFunctions<T> = HasFunction<T> extends false ? never : T;

// type ObjectWithFunctions_ = {
//   [K in keyof any]: IsFunction<any[K]>;
// } extends true
//   ? any
//   : never;


// type IsObjectWithoutFunctions<T> = Extract<T, undefined> extends never
// 	? T extends ObjectWithoutFunctions<T> ? keyof T extends keyof ObjectWithoutFunctions<T> ? T : never : never
// 	: Extract<T, undefined> extends ObjectWithoutFunctions<Extract<T, undefined>> ? keyof Extract<T, undefined> extends keyof ObjectWithoutFunctions<Extract<T, undefined>> ? T : never : never;



export type IfNotPrimitive<T, Y, N> = T extends Primitive ? N : Y;

// #########################################################################
// ############################## DOT UPDATES ##############################
// #########################################################################

type DotPatchAtomObject<T, P> =
	T extends NotObject
		? never
		: P extends DotPath<T,6>
			? { path: P, value: TypeAtDotPath<T, P> }
			: never;

export type DotPatchAtom<T> = DotPatchAtomObject<T, DotPath<T,6>>;
export type DotPatches<T> = DotPatchAtom<T>[];

export const toKeyValuePatch = <T>(dotUpdates: DotPatches<T>): { [key: string]: any } => {
	const kvp: { [key: string]: any } = {};
	dotUpdates.forEach(update => kvp[(update as any).path] = (update as any).value);
	return kvp;
}

// #########################################################################
// ############################## TAGGING #################################
// #########################################################################

export function empty<T>(): T {
	return {} as T;
}

export type Tag<Type, Tag extends string> = Tagged<Type, Tag>; // Utility type to brand objects
export type IdTag<Tag extends string, Prefix extends string=''> = Tagged<`${Prefix}${string}`, Tag>; // Utility type to brand strings

type UnwrapTaggedKey<T> = Extract<T, undefined> extends never
	? T extends TagContainer<any>
		? UnwrapTagged<T> extends string
			? string
			: UnwrapTagged<T> extends number
				? number
				: T
		: T
	: Exclude<T, undefined> extends TagContainer<any>
		? UnwrapTagged<Exclude<T, undefined>> extends string
			? string
			: UnwrapTagged<Exclude<T, undefined>> extends number
				? number
				: T
		: T;

type UnwrapTaggedValue<T> = Extract<T, undefined> extends never
	? T extends TagContainer<any>
		? UnwrapTagged<T>
		: T
	: Exclude<T, undefined> extends TagContainer<any>
		? UnwrapTagged<Exclude<T, undefined>> | undefined
		: T;

// Utility type to unbrand the keys of Bla
export type UntaggedObject<T> = {
	[K in keyof T as UnwrapTaggedKey<K>]: T[K] extends NotObject ? UnwrapTaggedValue<T[K]> : {
			[W in keyof Exclude<T[K], undefined> as UnwrapTaggedKey<W>]: Exclude<T[K], undefined>[W] extends NotObject ? UnwrapTaggedValue<Exclude<T[K], undefined>[W]> : {
					[X in keyof Exclude<Exclude<T[K], undefined>[W], undefined> as UnwrapTaggedKey<X>]: Exclude<Exclude<T[K], undefined>[W], undefined>[X] extends NotObject ? UnwrapTaggedValue<Exclude<Exclude<T[K], undefined>[W], undefined>[X]> : {
							[Y in keyof Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined> as UnwrapTaggedKey<Y>]: Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y] extends NotObject ? UnwrapTaggedValue<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y]> : {
									[Z in keyof Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined> as UnwrapTaggedKey<Z>]: Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined>[Z] extends NotObject ? UnwrapTaggedValue<Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined>[Z]> : {
											[A in keyof Exclude<Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined>[Z], undefined> as UnwrapTaggedKey<A>]: Exclude<Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined>[Z], undefined>[A] extends NotObject ? UnwrapTaggedValue<Exclude<Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined>[Z], undefined>[A]> : {
													[B in keyof Exclude<Exclude<Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined>[Z], undefined>[A], undefined> as UnwrapTaggedKey<B>]: Exclude<Exclude<Exclude<Exclude<Exclude<Exclude<T[K], undefined>[W], undefined>[X], undefined>[Y], undefined>[Z], undefined>[A], undefined>[B]
												}
										}
								}
						}
				}
		}
};

type NoFunctions = {
  name: string;
  age: number;
};

type WithFunctions = {
  name: string;
  greet: () => void;
};

type Test1 = NoFunctions extends IsObjectWithoutFunctions<NoFunctions> ? true : false; // true
type Test2 = WithFunctions extends IsObjectWithoutFunctions<WithFunctions> ? true : false; // false
type Test3 = object extends IsObjectWithoutFunctions<object> ? true : false; // fals

// type Test4 = NoFunctions extends ObjectWithFunctions<NoFunctions> ? true : false; // false
// type Test5 = WithFunctions extends ObjectWithFunctions<WithFunctions> ? true : false; // true

type orgId1 = Tagged<`org_${OrganizationId}`, 'orgId'> | undefined;
type orgId2 = Tagged<`org_${OrganizationId}`, 'orgId'>;

type userPaths = DotPath<User5>;
type userPath = TypeAtDotPath<User5, `data.templates.${OrganizationId}.pinned`>;

type test = User5[] extends Record<string, any> ? 'yes' : 'no';

type unbrandedUser = UntaggedObject<User5>;

const updates: DotPatches<User5> = [{
	path: `data.templates.${'test' as OrganizationId}.pinned`,
	value: ['test'],
}, {
	path: `data.templates.${'test' as OrganizationId}.start`,
	value: 'test',
}];
const patch = toKeyValuePatch<User5>(updates);

type AllExtends<T1, T2, Base> = T1 extends Base ? T2 extends Base ? true : false : false;

type AllExtends3<T1, T2, T3, Base> =
    T1 extends Base ?
    T2 extends Base ?
    T3 extends Base ? true : false : false : false;

type AllExtends4<T1, T2, T3, T4, Base> =
		T1 extends Base ?
		T2 extends Base ?
		T3 extends Base ?
		T4 extends Base ? true : false : false : false : false;

type AllExtends5<T1, T2, T3, T4, T5, Base> =
		T1 extends Base ?
		T2 extends Base ?
		T3 extends Base ?
		T4 extends Base ?
		T5 extends Base ? true : false : false : false : false : false;

type AnyExtends2<T1, T2, Base> = T1 extends Base ? true : T2 extends Base ? true : false;

type AnyExtends3<T1, T2, T3, Base> =
		T1 extends Base ?
		true :
		T2 extends Base ?
		true :
		T3 extends Base ? true : false;

type AnyExtends4<T1, T2, T3, T4, Base> =
		T1 extends Base ?
		true :
		T2 extends Base ?
		true :
		T3 extends Base ?
		true :
		T4 extends Base ? true : false;

type AnyExtends5<T1, T2, T3, T4, T5, Base> =
		T1 extends Base ?
		true :
		T2 extends Base ?
		true :
		T3 extends Base ?
		true :
		T4 extends Base ?
		true :
		T5 extends Base ? true : false;


export type NestedAccess<
  TValue,
  P1 extends keyof TValue,
  P2 extends keyof TValue[P1] | never = never,
  P3 extends keyof TValue[P1][P2 & keyof TValue[P1]] | never = never,
  P4 extends keyof TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]] | never = never,
  P5 extends keyof TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]][P4 & keyof TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]]] | never = never
> = AnyExtends5<P1, P2, P3, P4, P5, never> extends true
		? AnyExtends4<P1, P2, P3, P4, never> extends true
			? AnyExtends3<P1, P2, P3, never> extends true
				? AnyExtends2<P1, P2, never> extends true
					? TValue[P1]
					: TValue[P1][P2 & keyof TValue[P1]]
				: TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]]
			: TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]][P4 & keyof TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]]]
		: P5 extends string
			? TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]][P4 & keyof TValue[P1][P2 & keyof TValue[P1]][P3 & keyof TValue[P1][P2 & keyof TValue[P1]]]][P5]
			: never


/**
 * Utility type to remove branding from a type, while preserving the structure.
 */
// type Unbrand<T> = T extends infer U & { __brand: any } ? U : T;

export type DepthControl = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type PrevDepth<D extends DepthControl> =
    D extends 10 ? 9 :
    D extends 9 ? 8 :
    D extends 8 ? 7 :
    D extends 7 ? 6 :
    D extends 6 ? 5 :
    D extends 5 ? 4 :
    D extends 4 ? 3 :
    D extends 3 ? 2 :
    D extends 2 ? 1 :
    0;

/**
 * Helper type to validate the path of properties in an object or class using dots as separators.
 * Includes depth limitation to prevent type instantiation issues.
 */
export type DotPath<T, D extends DepthControl = 5, Prefix extends string = ''> =
    D extends 0 ? never :
    T extends NotObject
			? never // dont go deeper into primitives, arrays and functions
			: T extends IsObjectWithoutFunctions<T>
					? { [K in keyof T]-?: K extends string | number
							? `${Prefix}${K}` | DotPath<T[K], PrevDepth<D>, `${Prefix}${K}.`>
							: never
					}[keyof T]// | (string extends keyof T ? `${Prefix}${string}` : never)
					: never;

/**
 * Utility type to extract the type of a value at a given dot path in an object.
 */
export type TypeAtDotPath<T, P, D extends DepthControl = 5, Traversed=""> =
	D extends 0 ? never :
	Traversed extends string
		?	T extends NotObject
			? never
			: P extends `${infer Key}.${infer Rest}`
				? Key extends keyof T

					? Rest extends DotPath<T[Key]>
						? TypeAtDotPath<T[Key], Rest, PrevDepth<D>, `${Traversed}.${Key}`>
						: `Key '${Key}' extends a key of T, but '${Rest}' is not a valid path. Traversed until now: '${Traversed}'`
						// : Rest extends DotPath<UnbrandedObject<T>>
						// 	? TypeAtDotPath<UnbrandedObject<T>, Rest, `${Traversed}.${Key}`>
						// 	: `Key '${Key}' extends a key of T with unbranded properties, but '${Rest}' is not a valid path. Traversed until now: '${Traversed}'`

					: Key extends keyof UntaggedObject<T>
						? P extends DotPath<UntaggedObject<T>>
							? TypeAtDotPath<UntaggedObject<T>, P, PrevDepth<D>, Traversed>
							: `Key '${Key}' extends a key of T with unbranded properties, but '${Rest}' is not a valid path. Traversed until now: '${Traversed}'`
						: `Key '${Key}' not found before path '${Rest}'. Traversed until now: '${Traversed}'`

				: P extends keyof T
						? T[P]
						: P extends keyof UntaggedObject<T>
							? TypeAtDotPath<UntaggedObject<T>, P, PrevDepth<D>, Traversed>
							: P extends string ? `Key '${P}' not found in object ${keyof UntaggedObject<T>}. Traversed until now: '${Traversed}'` : `Key P is not consodered a dot path, not a key in (unbranded) T and not a string. Traversed until now: '${Traversed}'`
		: `Traversed is not a string`;

// type test = {
// 	a: {
// 		b: {
// 			[key:string]: {
// 				d: string;
// 			};
// 		};
// 	};
// };

// const path: DotPath<test> = 'a.b.c.d';
// const type: TypeAtDotPath<test, typeof path> = 'bla';

/**
 * Utility type to extract the type of a value at a given slash path in an object.
 */
export type TypeAtSlashPath<T, P extends string> =
	P extends `${infer Key}/${infer Rest}`
			? Key extends keyof T
					? TypeAtSlashPath<T[Key], Rest>
					: never
			: P extends keyof T
					? T[P]
					: never;

/**
 * Helper type to validate the path of properties in an object or class, using slashes as separators.
 * Includes depth limitation to prevent type instantiation issues.
 */
export type SlashPath<T, D extends DepthControl = 10, Prefix extends string = ''> =
    D extends 0 ? never :
    T extends Primitive | Function
    ? never
    : T extends Array<any>
    ? never // Exclude arrays, adjust as necessary for your use case
    : T extends object
        ? { [K in keyof T]-?: K extends string | number
            ? `${Prefix}${K}` | SlashPath<T[K], PrevDepth<D>, `${Prefix}${K}/`>
            : never
        }[keyof T] | (string extends keyof T ? `${Prefix}${string}` : never)
        : never;

/**
 * Get the value at a given path in an object using dots as separators
 * @param obj - The object to get the value from
 * @param path - The path to the value
 * @returns The value at the given path
 */
export function getValueAtDotPath<T, P extends string, ReturnType = TypeAtDotPath<T, P>>(obj: T, path: P): ReturnType | undefined {
	return path.split('.').reduce((acc, part) => {
			if (acc && typeof acc === 'object' && part in acc) {
					return acc[part];
			}
			return undefined;
	}, obj as any);
}

export function getValueAtDotPathUntyped(obj: any, path: string): any {
	return path.split('.').reduce((acc, part) => {
			if (acc && typeof acc === 'object' && part in acc) {
					return acc[part];
			}
			return undefined;
	}, obj) as any;
}

export function setValueAtDotPath<T, P extends string>(obj: T, path: P, value: any): void {
	const parts = path.split('.');
	const lastPart = parts.pop();
	const finalObj = parts.reduce((acc, part) => {
			if (acc && typeof acc === 'object') {
					if (!(part in acc)) {
							acc[part] = {};
					}
					return acc[part];
			}
			return undefined;
	}, obj as any);
	if (finalObj && typeof finalObj === 'object' && lastPart) {
			finalObj[lastPart] = value;
	}
}

export function setValueAtStringArrayPath<T, P extends string[] = string[]>(obj: T, path: P, value: any): void {
	const finalObj = path.slice(0, -1).reduce((acc, part) => {
			if (acc && typeof acc === 'object') {
					if (!(part in acc)) {
							acc[part] = {};
					}
					return acc[part];
			}
			return undefined;
	}, obj as any);
	if (finalObj && typeof finalObj === 'object') {
			finalObj[path[path.length - 1]] = value;
	}
}

/**
 * Get the value at a given path in an object using slashes as separators
 * @param obj - The object to get the value from
 * @param path - The path to the value
 * @returns The value at the given path
 */
export function getValueAtSlashPath<T, P extends string>(obj: T, path: P): TypeAtSlashPath<T, P> {
    return (path as string).split('/').reduce((acc, part) => acc && acc[part], obj as any);
}
