import { filter, Observable, Subscriber, throttleTime } from "rxjs";

export type Unsubscribe = () => void;

export interface IListenable<T> {
	listen(callback: (value: T) => void): () => void;
	clearUpdateCallbacks(): void;
	asObservable(): Observable<T>;
}

export abstract class Listenable<T> implements IListenable<T> {

	private listeners: ((value: T) => void)[] = [];

	/**
	 * Add on change callback
	 * @param listener - callback to be called when value changes
	 * @returns - function to remove the callback
	 */
	public listen(listener: (value: T) => void): Unsubscribe {
		const index = this.listeners.push(listener);
		return () => {
			this.listeners.splice(index, 1);
		};
	}

	private _notificationsEnabled = true;

	protected notifyUpdate(value: T): void {
		if (!this._notificationsEnabled) return;
		// this.pipedUpdates?.next(value);
		this.listeners.forEach(listener => {
			try {
				listener?.(value);
			} catch (e) {
				console.error(e);
			}
		});
	}

	protected withoutUpdateNitifications(callback: () => void): void {
		this._notificationsEnabled = false;
		callback();
		this._notificationsEnabled = true;
	}

	public clearUpdateCallbacks(): void {
		this.listeners.length = 0;
	}

	public asObservable(): Observable<T> {
		return new Observable(observer => {
			const unsubscribe = this.listen((v) => {
				observer.next(v);
			});
			return unsubscribe;
		});
	}

	public asThrottledObservable(duration: number = 1000): Observable<T> {
		return this.asObservable().pipe(
			throttleTime(duration, undefined, { leading: true, trailing: true })
		);
	}
}
