import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// TODO nest
export type RouteData = {
	navbarShow: boolean;
	navbarContentBackToProd: boolean;
	navbarContentAccountManagement: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RouteDataService {
  private baseRouteData: RouteData = {
    navbarShow: true,
		navbarContentBackToProd: false,
		navbarContentAccountManagement: false,
  };

  public RouteData = new BehaviorSubject<RouteData>({...this.baseRouteData});

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute)
    ).subscribe(route => {
      const updatedRouteData = this.checkRouteData(route, {...this.baseRouteData});
      this.RouteData.next(updatedRouteData);
      //console.log('Route Data:', updatedRouteData);
    });
  }

  private checkRouteData(route: ActivatedRoute, routeData: RouteData): RouteData {
    let updatedRouteData = { ...routeData };

    if (route.snapshot.data) {
      for (const key in updatedRouteData) {
        if (route.snapshot.data.hasOwnProperty(key)) {
          updatedRouteData[key as keyof RouteData] = route.snapshot.data[key];
        }
      }
    }

    if (route.firstChild) {
      updatedRouteData = this.checkRouteData(route.firstChild, updatedRouteData);
    }

    return updatedRouteData;
  }
}
