<form [formGroup]="this.form" (ngSubmit)="onSubmit()">
	<div class="d-flex flex-column gap-4">
		<ng-content select="[header]"></ng-content>
		<div class="d-flex flex-column">
			@for(inputSetup of this.inputs; track inputSetup?.name; let i = $index) {
				<app-form-input
					#formInput
					*ngIf="inputSetup"
					[disabled]="!active"
					[setup]="inputSetup"
					[value]="values![i]"
					[group]="this.form"
					[name]="inputSetup.name ? inputSetup.name : 'control_' + inputSetup.index"
					(keydown.enter)="handleEnter($event, i)"
					class="form-input"
					[ngClass]="['form-input-' + inputSetup?.type, 'form-input-' + (inputSetup?.description ? 'with-description' : 'without-description')]"
				>
					<button
						*ngIf="inputSetup?.type === 'textarea' || inputSetup?.type === 'textfield'"
						id="editTemplateButton"
						class="btn btn-sm border-med bg-white"
						tooltip="page.generate.chat.newChatForTemplate.clean"
					>
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</app-form-input>
			}
		</div>
		<ng-content></ng-content>
		<ng-content select="[footer]"></ng-content>
	</div>
</form>
