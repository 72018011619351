import { DocumentReference, Timestamp } from "firebase/firestore";
import { FirestoreDocument, MetaData5 } from "./generalModels";
import { OrganizationRole5, OrganizationPermissions5 } from "./orgPermissionModels";

export const inviteLinkIdPrefix = "inviteLink_";

export let inviteCollectionsToIdPrefixMapping: { [key: string]: string } = {
	"inviteLinks": inviteLinkIdPrefix,
}

export type InviteLink5 = FirestoreDocument & {
	organizationPointer: { ref : DocumentReference, id: string };
	inviteCode: string;
	expires: Timestamp;
	remainingUses: number;
	created: MetaData5;
	role: OrganizationRole5; // setting roles (except 'custom') should automatically set permissions
	permissions: OrganizationPermissions5;
}

export type UseInviteLinkBody5 = {
	inviteId: string;
}

export type UserInviteLinkFunctionResult5 = {
	success: boolean;
	alreadyMember: boolean;
	localization: string;
}
