import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { Injectable } from '@angular/core';
import { Organization5, OrganizationBranding5 } from '../../models/database/organizationModels';
// @ts-ignore
import tinycolor from 'tinycolor2';
import { PageStateService } from './page-state.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

	branding: BehaviorSubject<OrganizationBranding5 | undefined> = new BehaviorSubject<OrganizationBranding5 | undefined>(undefined);

  constructor(activeOrganizationService: ActiveOrganizationService) {
		activeOrganizationService.activeOrganization.subscribe((org) => {
			let branding = org?.data?.branding;

			// branding migration
			if (org && (org as any)['branding']) {
				branding = (org as any)['branding'];
				// update the organization
				activeOrganizationService.patchOrg<Organization5 & {branding: undefined}>([
					{ path: 'branding', value: undefined },
					{ path: 'data.branding', value: branding }
				]);
			}

			this.branding.next(branding);
			this.applyBranding(branding);
		});
	}

	private applyBranding(branding: OrganizationBranding5 | undefined) {
		// console.log("Applying branding", branding)

		if (!branding) branding = { colors: { primary: null, secondary: null, bg_primary: null, bg_secondary: null }, logo: null };

		document.documentElement.style.setProperty('--g8-primary', branding.colors.primary);

		document.documentElement.style.setProperty('--g8-primary-darker-05', this.blendColors(branding.colors.primary, '#000000', 5));
		document.documentElement.style.setProperty('--g8-primary-darker-10', this.blendColors(branding.colors.primary, '#000000', 10));
		document.documentElement.style.setProperty('--g8-primary-darker-15', this.blendColors(branding.colors.primary, '#000000', 15));
		document.documentElement.style.setProperty('--g8-primary-darker-20', this.blendColors(branding.colors.primary, '#000000', 20));
		document.documentElement.style.setProperty('--g8-primary-darker-25', this.blendColors(branding.colors.primary, '#000000', 25));
		document.documentElement.style.setProperty('--g8-primary-darker-30', this.blendColors(branding.colors.primary, '#000000', 30));
		document.documentElement.style.setProperty('--g8-primary-darker-35', this.blendColors(branding.colors.primary, '#000000', 35));
		document.documentElement.style.setProperty('--g8-primary-darker-40', this.blendColors(branding.colors.primary, '#000000', 40));
		document.documentElement.style.setProperty('--g8-primary-darker-45', this.blendColors(branding.colors.primary, '#000000', 45));
		document.documentElement.style.setProperty('--g8-primary-darker-50', this.blendColors(branding.colors.primary, '#000000', 50));

		document.documentElement.style.setProperty('--g8-primary-opacity-10', this.setAlpha(branding.colors.primary, 0.1));
		document.documentElement.style.setProperty('--g8-primary-opacity-25', this.setAlpha(branding.colors.primary, 0.25));
		document.documentElement.style.setProperty('--g8-primary-opacity-50', this.setAlpha(branding.colors.primary, 0.5));
		document.documentElement.style.setProperty('--g8-primary-opacity-75', this.setAlpha(branding.colors.primary, 0.75));
		document.documentElement.style.setProperty('--g8-primary-opacity-90', this.setAlpha(branding.colors.primary, 0.9));

		document.documentElement.style.setProperty('--g8-secondary', branding.colors.secondary);

		document.documentElement.style.setProperty('--g8-secondary-darker-05', this.blendColors(branding.colors.secondary, '#000000', 5));
		document.documentElement.style.setProperty('--g8-secondary-darker-10', this.blendColors(branding.colors.secondary, '#000000', 10));
		document.documentElement.style.setProperty('--g8-secondary-darker-15', this.blendColors(branding.colors.secondary, '#000000', 15));
		document.documentElement.style.setProperty('--g8-secondary-darker-20', this.blendColors(branding.colors.secondary, '#000000', 20));
		document.documentElement.style.setProperty('--g8-secondary-darker-25', this.blendColors(branding.colors.secondary, '#000000', 25));
		document.documentElement.style.setProperty('--g8-secondary-darker-30', this.blendColors(branding.colors.secondary, '#000000', 30));
		document.documentElement.style.setProperty('--g8-secondary-darker-35', this.blendColors(branding.colors.secondary, '#000000', 35));
		document.documentElement.style.setProperty('--g8-secondary-darker-40', this.blendColors(branding.colors.secondary, '#000000', 40));
		document.documentElement.style.setProperty('--g8-secondary-darker-45', this.blendColors(branding.colors.secondary, '#000000', 45));
		document.documentElement.style.setProperty('--g8-secondary-darker-50', this.blendColors(branding.colors.secondary, '#000000', 50));

		document.documentElement.style.setProperty('--g8-secondary-opacity-10', this.setAlpha(branding.colors.secondary, 0.1));
		document.documentElement.style.setProperty('--g8-secondary-opacity-25', this.setAlpha(branding.colors.secondary, 0.25));
		document.documentElement.style.setProperty('--g8-secondary-opacity-50', this.setAlpha(branding.colors.secondary, 0.5));
		document.documentElement.style.setProperty('--g8-secondary-opacity-75', this.setAlpha(branding.colors.secondary, 0.75));
		document.documentElement.style.setProperty('--g8-secondary-opacity-90', this.setAlpha(branding.colors.secondary, 0.9));

		document.documentElement.style.setProperty('--g8-bg-primary', branding.colors.bg_primary);
		document.documentElement.style.setProperty('--g8-bg-secondary', branding.colors.bg_secondary);
	}

	private setAlpha(color: string | null, alpha: number): string | null {
		if (!color) return null;
		const colorObj = tinycolor(color);
		colorObj.setAlpha(alpha);
		return colorObj.toString();
	}

	private blendColors(color1: string | null, color2: string | null, ratio: number): string | null {
		if (!color1 || !color2) return null;
    const blendedColor = tinycolor.mix(color1, color2, ratio);
    return blendedColor.toString();
  }
}
