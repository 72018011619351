import { DocumentReference, Timestamp } from "firebase/firestore";
import { Address5, Contact5, DataDoc5, FirestoreDocument, GeneralInfo5, MetaData5, Reference5 } from "./generalModels";
import { OrganizationPermissions5, OrganizationRole5 } from "./orgPermissionModels";
import { UserInfo5 } from "./userModels";
import { VariablesData5 } from "./variableModels";
import { IdTag } from "../../utilities/typeUtilities";

/////////////////////////
// Organization Models
/////////////////////////

export type OrganizationId = IdTag<"organizationId",`org_`>;
export type OrganizationInviteId = IdTag<"organizationInviteId",`orgInvite_`>;

export let organzationCollectionsToIdPrefixMapping: { [key: string]: string } = {
	"organizations": "org_",
	"organizationInvites": "orgInvite_",
	"organizationMembers": "orgMember_",
	"organizationData/contact": "orgContact_",
	//"organizationData/billing": "orgBilling_",
}

export type Organization5 = FirestoreDocument & {
	created: MetaData5;
	info: OrganizationInfo5;
	data?: OrganizationData5;
}

export type OrganizationInfo5 = GeneralInfo5 & {
	website: string | null;
	contact: Contact5 | null;
};

export type OrganizationData5 = {
	variables?: VariablesData5;
	branding?: OrganizationBranding5;
}

export type OrganizationBranding5 = {
	colors: {
		primary: string | null;
		secondary: string | null;
		bg_primary: string | null;
		bg_secondary: string | null;
	},
	logo: string | null;
};

export type OrganizationMembership5 = FirestoreDocument & {
	created: MetaData5;
	invitedByMemberRef: DocumentReference | null;
	inviteLinkRef: DocumentReference | null;
	organizationRef: DocumentReference;
	organizationInfo: OrganizationInfo5;
	userRef: DocumentReference;
	status: OrganizationMembershipStatus5;
	memberInfo: OrganizationMemberInfo5;
	userInfo: UserInfo5;
	role: OrganizationRole5; // setting roles (except 'custom') should automatically set permissions
	permissions: OrganizationPermissions5;
}

export enum OrganizationMembershipStatus5 {
	ACTIVE = "active",
	INACTIVE = "inactive",
	REMOVED = "removed"
}

export type OrganizationMemberInfo5 = GeneralInfo5 & {
	roles: string[];
	title: string | null;
}

export type OrganizationInvite5 = FirestoreDocument<OrganizationInviteId> & {
	inviteId: OrganizationInviteId;
	organizationRef: DocumentReference;
	organizationName: string;
	email: string;
	state: OrganizationInviteState5;
	remindersSent: number;
	created: MetaData5;
	expires: Timestamp;

	role: OrganizationRole5;
	permissions: OrganizationPermissions5;
}

export enum OrganizationInviteState5 {
	PENDING = "pending",
	ACCEPTED = "accepted",
	DECLINED = "declined",
	REVOKED = "revoked",
	EXPIRED = "expired"
}

export type OrganizationPaymentData5 = FirestoreDocument & {
	paysForUsage: boolean;
	walletPath: Reference5;
}

export type OrganizationPurchaseData5 = DataDoc5 & {
	customer: {
			ref: DocumentReference; // Customer's unique identifier
			name: string; // Customer's name
			email: string; // Customer's email address
	},

	shippingAddress: Address5; // Customer's shipping address (if applicable)
	billingAddress: Address5; // Customer's billing address

	amount: number;
	currency: string; // Currency used for the purchase (e.g., USD, EUR)
	transactionFees: number; // Fees charged by Stripe for processing the payment
	taxAmount: number; // Amount of tax included in the purchase (if applicable)

	discount: {
			applied: boolean; // Indicates whether a discount or coupon was applied
			amount: number; // Amount of the discount applied (if applicable)
	}

	product: {
			description: string; // Description of the purchased product or service
			ref: DocumentReference; // Identifier for the specific product or service
	}

	paymentMethod: string; // Payment method used (e.g., credit card, debit card)
	paymentStatus: string; // Whether the payment was successful or failed

	stripe: {
			chargeRef: DocumentReference; // Unique identifier for the associated Stripe charge
			paymentIntentRef: DocumentReference; // Associated Payment Intent ID (if applicable)
			subscriptionRef: DocumentReference; // ID of the associated Stripe subscription (if applicable)
	}

	created: MetaData5;
	completed: MetaData5;
	failed: MetaData5;
}

export type OrganizationContactData5 = DataDoc5 & {
	recipient: string;
	email: string;
	phone: string;
	address: Address5;
}

//export type OrganizationBillingData5 = DataDoc5 & {
//    recipient: string;
//    email: string;
//    phone: string;
//    address: Address5;
//    bank: Bank5;
//}

export enum OrganizationDocType5 {
	ORGANIZATION_CREDITS = "organizationCredits",
	//ORGANIZATION_BILLING = "organizationBilling",
	ORANIZATION_CONTACT = "organizationContact",
}

/////////////////////////
// Function bodies
/////////////////////////

export type CreateOrganizationBody5 = {
	organizationName: string;
}

export type CreateOrganizationInviteBody5 = {
	organizationId: OrganizationId;
	email: string;
	role: OrganizationRole5;
	permissions: OrganizationPermissions5;
}

export type AcceptOrganizationInviteBody5 = {
	inviteId: OrganizationInviteId;
}

export type DeclineOrganizationInviteBody5 = {
	inviteId: OrganizationInviteId;
}

/////////////////////////
// Function results
/////////////////////////

export type OrganizationFunctionResult5 = {
	success: boolean;
	organizationId: OrganizationId;
}

export type OrganizationInviteFunctionResult5 = {
	success: boolean;
	organizationInviteId: OrganizationInviteId;
}
