import { Injectable } from '@angular/core';
import { UserInfo } from 'firebase/auth';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PaymentService } from 'src/app/payment/services/payment.service';
import { AuthService } from '../../../../auth/services/auth.service';
import { ActiveOrganizationService } from '../../../../organization/services/active-organization.service';
import { UserService } from '../../../../user/services/user.service';
import { OrganizationBranding5, OrganizationMembership5 } from '../../models/database/organizationModels';
import { User5 } from '../../models/database/userModels';
import { LoadingService } from '../loading.service';
import { BrandingService } from './branding.service';
import { EmptyGen8Params as emptyGen8Params, Gen8Params, NavigationService } from './navigation.service';

export type PageStateKeys = Omit<PageState, keyof ChangesInPageState>;

export type PageState = UserPageState & LoadingPageState & ParamsPageState & ChangesInPageState & OrganizationPageState & PageStateObject;

class PageStateObject {
	constructor() {
		(this as unknown as PageState).changed = {};
	}
}

type UserPageState = {
  authUser?: UserInfo;
  user?: User5;
	userId?: string;
	userSessionId?: string;

	isNonAnonymousUser?: boolean;
}

type OrganizationPageState = {
  activeMembership?: OrganizationMembership5;
	activeOrganizationId?: string;
	walletBalance?: number;
	branding?: OrganizationBranding5;

  enoughCredits?: boolean;
}

type ParamsPageState = {
  [T in keyof Gen8Params as `${T}Param`]: Gen8Params[T];
}

type LoadingPageState = {
  loading?: { isLoading: boolean, message?: string};
}

type ChangesInPageState = {
	/**
	 * Contains the key of the property that has changed in the page state.
	 */
  changed: { [K in keyof PageStateKeys]?: true };
}

@Injectable({
  providedIn: 'root'
})
/**
 * This service is used to maintain the state of the page. It is used to pass data to components, which data are not directly related to the route.
 * Many pages need data from the user, the membership, the folder, the file, etc. This service is used to pass that data to the components, so that
 * the components don't have to fetch the data themselves and can just use the data passed to them.
 *
 * This allows for better separation of concerns, and allows for better reusability of components.
 */
export class PageStateService {

  public pageState: BehaviorSubject<PageState> = new BehaviorSubject<PageState>(new PageStateObject() as PageState);

  private authUser: BehaviorSubject<UserInfo | undefined> = new BehaviorSubject<UserInfo | undefined>(undefined);
  private authUserSubscription: Subscription | undefined;

  private user: BehaviorSubject<User5 | undefined> = new BehaviorSubject<User5 | undefined>(undefined);
  private isNonAnonymousUser: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  private userSubscription: Subscription | undefined;

	private walletBalance: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
	private walletBalanceSubscription: Subscription | undefined;

  private membership: BehaviorSubject<OrganizationMembership5 | undefined> = new BehaviorSubject<OrganizationMembership5 | undefined>(undefined);
  private membershipSubscription: Subscription | undefined;

  private params: BehaviorSubject<Gen8Params> = new BehaviorSubject<Gen8Params>(new Gen8Params());
  private paramsSubscription: Subscription | undefined;

  private loading: BehaviorSubject<{ isLoading: boolean, message: string | undefined}> = new BehaviorSubject<{ isLoading: boolean, message: string | undefined}>({ isLoading: false, message: undefined});
  private loadingSubscription: Subscription | undefined;

	public branding: BehaviorSubject<OrganizationBranding5 | undefined> = new BehaviorSubject<OrganizationBranding5 | undefined>(undefined);
	private brandingSubscription: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private activeOrganizationService: ActiveOrganizationService,
    private userService: UserService,
    private loadingService: LoadingService,
    private paymentService: PaymentService,
		private brandingService: BrandingService)
	{
    this.authUserSubscription = this.authService.currentUser.subscribe(authUser => {
      this.authUser.next(authUser);
      this.onUpdate({ authUser: authUser });
    });

    this.userSubscription = this.userService.user.subscribe(async (user) => {
      this.user.next(user);
			this.isNonAnonymousUser.next(user ? !user.isAnonymous : false);
      this.onUpdate( {
				user: user,
				userId: user ? user.docId : undefined,
				isNonAnonymousUser: user ? !user.isAnonymous : true /* if there is no user, it is also not anonymous */ });
    });

		this.walletBalanceSubscription = this.paymentService.userWalletBalance.subscribe((walletBalance) => {
			this.walletBalance.next(walletBalance);
			this.onUpdate({ walletBalance: walletBalance, enoughCredits: walletBalance ? walletBalance >= 0 : false });
		});

    this.membershipSubscription = this.activeOrganizationService.activeMembership.subscribe((membership) => {
      this.membership.next(membership ? membership : undefined);
      this.onUpdate({ activeMembership: membership, activeOrganizationId: membership ? membership.organizationRef.id : undefined });
    });

    this.paramsSubscription = navigationService.currentParams.subscribe((newParams) => {
			const previousParams = this.params.value;
      this.params.next(newParams);
			const update: Partial<ParamsPageState> = {};
			for (const key in emptyGen8Params) {
				const paramKey = key as keyof Gen8Params;
				if (newParams[paramKey] !== previousParams[paramKey]) {
					update[`${key}Param` as keyof ParamsPageState] = newParams[paramKey];
				}
			}
			this.onUpdate(update);
    });

    this.loadingSubscription = this.loadingService.loadingMessage.subscribe((loading) => {
      this.loading.next(loading);
      this.onUpdate({ loading: loading });
    });

		this.brandingSubscription = this.brandingService.branding.subscribe((branding) => {
			this.branding.next(branding);
			this.onUpdate({ branding: branding });
		});
  }

  ngOnDestroy(): void {
    console.log("destroying page state service");
    if (this.paramsSubscription) this.paramsSubscription.unsubscribe();
    if (this.authUserSubscription) this.authUserSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.membershipSubscription) this.membershipSubscription.unsubscribe();
    if (this.loadingSubscription) this.loadingSubscription.unsubscribe();
		if (this.walletBalanceSubscription) this.walletBalanceSubscription.unsubscribe();
		if (this.brandingSubscription) this.brandingSubscription.unsubscribe();
  }

  private onUpdate<T extends keyof PageStateKeys>(patch: { [K in T]?: PageState[T] }) {
    const pageState = this.pageState.value;
    const changed = {} as PageState['changed'];
    for (const key in patch) {
			if (pageState[key] === patch[key]) continue;
			pageState[key] = patch[key] as PageState[Extract<T, string>];
			changed[key] = true;
    }
		pageState.changed = changed;
    this.pageState.next(pageState);
  }
}
