import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabLayoutGroupItem } from 'src/app/core/shared/models/layout/layoutGroups';
import { DynamicValuesComponent } from '../../base/dynamic-values/dynamic-values.component';

@Component({
  selector: 'app-tab-layout-group-item-tab',
  template: `
	<li class="nav-item">
		<a
			class="nav-link header position-relative d-flex justify-content-center align-items-center"
			[class.active]="active"
			[attr.aria-current]="active"
			href="#"
			(click)="onClick.emit(); $event.preventDefault();"
			[innerHTML]="label | dynamic | sanitizeHtml"
		>
		</a>
	</li>
`,
  styles: [`
		:host {
			display: contents;
			}
	`]
})
export class TabLayoutGroupItemTabComponent extends DynamicValuesComponent {

	@Input() active = false;
	@Input() label: TabLayoutGroupItem['label'] = '';
	@Output() onClick = new EventEmitter();

	protected override setupAllValues(): void {
		// no values to setup
	}

	override setupValueForKey(key: keyof this): void {
		// no keys to setup
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to setup
	}
}
