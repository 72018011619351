import { DocumentReference, Timestamp } from "firebase/firestore";
import { FirestoreDocument, GeneralInfo5, MetaData5 } from "./generalModels";
import { OrganizationInfo5 } from "./organizationModels";


/////////////////////////
// Public Token Models
/////////////////////////

// publicTokens/{publicTokenId}/publicTokenMvp1Access/{mvp1path}
// publicTokens/{publicTokenId}/publicTokenFileAccess/{fileId}
// publicTokens/{publicTokenId}/publicTokenFolderAccess/{folderId}

export let tokenCollectionsToIdPrefixMapping: { [key: string]: string } = {
  "organizationPublicTokens": "orgPubTok_",
}

export type PublicToken5 = FirestoreDocument & {
  created: MetaData5;
  owner: {
    organizationRef: DocumentReference;
    organizationInfo: OrganizationInfo5;
  }
  info: GeneralInfo5;
  publicTokenId: string;
  expires: Timestamp | null;
  status: PublicTokenStatus5;
}

export enum PublicTokenStatus5 {
  ACTIVE = "active",
  INACTIVE = "inactive",
  REMOVED = "removed",
  EXPIRED = "expired"
}

export type BasePublicTokenAccess5 = FirestoreDocument & {
  created: MetaData5;
  organizationRef: DocumentReference;
  publicTokenRef: DocumentReference;
}

export type PublicTokenMvp1Access5 = BasePublicTokenAccess5 & {
  mvp1path: string;
}

export type PublicTokenFileAccess5 = BasePublicTokenAccess5 & {
  fileRef: DocumentReference;
  read: boolean;
  write: boolean;
  derive: boolean;
}

export type PublicTokenFolderAccess5 = BasePublicTokenAccess5 & {
  folderRef: DocumentReference;
  read: boolean;
  write: boolean;
  derive: boolean;
}

/////////////////////////
// Function bodies
/////////////////////////

export type LinkUserToPublicTokenFunctionBody5 = {
  publicTokenId: string;
}

export type CreatePublicTokenFunctionBody5 = {
  organizationId: string;
  expires?: string;
}

/////////////////////////
// Function results
/////////////////////////

export type CreatePublicTokenFunctionResult5 = {
  success: boolean;
  publicTokenId: string;
}
