<div style="position: fixed; bottom: 20px; right: 20px; z-index: 999999;">
	<!-- Then put toasts within -->
	<!-- use white text when danger and success backgrounds -->
	<div class="toast show mt-2" role="alert" aria-live="assertive" aria-atomic="true" *ngFor="let toast of toasts" [ngClass]="{
		'bg-success': toast.type === 'success',
		'bg-danger': toast.type === 'error',
		'bg-warning': toast.type === 'warning',
		'bg-info': toast.type === 'info',
		'text-white': toast.type === 'success' || toast.type === 'error' || toast.type === 'warning'
	}" (click)="(toast.onClick ? toast.onClick() : ''); removeToast(toast.id)">
		<div class="toast-header" *ngIf="toast.header">
			<strong class="mr-auto" *ngIf="toast.header">{{toast.header}}</strong>
			<!-- <strong class="mr-auto" *ngIf="!toast.header">{{toast.type | titlecase}}</strong> -->
			<!-- <small class="text-muted"></small>
			<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="removeToast(toast.id)">
				<span aria-hidden="true">&times;</span>
			</button> -->
		</div>
		<div class="toast-body">
			{{toast.message}}
		</div>
	</div>
</div>
