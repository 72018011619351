import {DocumentReference, collection, collectionGroup, doc, where} from "firebase/firestore";
import {fbAuth, fbFirestore as fs, fbFunctions as cf} from "src/app/firebase-init";
import {idPrefixes} from "../../models/database/generalModels";
import {httpsCallable} from "@firebase/functions";
import {
  AcceptOrganizationInviteBody5,
  CreateOrganizationBody5,
  CreateOrganizationInviteBody5,
  DeclineOrganizationInviteBody5,
  OrganizationFunctionResult5,
  OrganizationInviteFunctionResult5
} from "../../models/database/organizationModels";

// functions
export const createOrganization = (data: CreateOrganizationBody5) => {
  const callable = httpsCallable<CreateOrganizationBody5, OrganizationFunctionResult5>(cf, 'createOrganization5');
  return callable(data);
}
export const createOrganizationInvite = (data: CreateOrganizationInviteBody5) => {
  const callable = httpsCallable<CreateOrganizationInviteBody5, OrganizationInviteFunctionResult5>(cf, 'createOrganizationInvite5');
  return callable(data);
}
export const acceptOrganizationInvite = (data: AcceptOrganizationInviteBody5) => {
  const callable = httpsCallable<AcceptOrganizationInviteBody5, OrganizationInviteFunctionResult5>(cf, 'acceptOrganizationInvite5');
  return callable(data);
}
export const declineOrganizationInvite = (data: DeclineOrganizationInviteBody5) => {
  const callable = httpsCallable<DeclineOrganizationInviteBody5, OrganizationInviteFunctionResult5>(cf, 'declineOrganizationInvite5');
  return callable(data);
}

// docs and collections
export const organizationsCollection = () => collection(fs, `organizations`);
export const organizationDoc = (organizationId: string) => doc(fs, `organizations`, organizationId);

export const organizationMembersCollection = (organizationId: string) => collection(fs, `organizations/${organizationId}/organizationMembers`);
export const organizationMemberDoc = (organizationId: string, userId: string) => doc(fs, `organizations/${organizationId}/organizationMembers`, userId);
export const organizationMembersCollectionGroup = () => collectionGroup(fs, 'organizationMembers');

export const organizationInvitesCollection = (organizationId: string) => collection(fs, `organizations/${organizationId}/organizationInvites`);
export const organizationInviteDoc = (organizationId: string, inviteId: string) => doc(fs, `organizations/${organizationId}/organizationInvites`, inviteId);
export const organizationInvitesCollectionGroup = () => collectionGroup(fs, 'organizationInvites');

export const organizationDataCollection = (organizationId: string) => collection(fs, `organizations/${organizationId}/organizationData`);
export const organizationPaymentDoc = (organizationId: string) => doc(fs, `organizations/${organizationId}/organizationData/orgPayment`);

export const StripeProductsCollection = () => collection(fs, `stripeProducts`);

export const whereEmail = (email: string) => where('email', '==', email);
export const whereAuthUserEmail = () => where('email', '==', fbAuth.currentUser?.email ?? '');
export const wherePending = () => where('state', '==', 'pending');

export const whereOrganizationRef = (organizationRef: DocumentReference) => where('organizationRef', '==', organizationRef);
export const whereOrganizationRefFromId = (organizationId: string) => whereOrganizationRef(organizationDoc(organizationId));
