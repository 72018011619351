import { UserService } from '../../../../../user/services/user.service';
import { listenToDocumentByRef } from '../../../utilities/firebase/firestoreUtilities';
import { Component, Input } from '@angular/core';
import { DocumentReference, onSnapshot } from 'firebase/firestore';
import { OrganizationMembership5 } from '../../../models/database/organizationModels';
import { UserInfo5 } from '../../../models/database/userModels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss']
})
export class UserBadgeComponent {
  @Input() userInfo: UserInfo5 | undefined | null;
	@Input() userRef: DocumentReference | undefined | null;
	@Input() showName: boolean = true;
	@Input() hideBackground: boolean = false;

	private subscription: Subscription | undefined;
	protected isLoggedInUser: boolean = false;

	constructor(public userService: UserService) {
		this.subscription = userService.user.subscribe((user) => {
			this.update();
		});
	}

	ngOnChanges(): void {
		this.update();
	}

	private update() {
		if (!this.userRef) return;
		this.isLoggedInUser = this.userRef?.path === this.userService.user.value?.docRef?.path;
	}

	private unsubscribe() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = undefined;
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe();
	}
}
