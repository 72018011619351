import { UserTemplatesData5 } from '../../core/shared/models/database/userModels';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { User5, UserInfo5, UserMembershipsData5 } from 'src/app/core/shared/models/database/userModels';
import { toasts } from 'src/app/core/shared/services/toasts.service';
import { userDocFromAuthId } from 'src/app/core/shared/utilities/database/userUtilities';
import { DotPatches, DotPath } from 'src/app/core/shared/utilities/typeUtilities';
import { ManagedDocumentListenerByRef, fsdb } from '../../core/shared/utilities/firebase/firestoreUtilities';
import { userIdFromAuthId } from '../../core/shared/utilities/database/userUtilities';
import { DocumentReference } from 'firebase/firestore';
import { VariablesData5 } from 'src/app/core/shared/models/database/variableModels';
import { OrganizationId } from 'src/app/core/shared/models/database/organizationModels';
import { getUnixTimestampInSeconds } from 'src/app/core/shared/utilities/timeUtilities';
import { ContextService } from 'src/app/core/shared/services/page/context.service';

export type ActiveOrganizationServiceTunnelToUserService = {
	setActiveOrganization(organizationId: OrganizationId): void;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<User5 | undefined> = new BehaviorSubject<User5 | undefined>(undefined);
	isLoggedIn: boolean = false;
	isLoggedInAnonymously: boolean = false;
	isLoggedInWithIdentity: boolean = false;
	isLoggedInAsGod: boolean = false;
	userSessionId: string | undefined;

	public get isLoading() { return this.userListener.isLoading.value || !this.authService.isInitialized; }

	private setUser(user: User5 | undefined) {
		this.ngZone.run(() => {
			if (user) this.contextService.setUser(user.info.name ?? "Nameless user", user.userId);
			else this.contextService.unsetUser();
			this.user.next(user);
		});
	}

  constructor(
		private authService: AuthService,
		private contextService: ContextService,
		private ngZone: NgZone) {
    this.userSessionId = localStorage.getItem('userSessionId') ?? undefined;
    if (!this.userSessionId) {
      this.userSessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem('userSessionId', this.userSessionId);
    }

    this.authService.currentUser.subscribe(async (user) => {
			// if (user) console.warn('User signed in:', user);
			// else console.warn('User signed out');

      if (!user || !user.uid) {
				this.userListener.pause();
				this.isLoggedIn = false;
				this.isLoggedInAnonymously = false;
				this.isLoggedInWithIdentity = false;
        this.setUser(undefined);
        return;
      }

			this.isLoggedIn = true;
			this.isLoggedInAnonymously = (user as any).isAnonymous;
			this.isLoggedInWithIdentity = !this.isLoggedInAnonymously;

			if (this.isLoggedInAnonymously) {
				this.setUser({
					userRef: null,
					userId: userIdFromAuthId(user.uid),
					created: null,
					info: {
						name: 'Anonymous User',
						description: '',
						image: '',
					},
					isAnonymous: true,
				} as User5);
				return;
			}

			// console.log('user is logged in anonymously?', this.isLoggedInAnonymously);

			const userDoc = userDocFromAuthId(user.uid);
			this.userListener.listenTo(userDoc);
    });

		this.checkGodMode().then((isGod: boolean) => {
			this.isLoggedInAsGod = isGod;
		});
  }

	get id(): string | undefined {
		return this.user.value?.userId;
	}

	get ref(): DocumentReference | undefined {
		return this.user.value?.docRef;
	}

	get isAnonymous(): boolean | undefined {
		return this.user.value?.isAnonymous;
	}

	get info(): UserInfo5 | undefined {
		return this.user.value?.info;
	}

	get variablesData(): VariablesData5 | undefined {
		return this.user.value?.data?.variables;
	}

	get membershipsData(): UserMembershipsData5 | undefined {
		return this.user.value?.data?.memberships;
	}

	get templatesData(): UserTemplatesData5 | undefined {
		return this.activeOrganizationId ? this.user.value?.data?.templates?.[this.activeOrganizationId] : undefined;
	}

	private get activeOrganizationId(): OrganizationId | undefined {
		return this.user.value?.data?.memberships?.active?.organizationId;
	}

	private tunnelForActiveOrganizationServiceGiven: boolean = false;
	getTunnelForActiveOrganizationService() {
		if (!this.tunnelForActiveOrganizationServiceGiven) {
			this.tunnelForActiveOrganizationServiceGiven = true;
			const userService = this;
			return {
				setActiveOrganization: (organizationId: OrganizationId) => {
					const orgIdDataPath: DotPath<User5> = 'data.memberships.active.organizationId'
					const orgTimestampDataPath: DotPath<User5> = 'data.memberships.active.timestamp'

					userService.patchUser([
						{
							path: orgIdDataPath,
							value: organizationId,
						},
						{
							path: orgTimestampDataPath,
							value: getUnixTimestampInSeconds(),
						}
					]);
				}
			};
		} else {
			throw new Error('Tunnel for ActiveOrganizationService already given');
		}
	}


	async checkGodMode(): Promise<boolean> {
		try {
			const document = await fsdb.get(`system/gods`);
			return document !== undefined;
		} catch (error) {
			return false;
		}
	}

	updateUser(patch: Partial<User5>) {
		const user = this.user.value;

		if (!user) return;

		return fsdb.update(user.docRef!.path, patch).then(() => {
			// done
		}).catch((error) => {
			console.error('error updating user', patch, error);
		});
	}

	updateInfo(info: UserInfo5) {
		const user = this.user.value;

		if (!user) return;

		const update = fsdb.update(user.docRef!.path, { info }).then(() => {
			// done
		}).catch((error) => {
			console.error('error updating user info', info, error);
		});

		if (info?.name) {
			this.authService.updateDisplayName(info.name).then(() => {
				// done
			}).catch((error) => {
				console.error('error updating display name', error);
			});
		}

		if (info?.image) {
			this.authService.updatePhotoURL(info.image).then(() => {
				// done
			}).catch((error) => {
				console.error('error updating photo URL', error);
			});
		}

		return update;
	}

	patchUser(patch: DotPatches<User5>) {
		const user = this.user.value;
		if (!user) return;
		return fsdb.patch(user.docRef!.path, patch).then(() => {
			// done
		}).catch((error) => {
			throw error;
		});
	}

	//userListener: Unsubscribe | undefined;
	private userListener: ManagedDocumentListenerByRef<User5> = new ManagedDocumentListenerByRef<User5>(
		(user: User5 | undefined) => {

			// check if the user still exists
			if (!user) {
				this.setUser(undefined);
				return;
			}

			//if (doc.metadata.fromCache)	{
			//	console.log('User doc is from cache');
			//}

			// add user id if it is missing
			if (!user.userId && user.userRef?.id) {
				user.userId = user.userRef?.id;
			}

			this.setUser(user);
		},
		(error: Error) => {
			toasts.error(error, `Error loading user: ${error.message}`);
		}
	)
	.enableRetry(true);
}
