import { LocalizationWrapper } from "../../../directives/localization/localization-wrapper";
import { LocalizationContext, LocalizationKey, LocalizationTransformType } from "../../config/localization/localizationModels";
import { BaseHtmlBit } from "./base-html-bit";

export class LocalizedHtmlBit extends BaseHtmlBit {

	private wrapper: LocalizationWrapper;

	constructor(localizationWrapper: LocalizationWrapper)
	constructor(key: LocalizationKey, context?: LocalizationContext, transform?: LocalizationTransformType)
	constructor(keyOrWrapper: LocalizationKey | LocalizationWrapper, context?: LocalizationContext, transform?: LocalizationTransformType) {
		super();
		if (keyOrWrapper instanceof LocalizationWrapper) {
			this.wrapper = keyOrWrapper;
		} else {
			this.wrapper = new LocalizationWrapper(keyOrWrapper, context, transform);
		}
		this.listenTo(this.wrapper);
		this.updateValue();
	}

	set key(key: LocalizationKey) {
		this.wrapper.key = key;
	}

	set context(context: LocalizationContext | string) {
		this.wrapper.context = context;
	}

	set transform(transform: LocalizationTransformType) {
		this.wrapper.transform = transform;
	}

	get html(): string {
		return this.wrapper.value;
	}
};
