/////////////////////////
// Payment Models
/////////////////////////

import { MetaData5 } from "./generalModels"

export type CheckoutSession5 = {
  mode: 'payment'
  status: CheckoutSessionStatus5
  success_url: string
  cancel_url: string
  metadata: {
      organizationRefPath: string
      userRefPath: string
      memberRefPath: string
      checkoutSessionRefPath: string
  },
  initiated: MetaData5
  line_items: Array<CheckoutLineItem5>
  automatic_tax?: boolean, // Automatically calculate tax based on the customer's address
  tax_id_collection?: boolean, // Collect the customer's tax ID (important for B2B transactions)
  invoice_creation?: {
      enabled: boolean, // Automatically create an invoice for the customer
      invoice_data?: {
          account_tax_ids?: string[],
          custom_fields?: {
              name: string,
              value: string
          }[],
          description?: string,
          footer?: string,
          metadata?: {
              [key: string]: string
          },
          rendering_options?: {
              [key: string]: string
          }
      }
  }
}

export type CheckoutLineItem5 = {
  price: string
  quantity: number
  dynamic_tax_rates?: string[]
}

export enum CheckoutSessionStatus5 {
  PAID = "paid",
  FAILED = "failed",
  PENDING = "pending",
  EXPIRED = "expired"
}

/////////////////////////
// Function bodies
/////////////////////////

export enum WalletOwnerType5 {
	ORG = "org",
	USER = "user"
}

export type CreateCreditsCheckoutSessionBody5 = {
	quantity: number;
	walletId: string;
	giftcard: boolean;
}

/////////////////////////
// Function results
/////////////////////////

export type CreateCheckoutSessionResult5 = {
	success: boolean;
	checkoutUrl: string;
}

/////////////////////////
// Function inputs
/////////////////////////

export type SwitchPaymentPlanFunctionInput5 = {
    organizationId: string;
    paymentPlanId: string;
}

export type CreatePortalSessionResult5 = {
	portalUrl: string;
}
