import { ORGANIZATION_CREATOR_PERMISSIONS, ORGANIZATION_GUEST_PERMISSIONS, ORGANIZATION_MANAGER_PERMISSIONS, ORGANIZATION_OWNER_PERMISSIONS, ORGANIZATION_PUBLIC_PERMISSIONS, ORGANIZATION_USER_PERMISSIONS, OrganizationActionPermissionPath5, OrganizationPermissions5, OrganizationRole5 } from "../../models/database/orgPermissionModels"
import { OrganizationMembership5 } from "../../models/database/organizationModels"

export function getRoleValue(role: OrganizationRole5): number {
	switch (role) {
		case OrganizationRole5.OWNER:
			return 4
		case OrganizationRole5.MANAGER:
			return 3
		case OrganizationRole5.CREATOR:
			return 2
		case OrganizationRole5.USER:
			return 1
		case OrganizationRole5.GUEST:
			return 0
		case OrganizationRole5.PUBLIC:
			return -1
		default:
			return -2
	}
}

export function isRoleAtLeast(role: OrganizationRole5, atLeast: OrganizationRole5): boolean {
	return getRoleValue(role) >= getRoleValue(atLeast)
}

export function isValidRole(role: OrganizationRole5): boolean {
	return getRoleValue(role) >= 0 && role !== undefined && role !== null
}

function hasPermission(permissions: any, permissionPath: string): boolean {
	const [category, feature, action] = permissionPath.split('.');
	return permissions[category]?.[feature]?.[action] ?? false;
}

export function memberHasPermissionFor(member: OrganizationMembership5 | undefined, permissionPath: OrganizationActionPermissionPath5 | OrganizationActionPermissionPath5[], pathListOperator?: 'and' | 'or'): boolean {
	const permissions: any = member ? (member.permissions ?? (member.role ?? (getPermissionsForRole(OrganizationRole5.GUEST)))) : getPermissionsForRole(OrganizationRole5.PUBLIC);

	if (Array.isArray(permissionPath)) {
		if (pathListOperator === 'and') {
			return permissionPath.every(path => hasPermission(permissions, path));
		} else {
			return permissionPath.some(path => hasPermission(permissions, path));
		}
	} else {
		return hasPermission(permissions, permissionPath);
	}
}

export function getPermissionsForRole(role: OrganizationRole5): OrganizationPermissions5 {
	switch (role) {
		case OrganizationRole5.OWNER:
			return ORGANIZATION_OWNER_PERMISSIONS
		case OrganizationRole5.MANAGER:
			return ORGANIZATION_MANAGER_PERMISSIONS
		case OrganizationRole5.CREATOR:
			return ORGANIZATION_CREATOR_PERMISSIONS
		case OrganizationRole5.USER:
			return ORGANIZATION_USER_PERMISSIONS
		case OrganizationRole5.GUEST:
			return ORGANIZATION_GUEST_PERMISSIONS
		case OrganizationRole5.PUBLIC:
			return ORGANIZATION_PUBLIC_PERMISSIONS
		default:
			return ORGANIZATION_PUBLIC_PERMISSIONS
	}
}
