import { Destroyable } from './destroyable';
import { Listenable } from './listenables';

export class Listener<T> implements Destroyable {
	private _unsubs: any[] = [];
	private _listeningTo: any[] = [];

	listenTo(obj: Listenable<T> | unknown, listener: (value: T) => void) {
		if (obj instanceof Listenable) {
			this._unsubs.push(obj.listen(listener));
			this._listeningTo.push(obj);
		}
	}

	stopListeningTo(obj: Listenable<T> | unknown) {
		const index = this._listeningTo.indexOf(obj);
		if (index !== -1) {
			this._unsubs[index]();
			this._unsubs.splice(index, 1);
			this._listeningTo.splice(index, 1);
		}
	}

	stopListeningToAll() {
		this._unsubs.forEach((unsub) => unsub());
		this._unsubs.length = 0;
		this._listeningTo.length = 0;
	}

	destroy() {
		this._unsubs.forEach((unsub) => unsub());
		this._unsubs.length = 0;
		this._listeningTo.length = 0;
	}
}
