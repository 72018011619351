export function getUnixTimestampInSeconds () {
  return Math.floor(Date.now() / 1000)
}

export function getUnixTimestampInMilliseconds () {
	return Date.now()
}

export function getDateFromUnixTimestamp (timestamp: number) {
	return new Date(timestamp * 1000)
}

export function formatDayPadded(date: Date): string {
	const day = date.getDate();
	return `${day < 10 ? '0' + day : day}`;
}

export function formatMonthPadded(date: Date): string {
	const month = date.getMonth() + 1; // month is zero based
	return `${month < 10 ? '0' + month : month}`;
}

// yyyy-MM-dd (e.g. 2020-01-01)
export function formatDateSortable(date: Date): string {
	const year = date.getFullYear();
	return `${year}-${formatMonthPadded(date)}-${formatDayPadded(date)}`;
}

// yyyy-MM-dd (e.g. 2020-01-01) and with time (e.g. 2020-01-01_12-00-00)
export function formatDateTimeSortable(date: Date): string {
	const formattedDate = formatDateSortable(date);
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();
	return `${formattedDate}_${hours < 10 ? '0' + hours : hours}-${minutes < 10 ? '0' + minutes : minutes}-${seconds < 10 ? '0' + seconds : seconds}`;
}
