import { Injectable } from '@angular/core';
import { httpsCallable } from '@firebase/functions';
import { doc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { hostConfigs } from 'src/config/host-config';
import { HostConfigService } from 'src/app/core/shared/directives/host-config/host-config.service';
import { fbFirestore, fbFunctions } from 'src/app/firebase-init';
import { environment } from 'src/environments/environment';
import { SystemStatus } from '../models/database/systemModels';
import { loadDocumentByRef, updateDocumentByRef } from '../utilities/firebase/firestoreUtilities';
import { toasts } from './toasts.service';

export let devMode: boolean = false;
export const devModeHostSpoof = localStorage.getItem('hostSpoof');

@Injectable({
  providedIn: 'root'
})
export class DevService {

	private devModeWasEnabled: boolean = false;
	public devMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private keysDown: string[] = [];

	constructor(private hostConfigService: HostConfigService) {
		if (devModeHostSpoof) {
			//toasts.info("Host spoofed: " + devModeHostSpoof + ". Click here to clear", () => {
			//	this.setHostSpoof(null);
			//}, 10000);
		}

		this.setDevMode(devModeHostSpoof !== null || (this.hostConfigService.get('ui.devTools.isEnabled') ?? false));
		this.initialize();


		// toggle dev mode if all D E V keys are pressed
		document.addEventListener("keydown", (event) =>
		{
			if (event.key !== "d" && event.key !== "e" && event.key !== "v") {
				this.keysDown = [];
			}

			this.keysDown.push(event.key);
			if (this.keysDown.join("").toLowerCase().includes("dev")) {
				this.keysDown = [];
				this.toggleDevMode();
			}
		});

		// clear keysDown when the keys are released
		document.addEventListener("keyup", (event) =>
		{
			this.keysDown = [];
		});
	}

	get hostSpoofs() {
		return Object.keys(hostConfigs);
	}

	get currentHostSpoof() {
		return devModeHostSpoof;
	}

	setHostSpoof(value: string | null) {
		if (value) {
			localStorage.setItem('hostSpoof', value);
		} else {
			localStorage.removeItem('hostSpoof');
		}
		// refresh the page to apply the new host spoof
		window.location.reload();
	}

	public toggleDevMode() {
		this.setDevMode(!this.devMode.value);
	}

	public setDevMode(value: boolean) {
		if (value || this.devModeWasEnabled) {
			this.devModeWasEnabled = true;
			//toasts.info("Dev mode " + (value ? "enabled" : "disabled") + ". Click here to toggle", () => this.toggleDevMode(), 10000);
		}
		devMode = value;
		this.devMode.next(value);
	}

	initialize() {
		// if we use firebase emulators (check with environment variable), we want to use the emulator database
		if (environment['useEmulators']) {
			console.log("Using Firebase Emulators");
			loadDocumentByRef<SystemStatus>(doc(fbFirestore, "system/status"), (error) => {
				console.error("Error loading system status", error);
			}).then((status) => {
				if (!status?.templatesInitialized) {
					this.loadTemplates();
				}
				if (!status?.systemMessagesInitialized) {
					this.loadSystemPrompts();
				}
				if (!status?.paymentPlansInitialized) {
					this.loadPaymentPlans();
				}
				//if (!status?.stripeProductsInitialized) {
				//	this.loadStripeProducts();
				//}
			});
		}
	}

	loadTemplates() {
		return httpsCallable(fbFunctions, "loadTemplates5").call(null).then(() => {
			console.log("Templates loaded");
			updateDocumentByRef<SystemStatus>(doc(fbFirestore, "system/status"), {
				templatesInitialized: true,
			}).then(() => {
				console.log("Templates initialized");
			});
		});
	}

	loadSystemPrompts() {
		return httpsCallable(fbFunctions, "loadSystemPrompts5").call(null).then(() => {
			console.log("System prompts loaded");
			updateDocumentByRef<SystemStatus>(doc(fbFirestore, "system/status"), {
				systemMessagesInitialized: true,
			}).then(() => {
				console.log("System prompts initialized");
			});
		});
	}

	loadPaymentPlans() {
		return httpsCallable(fbFunctions, "loadPaymentPlans5").call(null).then(() => {
			console.log("Payment plans loaded");
			updateDocumentByRef<SystemStatus>(doc(fbFirestore, "system/status"), {
				paymentPlansInitialized: true,
			}).then(() => {
				console.log("Payment plans initialized");
			});
		});
	}

	//loadStripeProducts() {
	//	return httpsCallable(fbFunctions, "loadStripeProducts5").call(null).then(() => {
	//		console.log("Stripe products loaded");
	//		updateDocumentByRef<SystemStatus>(doc(fbFirestore, "system/status"), {
	//			stripeProductsInitialized: true,
	//		}).then(() => {
	//			console.log("Stripe products initialized")
	//		});
	//	});
	//}

}
