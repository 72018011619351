import { ManagedDocumentListenerByRef, listenToDocumentByRef } from '../../../utilities/firebase/firestoreUtilities';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DocumentReference, onSnapshot } from 'firebase/firestore';
import { OrganizationMembership5 } from '../../../models/database/organizationModels';
import { UserInfo5 } from '../../../models/database/userModels';
import { DestroyableComponent } from '../../base/destroyable/destroyable.component';

@Component({
  selector: 'app-member-badge',
  templateUrl: './member-badge.component.html',
  styleUrls: ['./member-badge.component.scss']
})
export class MemberBadgeComponent extends DestroyableComponent implements OnChanges, OnInit{
  @Input() memberRef?: DocumentReference | null;

  protected isLoading: boolean = false;
	protected collectiveUserInfo: UserInfo5 | undefined;
	protected userRef: DocumentReference | undefined;

  private memberListener: ManagedDocumentListenerByRef<OrganizationMembership5> = new ManagedDocumentListenerByRef(
		(member: OrganizationMembership5 | undefined) => {
			if (!member) {
				this.isLoading = false;
				this.collectiveUserInfo = undefined;
				this.userRef = undefined;
				return;
			}

			this.isLoading = false;
			this.collectiveUserInfo = {
				name: member.memberInfo.name || member.userInfo.name,
				image: member.memberInfo.image || member.userInfo.image,
				description: member.memberInfo.description || member.userInfo.description,
			}
			this.userRef = member.userRef;
		},
		(error: Error) => {
			this.isLoading = false;
			this.collectiveUserInfo = undefined;
			console.error('Error listening to member', error);
		})
		.hookStop(this)

	ngOnInit(): void {
		this.ngOnChanges();
	}

  ngOnChanges(): void {
    if (this.memberRef === undefined || this.memberRef === null) return;
		this.isLoading = true;
    this.memberListener.listenTo(this.memberRef)
  }
}
