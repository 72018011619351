import { Injectable } from '@angular/core';

export enum ObservedEvent {
	Added = 'added',
	Removed = 'removed',
}

type Observing = {
	className: string;
	callback: (element: Element, event: ObservedEvent) => void;
	elements: Element[];
};

@Injectable({
  providedIn: 'root'
})
export class DomObserverService {

	private static _instance: DomObserverService;
	static get instance(): DomObserverService {
		if (!DomObserverService._instance) {
			throw new Error('DomObserverService instance is not initialized');
		}
		return DomObserverService._instance;
	}

	private _observing: Map<string, Observing> = new Map();
	private _isObserving = false;

	private observer = new MutationObserver((mutationsList, observer) => {
		mutationsList.forEach((mutation) => {

      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {

        const target = mutation.target as Element;
				this._observing.forEach(observing => {
					const { className, callback } = observing;
					const wasClassAdded = !mutation.oldValue?.includes(className) && target.classList.contains(className);
					const wasClassRemoved = mutation.oldValue?.includes(className) && !target.classList.contains(className);

					if (wasClassAdded) {
						callback(target, ObservedEvent.Added);
					} else if (wasClassRemoved) {
						callback(target, ObservedEvent.Removed);
					}
				});

      }

			if (mutation.type === 'childList') {

				mutation.addedNodes.forEach(node => {
					if (node instanceof Element) {
						this._observing.forEach(observing => {
							const { className, callback } = observing;
							if (node.classList.contains(className)) {
								callback(node, ObservedEvent.Added);
							}
						});
					}	
				});

				mutation.removedNodes.forEach(node => {
					if (node instanceof Element) {
						this._observing.forEach(observing => {
							const { className, callback } = observing;
							if (node.classList.contains(className)) {
								callback(node, ObservedEvent.Removed);
							}
						});
					}
				});

			}
		});
	});

	constructor() {
		if (DomObserverService._instance) {
			throw new Error('DomObserverService instance is already initialized');
		}
		DomObserverService._instance = this;
	}

	private _startObserving() {
		if (this._isObserving) return;
		this._isObserving = true;
		// Start observing the entire document
		this.observer.observe(document.body, { 
			childList: true, 
			subtree: true,
			attributes: true,
			attributeOldValue: true,
			attributeFilter: ['class']
		});
	}

	private _stopObserving() {
		if (!this._isObserving) return;
		this.observer.disconnect();
		this._isObserving = false;
	}

	observeElementsWithClass(className: string, callback: (element: Element, event: ObservedEvent) => void) {
		const elements = document.getElementsByClassName(className);

		this._observing.set(className, {
			className,
			callback,
			elements: Array.from(elements)
		});

		this._observing.get(className)?.elements.forEach(element => {
			callback(element, ObservedEvent.Added);
		});

		this._startObserving();
	}

	stopObserving(className: string) {
		this._observing.delete(className);
		if (this._observing.size === 0) {
			this._stopObserving();
		}
	}

}
