import { httpsCallable } from "@firebase/functions";
import { fbFunctions as cf } from "src/app/firebase-init";
import { CreateCreditsCheckoutSessionBody5, CreateCheckoutSessionResult5 as CreateCreditsCheckoutSessionResult5, CreatePortalSessionResult5 } from "../../models/database/paymentModels";
import { collection } from "firebase/firestore";
import {fbAuth, fbFirestore as fs  } from "src/app/firebase-init";
import { AcceptCreditGiftInput5 } from "../../models/database/giftCardModels";

export const PRODUCT_COLLECTION_NAME = "products";

export const productCollection = () => collection(fs, PRODUCT_COLLECTION_NAME);

export const createStripeCheckoutSession = (data: CreateCreditsCheckoutSessionBody5) => {
  const callable = httpsCallable<CreateCreditsCheckoutSessionBody5, CreateCreditsCheckoutSessionResult5>(cf, 'createStripeCreditsCheckoutSession5');
  return callable(data);
}

export const createStripePortalSession = () => {
	const callable = httpsCallable<null, CreatePortalSessionResult5>(cf, 'createStripePortalSession5');
	return callable();
}

export const AcceptGiftCard = (data: AcceptCreditGiftInput5) => {
	const callable = httpsCallable<AcceptCreditGiftInput5, null>(cf, 'AcceptGiftCard5');
	return callable(data);
}
