import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditsToCurrencyString'
})
export class CreditsToCurrencyStringPipe implements PipeTransform {
	private credits_to_currency_ratio = 75;

  transform(credits: number, decimals: number = 2): string {
		const currencyAmount = credits * this.credits_to_currency_ratio
		const currencyString = currencyAmount.toFixed(decimals).toString();
		return currencyString;
  }
}
