import { BehaviorSubject } from "rxjs";
import { ErrorCallback } from './errorUtilities';
import { ResultCallback } from './firebase/databaseUtilities';

export class CanStop {
	stopped = false;

	listeners: (() => void)[] = [];
	tokens: any[] = [];

	addOnStopListener(listener: () => void, token?: any) {
		if (this.stopped) {
			listener();
			return;
		}
		this.listeners.push(listener);
		this.tokens.push(token);
	}

	removeOnStopListener(listener: () => void) {
		const index = this.listeners.indexOf(listener);
		if (index !== -1) {
			this.listeners.splice(index, 1);
			this.tokens.splice(index, 1);
		}
	}

	removeOnStopListenerByToken(token: any) {
		const index = this.tokens.indexOf(token);
		if (index !== -1) {
			this.listeners.splice(index, 1);
			this.tokens.splice(index, 1);
		}
	}

	protected onStopped() {
		this.stopped = true;
		this.listeners.forEach(listener => listener());
		this.listeners = [];
	}
}

export class SingleSubscriptionManager {
	stopCurrentSubscription?: () => void;
	stopped = false;
	debug = false;

	constructor(unsubscribe?: () => void) {
		if (unsubscribe) this.replaceSubscription(unsubscribe);
	}

	hook(stoppable: CanStop) {
		stoppable.addOnStopListener(() => this.unsubscribeAndStop());
		return this;
	}

	isStopped() {
		return this.stopped;
	}

	enableDebug(enable: boolean = true) {
		this.debug = enable;
		return this;
	}

	replaceSubscription(newUnsubscribe: () => void | undefined) {
		this.unsubscribe();
		if (this.stopped) {
			newUnsubscribe();
			if (this.debug) console.log('SubscriptionManager: Subscription immediately unsubscribed because this manager is stopped');
			return;
		}
		if (newUnsubscribe) {
			if (this.debug) console.log('SubscriptionManager: Subscription replaced');
			this.stopCurrentSubscription = newUnsubscribe;
		} else {
			if (this.debug) console.log('SubscriptionManager: Subscription replaced with undefined');
			this.stopCurrentSubscription = undefined;
		}
	}

	unsubscribe() {
		if (this.stopCurrentSubscription) {
			this.stopCurrentSubscription();
			if (this.debug) console.log('SubscriptionManager: Unsubscribed from current subscription');
		}
		this.stopCurrentSubscription = undefined;
	}

	unsubscribeAndStop() {
		this.stopped = true;
		this.unsubscribe();
		if (this.debug) console.log('SubscriptionManager: Subscription manager stopped');
	}
}


/**
 * A class that manages a listener to a document or collection in Firestore.
 * It will automatically start and stop the listener based on the current reference.
 * It will also handle retries if the listener fails to load the data.
 * It will also handle multiple calls to setRef, and only use the last one.
 * It will also ignore results from previous calls if they come in too late.
 */
export abstract class BaseManagedListener<T, TRef> {
	private managedUnsubscriber: SingleSubscriptionManager = new SingleSubscriptionManager();
	private currentRef?: TRef;

	protected resultCallback?: ResultCallback<T | undefined>;
	private originalResultCallback?: ResultCallback<T | undefined>;
	protected errorCallback?: ErrorCallback;
	private originalErrorCallback?: ErrorCallback;

	protected abstract startListening(ref: TRef): () => void;
	protected abstract refEqual(ref1: TRef, ref2: TRef): boolean;

	public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isRetrying: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private retryEnabled = false;
	private maxTries = 5;
	private msBetweenTries = 1000;
	private tries = 0;

	private paused = false;
	private pausedOnRef?: TRef;
	private undefinedOnPause = true;

	private callId = 0;

	private debug: boolean = false;

	constructor(resultCallback: ResultCallback<T | undefined>, errorCallback?: ErrorCallback) {
		this.originalErrorCallback = errorCallback;
		this.originalResultCallback = resultCallback;
	}

	hookStop(canStop: CanStop) {
		this.managedUnsubscriber.hook(canStop);
		return this;
	}

	enableDebug(enable: boolean = true) {
		this.debug = enable;
		this.managedUnsubscriber.enableDebug(enable);
		return this;
	}

	enableRetry(enable: boolean = true, maxTries = 10, msBetweenTries = 1000) {
		this.retryEnabled = enable;
		this.maxTries = maxTries;
		this.msBetweenTries = msBetweenTries;
		return this;
	}

	enableUndefinedOnPause(setUndefined: boolean = true) {
		this.undefinedOnPause = setUndefined;
		return this;
	}


	listenTo(ref?: TRef, forceReload: boolean = false) {
		if (this.managedUnsubscriber.isStopped()) {
			return;
		}

		if (ref === undefined) {
			this.pause();
			return;
		}

		if (this.currentRef && this.refEqual(this.currentRef, ref)) {
			if (!forceReload) {
				if (this.debug) {
					console.log("ManagedListener: Already listening to", ref);
				}
				return;
			}
		} else {
			this.isRetrying.next(false);
		}

		if (this.debug) {
			console.log("ManagedListener: Listening to", ref, "forceReload", forceReload, "while before we were listening to", this.currentRef);
		}

		this.paused = false;
		this.currentRef = ref;
		this.isLoading.next(true);
		const currentCallId = ++this.callId;

		this.resultCallback = (data) => {
			if (this.managedUnsubscriber.isStopped()) return; // if we have stopped, ignore the result
			if (this.paused) return; // if we have paused, ignore the result
			if (this.callId !== currentCallId) return; // if we have made another call, ignore the result

			if (this.debug) {
				console.log("ManagedListener: Result from listener with called Id", currentCallId, data);
			}

			this.tries = 0;
			this.isLoading.next(false);
			this.isRetrying.next(false);
			this.originalResultCallback?.(data);
		};

		// replace the error callback with one that will retry until it succeeds or this managed listener is stopped
		this.errorCallback = (error) => {
			if (this.managedUnsubscriber.isStopped()) return;
			if (this.paused) return; // if we have paused, ignore the result
			if (this.callId !== currentCallId) return; // if we have made another call, ignore the result

			if (!this.retryEnabled) {
				if (this.debug) {
					console.error("ManagedListener: Error from listener", error);
				}
				this.isLoading.next(false);
				this.isRetrying.next(false);
				if (!this.originalErrorCallback) {
					console.error(error);
				} else {
					this.originalErrorCallback(error);
				}
				return;
			}

			this.tries++;

			if (this.debug) {
				console.error("ManagedListener: Error from listener. Retrying #", this.tries, error);
			}

			if (this.tries >= this.maxTries) {
				this.isLoading.next(false);
				this.isRetrying.next(false);
				if (!this.originalErrorCallback) {
					console.error(`ManagedListener: Failed to load document after ${this.tries} tries. Last 5 errors:`, error);
				} else {
					this.originalErrorCallback(`Failed to load document after ${this.tries} tries. Last 5 errors: ${error}`);
				}
				return;
			}

			this.isRetrying.next(true);
			setTimeout(() => this.listenTo(this.currentRef!, true), this.msBetweenTries);
		}

		this.managedUnsubscriber.replaceSubscription(this.startListening(ref));

		return this;
	}

	pause() {
		if (this.paused) return;
		if (this.debug) {
			console.log("ManagedListener: Pausing listener");
		}
		this.paused = true;
		this.pausedOnRef = this.currentRef;
		this.currentRef = undefined;
		this.isLoading.next(false);
		this.tries = 0;
		this.managedUnsubscriber.unsubscribe();

		if (this.undefinedOnPause) {
			this.originalResultCallback?.(undefined);
		}
	}

	resume() {
		if (!this.paused) return;
		if (this.debug) {
			console.log("ManagedListener: Resuming listener");
		}
		if (!this.pausedOnRef) {
			console.error("ManagedListener: Cannot resume without a paused ref");
		}
		this.listenTo(this.pausedOnRef);
	}

	stop() {
		if (this.debug) {
			console.log("Stopping listener");
		}
		this.managedUnsubscriber.unsubscribeAndStop();
	}
}
