<div id="fileHandler"
	class="d-flex flex-column align-items-center gap-1 h-100"
	[class.dragging]="isDragging"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
>
	<div class="d-flex flex-row my-auto gap-1">
		<!-- Picture from gallery -->
		<input [disabled]="disabled" #galleryInput class="d-none" type="file" (change)="fileChanged($event)" multiple accept="image/*">
		<i (click)="enabled() ? openGallery() : null" role="button" class="fa-regular fa-image"></i>
		<!-- Picture from camera -->
		<ng-container *ngIf="isMobile">
			<input [disabled]="disabled" #cameraInput class="d-none" type="file" (change)="fileChanged($event)" multiple capture accept="image/*">
			<i (click)="enabled() ? openGallery() : null" role="button" class="fa-regular fa-camera"></i>
		</ng-container>
	</div>

	<!-- Files -->
	<div class="d-flex flex-wrap align-items-left gap-3" *ngIf="fileDisplay && fileDisplay.length > 0">
		<div class="d-block" *ngFor="let display of fileDisplay; let i = index">
			<!-- Display -->
			<img *ngIf="display.startsWith('data:image')" [src]="display" [ngClass]="{'opacity-50': fileStates[i] !== 'uploaded'}">
			<audio *ngIf="display.startsWith('data:audio')" controls [src]="display" [ngClass]="{'opacity-50': fileStates[i] !== 'uploaded'}"></audio>
			<div *ngIf="!display.startsWith('data:image') && !display.startsWith('data:audio')" [ngClass]="{'opacity-50': fileStates[i] !== 'uploaded'}">
				<i style="margin-right: 1rem" class="fa-solid fa-file"></i>
				<span>{{ display }}</span>
			</div>

			<!-- Loader -->
			<div *ngIf="fileStates[i] === 'uploading'" class="spinner-border text-primary position-absolute" style="left: 0.5rem; top: 0.5rem" role="status">
				<span class="visually-hidden" localize="general.loading"></span>
			</div>

			<!-- Error -->
			<div *ngIf="fileStates[i] === 'error'" class="text-danger position-absolute" style="left: 0.5rem; top: 0.5rem">
				<i class="fa-solid fa-exclamation-triangle"></i>
			</div>

			<!-- Remove button -->
			<button [disabled]="disabled" class="btn btn-sm btn-danger remove-file" (click)="removeFile(i); $event.stopPropagation()" *ngIf="fileStates[i] !== 'uploading'">
				X
			</button>
		</div>
	</div>
</div>
