import { Pipe, PipeTransform } from '@angular/core';
import { DestroyableComponent } from '../../components/base/destroyable/destroyable.component';
import { LocalizationContext, LocalizationKey } from '../../models/config/localization/localizationModels';
import { LocalizationService } from './localization.service';

@Pipe({
  name: 'localize',
	pure: false,
})
export class LocalizePipe extends DestroyableComponent implements PipeTransform {

	private _key?: LocalizationKey;
	private _context?: LocalizationContext;
	private _currentValue: string = '';

	constructor(private localizationService: LocalizationService) {
		super();
		this.addOnStopListener(localizationService.listen(() => {
			this._updateObservable();
		}));
	}

	private _updateObservable() {
		this._currentValue = this.localizationService.get(this._key!, this._context);
	}

  transform(key: LocalizationKey, context?: LocalizationContext): string {
		this._context = context;
		if (this._key !== key) {
			this._key = key;
			this._updateObservable();
		}
		return this._currentValue;
  }
}
