import {
	Component, ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormComponentType, FormInputComponent as FormInputComp } from '../../../models/FormInput';
import { evaluate, formGroupToDictionary } from '../../../models/Condition';

const onFormInputValueChanged: EventEmitter<{name:string,value:any}> = new EventEmitter<{name:string,value:any}>();

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnChanges, OnInit {
	@Input() setup: FormInputComp | undefined;
	@Input() group: FormGroup | undefined;
	@Input() name: string | undefined;
	@Input() value: any | undefined;
	@Input() disabled: boolean = false;
	@ViewChild('inputElement', { static: false }) inputElement!: ElementRef;

	protected showSuggestions: boolean = false;
	protected anySetup: any | undefined;
	@Output() protected show: boolean = false;

	protected hasValue: boolean = false;
	protected controlValue: any | undefined;

	private subscription: any | undefined;

	constructor(private formBuilder : FormBuilder) {
		this.subscription = onFormInputValueChanged.subscribe((value) => {
			this.evaluateShow();
		});
	}

	clearInputValues() {
		this.group?.get(this.name!)?.setValue(null);
		this.controlValue = null;
		this.hasValue = false;
		this.showSuggestions = false;
		onFormInputValueChanged.emit({name: this.name!, value: null});
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		this.group?.removeControl(this.name!);
	}

	ngOnInit(): void {
		//console.log(this.setup);
		this.group?.addControl(this.name!, this.formBuilder.control(null));
		this.group?.get(this.name!)?.setValue(this.value);
		this.disabled ? this.group?.disable() : this.group?.enable();
		this.updateValues();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['setup']) {
			this.anySetup = this.setup;
		}
		if (changes['value']) {
			this.group?.get(this.name!)?.setValue(this.value);
		}
		if (changes['disabled'] !== undefined) {
			if (changes['disabled'].currentValue) this.group?.disable();
			else this.group?.enable();
		}
		// if (this.setup?.type === 'radio' || this.setup?.type === 'dropdown') {
		// 	this.anySetup.type = 'pick';
		// 	this.anySetup.options = [{value: 'test', label: 'test'}, {value: '('navbar.user.noOrg'| localize)', label: '('navbar.user.noOrg'| localize)'}, {value: 'test3', label: 'test3'}];
		// }

		this.updateValues();
	}

	updateValues(){
		this.showSuggestions = this.value ? true : false;
		this.controlValue = this.group!.get(this.name!)?.value;
		this.hasValue = this.controlValue ? true : false;
		this.evaluateShow();
	}

	evaluateShow() {
		this.show = true;
		if (this.group) {
			// console.log("checkingCondition", this.setup?.conditions ?? this.setup?.conditions ?? this.setup?.condition!, formGroupToDictionary(this.group));
			this.show = evaluate(this.setup?.condition ?? this.setup?.condition ?? this.setup?.condition!, formGroupToDictionary(this.group));
		}

		if (this.setup?.type === FormComponentType.Prompt) {
			this.group!.get(this.name!)?.setValue(this.show.toString());
		}
	}

  inputSuggestion(suggestion: string) {
    this.group!.get(this.name!)?.setValue(suggestion);
		this.hasValue = true;
		onFormInputValueChanged.emit({name: this.name!, value: suggestion});
  }

	pick(value: string | null) {
		if (value === null) {
			this.group!.get(this.name!)?.setValue(null);
			this.hasValue = false;
			this.controlValue = null;
			onFormInputValueChanged.emit({name: this.name!, value: null});
			return;
		}
		console.log("pick", value);
		this.group!.get(this.name!)?.setValue(value);
		this.hasValue = true;
		this.controlValue = value;
		onFormInputValueChanged.emit({name: this.name!, value: value});
	}

	onChanged(event: any) {
		if (event.target.type === 'checkbox') {
			this.group!.get(this.name!)?.setValue(event.target.checked);
			// console.log("onChanged", this.name, event.target.checked, event.target);
			onFormInputValueChanged.emit({name: this.name!, value: event.target.checked});
		} else {
			// console.log("onChanged", this.name, event.target.value, event.target);
			onFormInputValueChanged.emit({name: this.name!, value: event.target.value});
		}
	}

	onInput(event: any) {
		// console.log("onChanged", this.name, event.target.value);
		onFormInputValueChanged.emit({name: this.name!, value: event.target.value});
	}

	focus(): void {
		if (this.inputElement) {
			this.inputElement.nativeElement.focus();
		}
	}
}
