import { Component, Input } from '@angular/core';
import { IconElement } from 'src/app/core/shared/models/layout/layoutElements';
import { empty } from '../../../utilities/typeUtilities';
import { InlineBlockLayoutElementComponent } from '../base/inline-block-layout-element.component';

@Component({
  selector: `app-icon-element`,
	template: ``,
	styles: [`
    :host {
      /* display: inline-block;
			position: relative;
			height: 1em;
			width: 1em;
			vertical-align: middle; */
    }
  `]
})
export class IconElementComponent extends InlineBlockLayoutElementComponent {

	@Input() icon: IconElement['icon'] = empty();

	override setupAllValues(): void {
		this.setupValueForKey('icon');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'icon') {
			this.contextElement.nativeElement.innerHTML = this.icon.value;
		}
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays
	}
}
