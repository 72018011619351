import { Component, Input } from '@angular/core';
import { BaseInputElement, TextInputElement } from '../../../models/layout/inputElements';
import { ValueLink } from '../../../utilities/data/dynamic/valueWrapper';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';

@Component({
  template: ``
})
export abstract class BaseInputElementComponent<V> extends BlockLayoutElementComponent {
	
	protected uniqueId: string = Math.random().toString(36).substring(2, 12);

	@Input() name: BaseInputElement<any>['name'];
	@Input() label: BaseInputElement<any>['label'] = '';
	@Input() value: BaseInputElement<V>['value'];

	@Input() description: BaseInputElement<any>['description'];

	@Input() disabled: BaseInputElement<any>['disabled'] = false;
	@Input() required: BaseInputElement<any>['required'] = false;
	@Input() validators: BaseInputElement<any>['validators'] = [];
	@Input() visible: BaseInputElement<any>['visible'];

	override ngOnInit(): void {
		super.ngOnInit();
	}

	protected abstract onValueChangeEventToInputValue(event: Event): V;
	protected abstract onInputEventToInputValue(event: Event): V;

	protected onValueChange(event: Event) {
		const value = this.onValueChangeEventToInputValue(event);
		this.setValue(value);
	}

	protected onInput(event: Event) {
		const value = this.onInputEventToInputValue(event);
		this.setValue(value);
	}

	protected setValue(value: V) {
		if (this.value instanceof ValueLink) {
			this.value.value = value;
		} else {
			this.value = value;
		}
	}

	override setupAllValues(): void { 
		this.setupValueForKey('visible');
	}
	
	override setupValueForKey(key: keyof this): void {
		if (key === 'visible') {
			console.log('setting visibility', unwrap(this.visible));
			this.setClass('d-none', unwrap(this.visible) === false);
		}
	 }

	override setupValueInArrayForKey(key: keyof this, index: number): void { }
}
