import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationKey } from '../../models/config/localization/localizationModels';

@Pipe({
  name: 'asLocalizationKey'
})
export class AsLocalizationKeyPipe implements PipeTransform {

  transform(value: string, args?: any): LocalizationKey {
    return value as LocalizationKey;
  }

}
